import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Flex, Box } from "rebass";
import { getCities } from "../../api";
import { Footer, HeaderItem, ModalLoadingView } from "../../components";
import { Hide } from "@rebass/hide";
import { getProductDetails } from "../../api";
import { saveCityObj } from "../../store/actions";
import { filterandSortCities } from "../../utils";
import { BodyContent, HeaderContent, WebFooter, MobileFooter } from "../../container/ProductItemContainer";

interface IProps {
  productID: any;
  history: any;
  handleClickCity: any;
  cityName: any;
  match: any;
  location: any;
}
interface IState {
  product: any;
  userSelectedProduct: any;
  focused: boolean;
  input: string;
  cityList: any;
  matchedCities: any;
  modalIsOpen: boolean;
  copied: boolean;
  errorMessage: string;
}

class ProductItem extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      product: null,
      userSelectedProduct: "",
      focused: false,
      input: "",
      cityList: [],
      matchedCities: [{ name: "", state: "", country: "", countryName: "" }],
      modalIsOpen: false,
      copied: false,
      errorMessage: ""
    };
  }

  public componentWillMount = async () => {
    this.openModal();
    let productID: any;
    try {
      if (this.props.match && this.props.match.params && this.props.match.params.productID) {
        productID = this.props.match.params.productID;
      } else {
        productID = this.props.productID;
      }
      console.log("ID:" + productID);
      const response = await getProductDetails(productID);
      if (response.status === 200) {
        this.setState({ product: response.data.product });
        this.closeModal();
      }
    } catch (e) {
      console.log(e);
      alert(`Invalid product ${productID}`);
    }
  };

  public componentDidMount = async () => {
    const cityList = await getCities();
    if (cityList) {
      this.setState({ cityList: cityList.data.List });
    } else {
      console.log("no cityList response");
    }
    window.addEventListener("keyup", this.onEnter);
  };

  public componentWillUnmount = () => {
    window.removeEventListener("keyup", this.onEnter);
  };

  onEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.onSubmit(e);
    }
  };

  onSubmit = async (e) => {
    if (!this.state.input) {
      return;
    }
    if (this.state.matchedCities.length === 1) {
      await this.onResultClick(this.state.matchedCities[0]);
      this.props.history.push("/products");
    } else if (this.state.matchedCities.length === 0) {
      this.setState({errorMessage: "We couldn’t find any experiences. Double check the spelling is correct or try another city!"})
    } else if (this.state.matchedCities.length > 1) {
      this.setState({errorMessage: "Please select a city."})
    }
  };

  private openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  private closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  public onCheckAvailability = () => {
    const { history } = this.props;
    history.push({
      pathname: "/productAvailability",
      state: {
        productID: this.props.match.params.productID,
      },
    });
  };

  private handleInputFocus = () => {
    this.setState({ focused: true });
  };

  private handleInputBlur = () => {
    if (this.state.input) {
      return;
    }
    this.setState({ focused: false });
  };

  private onInputChange = async (e) => {
    const value: string = e.target.value;
    this.setState({ input: value });
    const matchedCities = filterandSortCities(this.state.cityList, value);
    this.setState({ matchedCities });
    this.setState({errorMessage: ""})
  };

  private onResultClick = async (item) => {
    this.setState({ input: item.name });
    const matchedCities = filterandSortCities(this.state.cityList, item.name);
    this.setState({ matchedCities });
    this.props.handleClickCity(item);
    this.props.history.push("/products");
  };

  private setOnCopied = async () => {
    this.setState({ copied: true });
    setTimeout(async () => {
      this.setState({ copied: false });
    }, 3000);
  };

  render() {
    const { focused, input, product, modalIsOpen, copied } = this.state;
    //console.log(product);
    return (
      <React.Fragment>
        {product ? (
          <Box>
            <Flex flexWrap="wrap">
              <HeaderItem
                imageUrls={
                  this.props.location.state && this.props.location.state.cityImages
                    ? this.props.location.state.cityImages
                    : null
                }
              >
                <HeaderContent
                  cityName={this.props.cityName}
                  focused={focused}
                  handleInputFocus={this.handleInputFocus}
                  handleInputBlur={this.handleInputBlur}
                  input={input}
                  onInputChange={this.onInputChange}
                  product={product}
                  copied={copied}
                  onCopied={this.setOnCopied}
                  onSubmit={this.onSubmit}
                  matchedCities={this.state.matchedCities}
                  errorMessage={this.state.errorMessage}
                  onResultClick={this.onResultClick}
                />
              </HeaderItem>
              <BodyContent product={product} />
            </Flex>
            <Footer>
              <Hide xsmall small>
                <WebFooter product={product} onCheckAvailability={this.onCheckAvailability} />
              </Hide>
              <Hide medium large xlarge>
                <MobileFooter product={product} onCheckAvailability={this.onCheckAvailability} />
              </Hide>
            </Footer>
          </Box>
        ) : (
          <Box>
            <Flex flexWrap="wrap">
              <HeaderItem>
                <Box />
              </HeaderItem>
            </Flex>
            <ModalLoadingView modalIsOpen={modalIsOpen} closeModal={() => this.closeModal()} />
          </Box>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    productID: state.userSelectedProduct,
    cityName: state.userSelectedCity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleClickCity(obj) {
      dispatch(saveCityObj(obj));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductItem));

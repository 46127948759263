import Modal from "react-modal";
import React from "react";
import { useWindowSize, BoldSkew, MediumItalic, DemiBold } from "../components";
import { theme } from "../constants";
import { Text, Flex, Box, Image } from "rebass";
import { FiX } from "react-icons/fi";
import styled from "styled-components";
import bell from "../assets/travelExperience/Bell.png";
import partyFace from "../assets/travelExperience/partyface.png";
import smile from "../assets/travelExperience/SmileEmoji.png";
import shockFace from "../assets/travelExperience/shockface.png";
import { IoMdArrowDropright, IoMdArrowDropleft } from "react-icons/io";

export function ModalView(props: any) {
  let [winWidth, winHeight] = useWindowSize();
  const customStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: winWidth > 510 ? winWidth - 700 : winWidth - 50,
      height: winWidth > 400 ? winHeight - 250 : winHeight - 200,
      padding: winWidth > 510 ? "70px" : "20px",
      outline: "none",
      overflow: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: theme.colors.solitude,
    },
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="Modal modalScroll"
      overlayClassName="Overlay"
      style={customStyles}
    >
      {/* <Button
        onClick={props.closeModal}
        sx={{
          position: "absolute",
          top: 2,
          right: 2,
          backgroundColor: "white",
          outline: "none",
          color: theme.colors.midnightBlue,
        }}
      >
        X
      </Button> */}
      {props.content}
    </Modal>
  );
}

export function ModalLoadingView(props: any) {
  const customStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "50%",
      height: "50%",
      outline: "none",
      overflow: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
    },
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="Modal modalScroll"
      overlayClassName="Overlay"
      style={customStyles}
    >
      <Flex justifyContent="center">
        <Box className={"loading"}>
          <Box className="slider" my={"50%"}>
            <Text
              className={"pelikin-logo"}
              sx={{
                fontFamily: "PelikinSolidIcon",
                color: theme.colors.limeGreen,
                fontSize: "100px",
              }}
            >
              2
            </Text>
          </Box>
        </Box>
      </Flex>
    </Modal>
  );
}

export function ModalLeviesView(props: any) {
  let [winWidth] = useWindowSize();
  const customStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: winWidth > 510 ? winWidth - 300 : winWidth - 50,
      padding: winWidth > 510 ? "40px" : "20px",
      outline: "none",
      overflow: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
    },
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="Modal"
      overlayClassName="Overlay"
      style={customStyles}
    >
      <Flex justifyContent="center">
        <Box className={"loading"}>
          <Box
            className="bookingModal"
            sx={{
              backgroundColor: theme.colors.midnightBlue,
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              position: "relative",
              padding: "50px",
            }}
          >
            <Box
              onClick={props.closeModal}
              sx={{
                cursor: "pointer",
                position: "absolute",
                top: "10px",
                right: "15px",
              }}
            >
              <FiX style={{ color: theme.colors.white, fontSize: "25px" }} />
            </Box>
            {props.blueContent()}
          </Box>
          <Box
            className="bookingModal"
            sx={{
              backgroundColor: "white",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
          >
            {props.whiteContent()}
          </Box>
        </Box>
      </Flex>
    </Modal>
  );
}

const MovingBar = styled.div`
  background-color: ${theme.colors.wildStrawberry};
  margin-top: 25px;
  height: 12px;
  animation-timing-function: linear;
  animation: moving-bar 2s infinite;
  width: 260px;
`;

export function GradientModal(props: any) {
  let [winWidth, winHeight] = useWindowSize();
  const customStyles = {
    content: {
      position: "absolute",
      top: "60%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: winWidth > 510 ? winWidth - 240 : winWidth - 100,
      height: winWidth > 400 ? winHeight - 220 : winHeight - 400,
      padding: winWidth > 510 ? "40px" : "20px",
      outline: "none",
      overflow: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
    },
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="Modal"
      overlayClassName="gradientOverlay"
      style={customStyles}
    >
      <Flex justifyContent="center" alignItems="center">
        <Box className={"loading"}>
          <Box
            className="gradientModal"
            sx={{
              backgroundColor: "transparent",
            }}
          >
            <Flex justifyContent="center">
              <Image
                sx={{
                  width: winWidth > 900 ? "150px" : "110px",
                  height: winWidth > 900 ? "140px" : "100px",
                }}
                src={props.image}
              />
            </Flex>
          </Box>
          <Box
            className="gradientModal"
            sx={{
              backgroundColor: "transparent",
              padding: "0 10px",
            }}
          >
            <BoldSkew fontSize="40px" color="#FFF" sx={{ textAlign: "center" }} spacing="-1.5px">
              {props.text}
            </BoldSkew>
          </Box>
          <MovingBar />
        </Box>
      </Flex>
    </Modal>
  );
}

export function PriceChangeModal(props: any) {
  let [winWidth] = useWindowSize();
  const customStyles = {
    content: {
      position: "absolute",
      top: "45%",
      left: "50%",
      width: winWidth > 510 ? winWidth - 300 : winWidth - 50,
      padding: winWidth > 510 ? "40px" : "20px",
      outline: "none",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
    },
  };

  const modalBlueContent = () => {
    return (
      <Flex justifyContent="center" flexWrap="wrap" flexDirection="column">
        <Flex justifyContent="center">
          <Image
            sx={{
              height: winWidth > 900 ? "74px" : "60px",
            }}
            src={props.type === "change" ? bell : partyFace}
          />
        </Flex>
        <Box>
          <BoldSkew
            fontSize={winWidth > 499 ? "40px" : "30px"}
            spacing={"-1px"}
            color="#FFF"
            sx={{ textAlign: "center" }}
          >
            {props.type === "change" ? "PRICE CHANGE" : "PRICE DROP"}
          </BoldSkew>
          <MediumItalic fontSize={winWidth > 499 ? "18px" : "15px"} sx={{ textAlign: "center", maxWidth: "420px" }}>
            {props.type === "change"
              ? "Looks like the provider has updated the price of this Pelikin Experience! The new price is available for you below."
              : "Good news! We’ve found a lower price for your experience!"}
          </MediumItalic>
        </Box>
      </Flex>
    );
  };

  const modalWhiteContent = () => {
    return (
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <DemiBold
          sx={{ textAlign: "center", display: "block" }}
          color={theme.colors.wildStrawberry}
          fontSize={winWidth > 499 ? "18px" : "15px"}
          spacing="-1.25px"
        >
          NEW PRICE
        </DemiBold>

        <BoldSkew
          fontSize={winWidth > 499 ? "50px" : "38px"}
          spacing={"-1px"}
          color={theme.colors.midnightBlue}
          sx={{ textAlign: "center", display: "block" }}
        >
          $ {props.total}
        </BoldSkew>
      </Flex>
    );
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="Modal"
      overlayClassName="Overlay"
      style={customStyles}
    >
      <Flex justifyContent="center">
        <Box className={"loading"}>
          <Box
            className="bookingModal"
            sx={{
              backgroundColor: theme.colors.midnightBlue,
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              padding: "50px",
            }}
          >
            {modalBlueContent()}
          </Box>
          <Box sx={{ position: "relative" }}>
            <Box
              className="bookingModal"
              sx={{
                backgroundColor: "white",
                borderBottomLeftRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              {modalWhiteContent()}
            </Box>
            <Box
              sx={{
                padding: "10px",
                width: props.type === "change" ? "140px" : "210px",
                backgroundColor: "white",
                position: "absolute",
                right: 0,
                bottom: "-80px",
                borderRadius: "110px",
                cursor: "pointer",
              }}
              onClick={props.closeModal}
            >
              <BoldSkew
                fontSize={winWidth > 499 ? "18px" : "15px"}
                spacing={"-1px"}
                color={theme.colors.wildStrawberry}
                sx={{ textAlign: "center" }}
              >
                {props.type === "change" ? "OKAY" : "GREAT THANKS!"}
              </BoldSkew>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Modal>
  );
}

export function BookingAndPaymentErrorModal(props: any) {
  let [winWidth] = useWindowSize();
  const customStyles = {
    content: {
      position: "absolute",
      top: "45%",
      left: "50%",
      width: winWidth > 510 ? winWidth - 300 : winWidth - 50,
      padding: winWidth > 510 ? "40px" : "20px",
      outline: "none",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
    },
  };

  const modalBlueContent = () => {
    return (
      <Flex justifyContent="center" flexWrap="wrap" flexDirection="column">
        <Flex justifyContent="center">
          <Image
            sx={{
              height: winWidth > 900 ? "100px" : "75px",
              marginBottom: "40px",
            }}
            src={smile}
          />
        </Flex>
        <Box>
          <BoldSkew
            fontSize={winWidth > 499 ? "40px" : "30px"}
            spacing={"-1px"}
            color="#FFF"
            sx={{ textAlign: "center" }}
          >
            {props.type !== "multiple" ? (props.type === "payment" ? "PAYMENT ERROR" : "BOOKING ERROR") : "PAX ERROR"}
          </BoldSkew>
          <MediumItalic fontSize={winWidth > 499 ? "18px" : "15px"} sx={{ textAlign: "center", maxWidth: "420px" }}>
            {props.type !== "multiple"
              ? props.type === "payment"
                ? "Transaction was not processed. We recommend you to check your pin,internet connection and please try again."
                : "Something went wrong."
              : "This product requires at least 2 people"}
          </MediumItalic>
        </Box>
      </Flex>
    );
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="Modal"
      overlayClassName="Overlay"
      style={customStyles}
    >
      <Flex justifyContent="center">
        <Box className={"loading"}>
          <Box
            className="bookingModal"
            sx={{
              backgroundColor: theme.colors.midnightBlue,
              borderRadius: "15px",
              padding: "50px",
            }}
          >
            {modalBlueContent()}
          </Box>
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                padding: "10px",
                width: props.type === "change" ? "140px" : "210px",
                backgroundColor: "white",
                position: "absolute",
                right: 0,
                bottom: "-90px",
                borderRadius: "110px",
                cursor: "pointer",
              }}
              onClick={props.closeModal}
            >
              <BoldSkew
                fontSize={winWidth > 499 ? "18px" : "15px"}
                spacing={"-1px"}
                color={theme.colors.wildStrawberry}
                sx={{ textAlign: "center" }}
              >
                {props.type === "" ? "CLOSE" : "TRY AGAIN"}
              </BoldSkew>
            </Box>
          </Box>
        </Box>
      </Flex>
    </Modal>
  );
}

export function NotAvailableModal(props: any) {
  let [winWidth] = useWindowSize();
  const customStyles = {
    content: {
      position: "absolute",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: winWidth > 510 ? winWidth - 300 : winWidth - 50,
      padding: winWidth > 510 ? "40px" : "20px",
      outline: "none",
      overflow: "auto",
      transform: "translate(-50%, -50%)",
      backgroundColor: "transparent",
    },
  };

  const modalBlueContent = () => {
    return (
      <Flex justifyContent="center" flexWrap="wrap" flexDirection="column">
        <Box>
          <Flex justifyContent="center">
            <Image
              sx={{
                height: winWidth > 900 ? "100px" : "75px",
                marginBottom: "15px",
              }}
              src={shockFace}
            />
          </Flex>
        </Box>
        <Box>
          <BoldSkew
            fontSize={winWidth > 499 ? "40px" : "30px"}
            spacing={"-1px"}
            color="#FFF"
            sx={{ textAlign: "center" }}
          >
            IT'S GONE!
          </BoldSkew>
        </Box>

        <Box>
          <Flex justifyContent="center">
            <MediumItalic fontSize={winWidth > 499 ? "18px" : "15px"} sx={{ textAlign: "center", maxWidth: "420px" }}>
              This experience is no longer available. You can check back at a later date or keep exploring to find
              another amazing experience that you’ll love.
            </MediumItalic>
          </Flex>
        </Box>
      </Flex>
    );
  };

  const modalWhiteContent = () => {
    return (
      <Flex justifyContent="space-around">
        <Box width={1 / 3} onClick={props.backToProduct} sx={{ cursor: "pointer", outline: "none" }}>
          <Flex className={"item-hover"}>
            <Box>
              <IoMdArrowDropleft style={{ fontSize: "50px" }} />
            </Box>
            <BoldSkew
              sx={{ textAlign: "center" }}
              color={theme.colors.midnightBlue}
              className={"item-hover"}
              fontSize={winWidth > 499 ? "22px" : "18px"}
              spacing="-1.25px"
              lineHeight="35px"
            >
              EXPLORE OTHER EXPERIENCES
            </BoldSkew>
          </Flex>
        </Box>
        <Box width={1 / 3} onClick={props.closeModal} sx={{ cursor: "pointer", outline: "none" }}>
          <Flex className={"item-hover"}>
            <BoldSkew
              sx={{ textAlign: "center" }}
              color={theme.colors.midnightBlue}
              className={"item-hover"}
              fontSize={winWidth > 499 ? "22px" : "18px"}
              spacing="-1.25px"
              lineHeight="35px"
            >
              CHOOSE ANOTHER SESSION TIME
            </BoldSkew>
            <Box>
              <IoMdArrowDropright style={{ fontSize: "50px" }} />
            </Box>
          </Flex>
        </Box>
      </Flex>
    );
  };

  return (
    <Modal
      isOpen={props.modalIsOpen}
      onRequestClose={props.closeModal}
      className="Modal"
      overlayClassName="Overlay"
      style={customStyles}
    >
      <Flex justifyContent="center">
        <Box className={"loading"}>
          <Box
            className="bookingModal"
            sx={{
              backgroundColor: theme.colors.midnightBlue,
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              position: "relative",
              padding: "50px",
            }}
          >
            {modalBlueContent()}
          </Box>
          <Box
            className="bookingModal"
            sx={{
              backgroundColor: "white",
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
            }}
          >
            {modalWhiteContent()}
          </Box>
        </Box>
      </Flex>
    </Modal>
  );
}

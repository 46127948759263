/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {
  BoldSkew,
  NextMedium,
  DemiBold,
  useWindowSize,
  NextRegular,
  ModalView,
  readMoreContainer,
  checkLength,
  MediumItalic
} from "../components";
import { Flex, Box, Button, Text, Image } from "rebass";
import { theme } from "../constants";
import { calculateDuration } from "../utils";
import { BsChevronLeft } from "react-icons/bs";
import Slider from "react-slick";
import { FiSearch } from "react-icons/fi";
import { CSSTransition } from "react-transition-group";
import { FaCheck } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RenderResponsiveSearchBox } from "../container/ProductContainer";
import { getDisplayHeight, renderCancellationPolicy } from "../utils";
import { RenderResult } from "../container/ProductContainer";

const MAX_NAME_LENGTH: number = 40;
const WIDE = 1023;
const headerBig = "30px";
const headerSmall = "25px";
const descBig = "19px";
const descSmall = "16px";

export function BodyContent(props: any) {
  const product: any = props.product;
  let [winWidth] = useWindowSize();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: winWidth > 600 ? 3 : 1,
    slidesToScroll: 1,
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return product ? (
    <Box
      width={1}
      px={winWidth > 900 ? "150px" : "20px"}
      py={winWidth > 900 ? "40px" : "30px"}
      sx={{ bg: theme.colors.solitude }}
    >
      {product.name.length >= MAX_NAME_LENGTH && (
        <Box>
          <DemiBold fontSize={"4vmin"}>{product.name}</DemiBold>
        </Box>
      )}

      <Box py={"30px"}>
        <NextMedium fontSize={winWidth > 499 ? headerBig : headerSmall}>About the Experience</NextMedium>
        <Box mt={"11px"}>
          <NextRegular
            fontSize={winWidth > 499 ? descBig : descSmall}
            spacing={"0px"}
            color={theme.colors.midnightBlue}
          >
            {checkLength(product.description, 400)}
            <a onClick={openModal}>
              <NextMedium
                className={"item-hover"}
                color={theme.colors.vividblue}
                fontSize={winWidth > 499 ? descBig : descSmall}
                marginTop={"6px"}
              >
                Read more
              </NextMedium>
            </a>
          </NextRegular>
        </Box>
      </Box>
      <Box py={"30px"}>
        <Slider {...settings}>
          <Box sx={theme.styles.centerText}>
            <Box sx={theme.styles.slickMargin}>
              <Image src={product.images[0].urlPreviewSecure} />
            </Box>
          </Box>
          <Box sx={theme.styles.centerText}>
            <Box sx={theme.styles.slickMargin}>
              <Image src={product.images[1].urlPreviewSecure} />
            </Box>
          </Box>
          <Box sx={theme.styles.centerText}>
            <Box sx={theme.styles.slickMargin}>
              <Image src={product.images[2].urlPreviewSecure} />
            </Box>
          </Box>
        </Slider>
      </Box>
      {readMoreContainer(
        winWidth,
        "Voucher Instructions",
        product.sellVouchers ? "Voucher required" : "No Vouchers",
        true
      )}
      {readMoreContainer(winWidth, "Notes", product.specialNotes, product.specialNotes)}
      {readMoreContainer(winWidth, "Itinerary", product.itineraryStr, product.itineraryStr)}
      {readMoreContainer(winWidth, "Highlights", product.highlightsStr, product.highlightsStr)}
      <Box py={"30px"}>
        <NextMedium fontSize={winWidth > 499 ? headerBig : headerSmall}>{"Confirmation"}</NextMedium>
        <Box mt={"11px"}>
          <NextRegular
            fontSize={winWidth > 499 ? descBig : descSmall}
            spacing={"2px"}
            color={theme.colors.midnightBlue}
            className={"lineBreak"}
          >
            {
              "You will receive a confirmation email from us detailing everything you need to enjoy your experience instantly after booking.\n\nIf you make a booking and do not receive your confirmation email, please check your spam folder or contact us at help@pelikinmoney.com"
            }
          </NextRegular>
        </Box>
      </Box>
      {readMoreContainer(winWidth, "Cancellation Policy", renderCancellationPolicy(product.cancellationPolicy), true)}
      {readMoreContainer(winWidth, "Includes", product.includes, product.includes)}
      {readMoreContainer(winWidth, "Pick Up", product.pickupNotes, product.pickupNotes)}
      {readMoreContainer(winWidth, "Drop Off", product.dropoffNotes, product.dropoffNotes)}
      <ModalView modalIsOpen={modalIsOpen} closeModal={closeModal} content={<ModalContent product={product} />} />
    </Box>
  ) : (
    <Text> No Product </Text>
  );
}

function ModalContent(props: any) {
  const product: any = props.product;
  let [winWidth] = useWindowSize();
  return (
    <Box className="lineBreak">
      <DemiBold fontSize={winWidth > 499 ? headerBig : headerSmall}>{product.name}</DemiBold>
      <Box sx={{ pb: 3, pt: 3 }}>
        <NextMedium fontSize={winWidth > 499 ? headerBig : headerSmall}>About the Experience</NextMedium>
      </Box>
      <NextRegular fontSize={winWidth > 499 ? descBig : descSmall} spacing={"0px"} color={theme.colors.midnightBlue}>
        {product && product.description}
      </NextRegular>
      <Box sx={{ pb: 3, pt: 4 }}>
        <NextMedium fontSize={winWidth > 499 ? headerBig : headerSmall}>Highlights</NextMedium>
      </Box>
      <NextRegular fontSize={winWidth > 499 ? descBig : descSmall} spacing={"2px"} color={theme.colors.midnightBlue}>
        {product && product.highlightsStr}
      </NextRegular>
    </Box>
  );
}

export function HeaderContent(props: any) {
  const product: any = props.product;
  let shareUrl = window.location.href;
  if (shareUrl.indexOf("productItem") !== -1) {
    shareUrl = shareUrl.replace("productItem", "productShare");
    shareUrl += `/${product.id}`;
  }
  let [winWidth] = useWindowSize();
  return !product ? (
    <Text>No Product</Text>
  ) : (
    <Box mx={winWidth > 1023 ? 6 : 0}>
      <Box width={"100%"}>
        <Flex justifyContent="space-between" flexDirection={winWidth > WIDE ? "row" : "column"}>
          <Box width={winWidth > WIDE ? "80%" : "100%"} marginBottom={"10px"}>
            <CSSTransition in={props.focused} timeout={300} classNames="slide">
              <RenderResponsiveSearchBox
                focused={props.focused}
                handleInputFocus={props.handleInputFocus}
                handleInputBlur={props.handleInputBlur}
                input={props.input}
                onInputChange={props.onInputChange}
              />
            </CSSTransition>
            <div style={{ display: "inline", cursor: "pointer" }} onClick={props.onSubmit}>
              <FiSearch
                style={{
                  color: "gray",
                  marginLeft: "-35px",
                  position: "relative",
                  top: "6px",
                }}
                size={25}
              />
            </div>
            {props.input && props.focused && props.matchedCities.length > 0 ? (
              <RenderResult matchedCities={props.matchedCities} onResultClick={props.onResultClick} fromItem={true} />
            ) : null}
          </Box>
          <Box
            m={winWidth > WIDE ? "24px auto" : 0}
            width={winWidth > WIDE ? "20%" : "80%"}
            sx={{
              textAlign: winWidth > WIDE ? "right" : "left",
            }}
          >
            {!props.copied ? (
              <CopyToClipboard text={shareUrl} onCopy={props.onCopied}>
                <Button
                  sx={{
                    bg: "transparent",
                    cursor: "pointer",
                    fontFamily: "AvenirNextCondensedBoldSkew",
                    fontSize: "20px",
                    letterSpacing: "1px",
                  }}
                  className={"share-hover"}
                >
                  <Flex>
                    <Box mr={winWidth > WIDE ? 2 : 0}>SHARE</Box>
                    <Text sx={theme.styles.shareIcon}></Text>
                  </Flex>
                </Button>
              </CopyToClipboard>
            ) : (
              <Button
                disabled={true}
                sx={{
                  bg: theme.colors.wildStrawberry,
                  borderRadius: 30,
                  cursor: "pointer",
                  fontFamily: "AvenirNextCondensedBoldSkew",
                  fontSize: "18px",
                }}
              >
                <Flex pt={"5px"}>
                  <Box mr={2} mt={"1px"}>
                    <FaCheck style={{ color: theme.colors.white }} size={20} />
                  </Box>
                  <Box>Link copied!</Box>
                </Flex>
              </Button>
            )}
          </Box>
        </Flex>
        {props.errorMessage ? (
          <MediumItalic fontSize={"15px"} sx={{ marginTop: "20px" }}>
            {props.errorMessage}
          </MediumItalic>
        ) : null}
      </Box>
      <Flex sx={{ mt: winWidth > WIDE ? 4 : 1 }}>
        <NextMedium color={theme.colors.white} fontSize={getDisplayHeight(winWidth)}>
          {checkLength(product.name, MAX_NAME_LENGTH)}
        </NextMedium>
      </Flex>
      <Box width={"80%"} marginTop={"5px"}>
        <Flex flexWrap="wrap" justifyContent="start">
          <Flex sx={{ marginRight: winWidth <= WIDE ? "20px" : "50px" }}>
            <Text
              sx={Object.assign({}, theme.styles.locationIcon, {
                fontSize: winWidth <= WIDE ? "18px" : "24px",
              })}
            >
              
            </Text>
            <Box sx={{ mt: 9 }}>
              <NextMedium color={theme.colors.white} fontSize={winWidth <= WIDE ? "16px" : "20px"} spacing={"0px"}>
                Book Instantly
              </NextMedium>
            </Box>
          </Flex>
          <Flex sx={{ marginRight: winWidth <= WIDE ? "20px" : "50px" }}>
            <Text
              sx={Object.assign({}, theme.styles.locationIcon, {
                fontSize: winWidth <= WIDE ? "18px" : "24px",
              })}
            >
              
            </Text>
            <Box sx={{ mt: 9 }}>
              <NextMedium color={theme.colors.white} fontSize={winWidth <= WIDE ? "16px" : "20px"} spacing={"0px"}>
                {calculateDuration(product)}
              </NextMedium>
            </Box>
          </Flex>
          <Flex justifyContent={"space-between"}>
            <Box>
              <Text
                sx={Object.assign({}, theme.styles.locationIcon, {
                  fontSize: winWidth <= WIDE ? "18px" : "24px",
                })}
              >
                
              </Text>
            </Box>
            <Box sx={{ mt: 9 }}>
              <Flex>
                {winWidth <= WIDE ? null : (
                  <React.Fragment>
                    {props.cityName.length !== 0 && (
                      <Box>
                        <NextMedium
                          sx={{ fontWeight: "bold" }}
                          color={theme.colors.white}
                          fontSize={winWidth <= WIDE ? "16px" : "20px"}
                          spacing={"0px"}
                        >
                          {props.cityName.name + ", " + props.cityName.state + ", " + props.cityName.countryName}
                        </NextMedium>
                      </Box>
                    )}
                  </React.Fragment>
                )}
                <Box ml={1}>
                  <Flex>
                    {winWidth <= WIDE ? null : (
                      <NextMedium
                        sx={{ fontWeight: "bold" }}
                        color={theme.colors.white}
                        fontSize={winWidth <= WIDE ? "16px" : "20px"}
                        spacing={"0px"}
                      >
                        {"("}
                      </NextMedium>
                    )}
                    <a
                      href={`https://www.google.com/maps/search/?api=1&query=${product.latitudeStart},${product.longitudeStart}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <NextMedium
                        sx={{ textDecoration: "underline" }}
                        color={theme.colors.white}
                        fontSize={winWidth <= WIDE ? "16px" : "20px"}
                        spacing={"0px"}
                        className={"share-hover"}
                      >
                        {"Get Directions"}
                      </NextMedium>
                    </a>
                    {winWidth <= WIDE ? null : (
                      <NextMedium
                        sx={{ fontWeight: "bold" }}
                        color={theme.colors.white}
                        fontSize={winWidth <= WIDE ? "16px" : "20px"}
                        spacing={"0px"}
                      >
                        {")"}
                      </NextMedium>
                    )}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

export function WebFooter(props: any) {
  return (
    <Flex px={"125px"} py={3} justifyContent="space-between">
      <Box sx={{ pt: theme.padding.p10 }}>
        <Button
          onClick={() => window.history.go(-1)}
          className={"item-hover"}
          sx={Object.assign({}, theme.styles.footerGoBack, {
            color: theme.colors.periglacialblue,
            border: 0,
            backgroundColor: "transparent",
            width: "141px",
            height: "55px",
            fontSize: 20,
          })}
        >
          <Flex>
            <BsChevronLeft />
            <Text> Go Back</Text>
          </Flex>
        </Button>
      </Box>
      <Box sx={{ pt: theme.padding.p10 }}>
        <Flex justifyContent="space-between">
          <BoldSkew color={theme.colors.midnightBlue} fontSize={"40px"} spacing={"0px"}>
            From ${props.product.minRateAdult.toFixed(2)}
          </BoldSkew>
          <Box mt={"25px"} px={"20px"} />
          <Button
            sx={Object.assign({}, theme.styles.footerCheckAvailability, {
              color: theme.colors.white,
              backgroundColor: theme.colors.wildStrawberry,
              width: "231px",
              height: "47px",
              fontSize: 20,
              outline: "none",
              cursor: "pointer",
            })}
            onClick={props.onCheckAvailability}
          >
            CHECK AVAILABILITY
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}

export function MobileFooter(props: any) {
  return (
    <div>
      <Flex px={3} justifyContent="center">
        <BoldSkew color={theme.colors.midnightBlue} fontSize={"19px"} spacing={"0px"}>
          From ${props.product.minRateAdult.toFixed(2)}
        </BoldSkew>
      </Flex>
      <Box>
        <Flex justifyContent="center">
          <Button
            sx={Object.assign({}, theme.styles.footerCheckAvailability, {
              color: theme.colors.white,
              backgroundColor: theme.colors.wildStrawberry,
              width: "50%",
              fontSize: "13px",
              outline: "none",
              cursor: "pointer",
            })}
            onClick={props.onCheckAvailability}
          >
            <Text m={"auto"}>CHECK AVAILABILITY</Text>
          </Button>
        </Flex>
      </Box>
      <Box>
        <Flex mt={1} justifyContent="center">
          <Button
            onClick={() => window.history.go(-1)}
            className={"item-hover"}
            sx={Object.assign({}, theme.styles.footerCheckAvailability, {
              color: theme.colors.periglacialblue,
              backgroundColor: "transparent",
              width: "50%",
              height: "6.5vw",
              fontSize: "13px",
            })}
          >
            <Flex justifyContent="center">
              <Text mt={"-1px"}>{"< Go Back"}</Text>
            </Flex>
          </Button>
        </Flex>
      </Box>
    </div>
  );
}

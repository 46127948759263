import React from "react";
import { Box } from "rebass";
import { theme } from "../constants";

export function Footer({ children }) {
  return (
    <Box>
      <Box sx={theme.styles.phantom} />
      <Box sx={theme.styles.footer}>{children}</Box>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar, DayValue } from "react-modern-calendar-datepicker";
import Moment from "moment";
import { theme } from "../constants";

export function CalendarDatePicker(props: any) {
  let defaultValue = {
    year: parseInt(Moment().format("YYYY")),
    month: parseInt(Moment().format("M")),
    day: parseInt(Moment().format("DD")),
  };

  let yearAfter = {
    year: parseInt(Moment().add(1, "year").format("YYYY")),
    month: parseInt(Moment().add(1, "year").format("M")),
    day: parseInt(Moment().add(1, "year").format("DD")),
  };

  let startDate = Moment();
  let endDate = Moment().add(1, "year");
  let datesBetween: any[] = [];

  let startingMoment = startDate;

  while (startingMoment <= endDate) {
    datesBetween.push(startingMoment.clone()); // clone to add new object
    startingMoment.add(1, "days");
  }
  let operatingArr = props.operatingDaysStr.split(",");

  let disabledDays = [] as any;

  datesBetween.forEach((date) => {
    let weekdays = Moment(date).format("ddd").toString();
    let year = parseInt(Moment(date).format("YYYY"));
    let month = parseInt(Moment(date).format("M"));
    let day = parseInt(Moment(date).format("DD"));
    const notOperating = (element: string) => element === weekdays;
    if (!operatingArr.some(notOperating)) {
      disabledDays.push(Object.assign({}, { year, month, day }));
    }
  });

  const [selectedDay, setSelectedDay] = useState<DayValue>(defaultValue);

  const useComponentDidMount = (func: any) => useEffect(func, []);

  useComponentDidMount(() => {
    props.setSelectedDate(selectedDay);
  });

  const handleDisabledSelect = (disabledDay) => {
    console.log("Tried selecting a disabled day", disabledDay);
  };

  const onChangeSelectedDay = (val) => {
    setSelectedDay(val);
    props.setSelectedDate(val);
  };

  return (
    <Calendar
      value={selectedDay}
      minimumDate={defaultValue}
      maximumDate={yearAfter}
      colorPrimary={theme.colors.skyblue}
      onChange={onChangeSelectedDay}
      disabledDays={disabledDays} // here we pass them
      onDisabledDayError={handleDisabledSelect} // handle error
      calendarClassName="responsive-calendar"
      shouldHighlightWeekends
    />
  );
}

import React from "react";
import { HeaderItem, ModalLoadingView, StyledSelect, NextMedium, MediumItalic } from "../../components";
import { getProductByLocationAndDate } from "../../api";
import { Flex, Box, Image } from "rebass";
import { withRouter } from "react-router";
import { theme } from "../../constants";
import { connect } from "react-redux";
import { getCities } from "../../api";
import { saveCityObj } from "../../store/actions";
import { filterandSortCities } from "../../utils";
import Alert from "../../assets/alert.png";
import {
  HeaderSubContent,
  HeaderContent,
  HeaderSearchBox,
  LoadMore,
  RenderResult,
} from "../../container/ProductContainer";

interface IProps {
  history: any;
  city: any;
  products: any;
  handleClickCity: any;
}

interface IState {
  products: any;
  focused: boolean;
  input: string;
  cityList: any;
  matchedCities: any;
  modalIsOpen: boolean;
  reload: boolean;
  productToShow: any;
  postPerPage: number;
  hideLoadMore: boolean;
  errorMessage: string;
}

const getLabel = (left, right) => {
  return (
    <Box>
      <div style={{ float: "left", fontWeight: "bold", paddingRight: "10px", color: "#091c80" }}>{left}</div>
      <div style={{ float: "right", paddingRight: "0px", color: "#4a5fB1" }}>{right}</div>
    </Box>
  );
};

const options = [
  { label: getLabel("Price ", " Low to High"), value: "1" },
  { label: getLabel("Price ", " High to Low"), value: "2" },
  { label: getLabel("Duration ", " Short to Long"), value: "3" },
  { label: getLabel("Duration ", " Long to Short"), value: "4" },
];
class Product extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      productToShow: [],
      postPerPage: 12,
      focused: false,
      input: "",
      cityList: [],
      matchedCities: [{ name: "", state: "", country: "", countryName: "" }],
      modalIsOpen: false,
      reload: false,
      hideLoadMore: false,
      errorMessage: "",
    };
  }

  private openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  private closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  private handleInputFocus = () => {
    this.setState({ focused: true });
  };

  private handleInputBlur = () => {
    if (this.state.input) {
      return;
    }
    this.setState({ focused: false });
  };

  private onInputChange = async (e) => {
    const value: string = e.target.value;
    this.setState({ input: value });
    const matchedCities = filterandSortCities(this.state.cityList, value);
    this.setState({ matchedCities });
    this.setState({errorMessage: ""})
  };

  onSubmit = async (e) => {
    if (!this.state.input) {
      return;
    }
    if (this.state.matchedCities.length === 1) {
      await this.onResultClick(this.state.matchedCities[0]);
      window.location.reload(true);
    } else if (this.state.matchedCities.length === 0) {
      this.setState({
        errorMessage: "We couldn’t find any experiences. Double check the spelling is correct or try another city!",
      });
    } else if (this.state.matchedCities.length > 1) {
      this.setState({ errorMessage: "Please select a city." });
    }
  };

  private onSort = async e => {
    const value: string = e[0].value;
    let sorted: any;
    let sort: any;

    switch (value) {
      case "1":
        sort = (item1: any, item2: any) => item1.minRateAdult - item2.minRateAdult;
        break;
      case "2":
        sort = (item1: any, item2: any) => item2.minRateAdult - item1.minRateAdult;
        break;
      case "3":
        sort = (item1: any, item2: any) => item1.duration - item2.duration;
        break;
      case "4":
        sort = (item1: any, item2: any) => item2.duration - item1.duration;
        break;
      default:
        console.log("not sorting");
    }

    if (sort) {
      sorted = this.state.products.sort(sort);
    } else {
      sorted = this.state.products;
    }
    this.setState({ reload: true });
    await this.setState({
      products: sorted,
      productToShow: sorted.slice(0, this.state.postPerPage),
    });
    this.loadMore();
  };
  private onProductSelect = () => {
    const { history, city } = this.props;
    history.push({
      pathname: "/productItem",
      state: {
        cityImages: city.images,
      },
    });
  };

  private onResultClick = async (item) => {
    this.setState({ input: item.name });
    this.props.handleClickCity(item);
    window.location.reload(true);
  };

  public componentDidMount = async () => {
    const { city } = this.props;

    this.openModal();
    const cityList = await getCities();
    if (cityList) {
      this.setState({ cityList: cityList.data.List });
    } else {
      console.log("no cityList response");
    }

    try {
      const response = await getProductByLocationAndDate(city.latitude, city.longitude, "100", new Date());

      if (response) {
        console.log(`Got ${response.data.List.length} products`);
        this.setState({
          products: response.data.List,
          productToShow: response.data.List.slice(0, this.state.postPerPage),
        });
        if (this.state.productToShow.length < this.state.postPerPage) {
          this.setState({ hideLoadMore: true });
        }
      } else {
        console.log("no response");
      }
    } catch (e) {
      // this.props.history.push("/");
    }

    const myInput: any = document.getElementById("city-search");
    myInput.addEventListener("keyup", this.onEnter);

    this.closeModal();
  };

  public componentWillUnmount = () => {
    const myInput: any = document.getElementById("city-search");
    myInput.removeEventListener("keyup", this.onEnter);
  };

  onEnter = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.onSubmit(e);
    }
  };

  private loadMore = () => {
    const visibleItemsCount = this.state.productToShow.length;
    const totalItems = this.state.products.length;

    const datatoLoad = [
      ...this.state.productToShow,
      ...this.state.products.slice(visibleItemsCount, visibleItemsCount + this.state.postPerPage),
    ];

    const isAllItemsLoaded = datatoLoad.length === totalItems;

    this.setState({
      productToShow: datatoLoad,
      hideLoadMore: isAllItemsLoaded,
    });
  };

  render() {
    const { products, focused, modalIsOpen, input, productToShow, hideLoadMore } = this.state;
    const { city } = this.props;
    console.log("prod", products.length);
    return (
      <Box sx={{ overflow: "hidden" }}>
        <Flex flexWrap="wrap" justifyContent="flex-start">
          <HeaderItem imageUrls={city.images}>
            <Box className="search" width={"100%"}>
              <HeaderSearchBox
                focused={focused}
                handleInputFocus={this.handleInputFocus}
                handleInputBlur={this.handleInputBlur}
                input={input}
                onInputChange={this.onInputChange}
                onSubmit={this.onSubmit}
              />
              {this.state.input && focused && this.state.matchedCities.length > 0 ? (
                <RenderResult matchedCities={this.state.matchedCities} onResultClick={this.onResultClick} />
              ) : null}
              {this.state.errorMessage ? (
                <MediumItalic fontSize={"15px"} sx={{ marginTop: "20px" }} mx={window.innerWidth > 1023 ? 6 : 0}>
                {this.state.errorMessage}
              </MediumItalic>
              ) : null}
              <ModalLoadingView modalIsOpen={modalIsOpen} closeModal={() => this.closeModal()} />
              <HeaderContent history={this.props.history} city={city} />
            </Box>
            <HeaderSubContent products={products} modalIsOpen={modalIsOpen} />
          </HeaderItem>
        </Flex>
        {products.length > 0 ? (
          <React.Fragment>
            <Box
              sx={{
                bg: theme.colors.solitude,
                width: "100%",
              }}
            >
              <Flex justifyContent="flex-end">
                <Box sx={theme.styles.prodFilterContainer}>
                  <StyledSelect
                    className={"shadowBox"}
                    options={options}
                    values={[]}
                    onChange={this.onSort}
                    placeholder={"Sort"}
                  />
                </Box>
              </Flex>
            </Box>
            <Box sx={{ bg: theme.colors.solitude }}>
              {products ? (
                <LoadMore
                  navigate={this.onProductSelect}
                  products={productToShow}
                  loadmore={this.loadMore}
                  hideload={hideLoadMore}
                />
              ) : null}
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            {!modalIsOpen ? (
              <Flex justifyContent={"center"} flexWrap={"wrap"}>
                <Box sx={{ py: "5%" }}>
                  <Image
                    sx={{
                      height: "50px",
                      width: "50px",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    src={Alert}
                  />
                  <Box pt={3} />
                  <NextMedium textAlign={"center"} fontSize={"23px"}>
                    We can’t find anything for your selected location.
                  </NextMedium>
                </Box>
              </Flex>
            ) : null}
          </React.Fragment>
        )}
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    city: state.userSelectedCity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleClickCity(obj) {
      dispatch(saveCityObj(obj));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Product));

import styled from "styled-components";
import Select from "react-dropdown-select";

export const StyledSelect = styled(Select)`
  background: #f3f5fb;
  border: #333 !important;
  color: #1a1f71 !important;
  font-family: AvenirNextDemiBold;
  cursor: pointer;
  .react-dropdown-select-clear,
  .react-dropdown-select-dropdown-handle {
    color: #1a1f71;
  }
  .react-dropdown-select-option {
    border: 1px solid #fff;
  }
  .react-dropdown-select-item {
    color: #333;
  }
  .react-dropdown-select-input {
    color: #1a1f71;
  }
  .react-dropdown-select-input::-webkit-input-placeholder {
    font-family: AvenirNextRegular;
    color: #1a1f71;
    font-weight: bold;
  }
  .react-dropdown-select-dropdown {
    position: absolute;
    left: 0;
    border: none;
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    max-height: 300px;
    overflow: auto;
    z-index: 9;
    background: #f3f5fb;
    box-shadow: none;
    color: #1a1f71 !important;
    -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  }
  .react-dropdown-select-item {
    color: #1a1f71;
    border-bottom: 1px solid #f3f5fb;
  }
  .react-dropdown-select-item.react-dropdown-select-item-selected,
  .react-dropdown-select-item.react-dropdown-select-item-active {
    background: #fa2e81;
    border-bottom: 1px solid #f3f5fb;
    color: #fff;
    font-weight: bold;
  }
  .react-dropdown-select-item.react-dropdown-select-item-disabled {
    background: #777;
    color: #ccc;
  }
`;

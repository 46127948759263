import React from "react";
import { Switch, Route } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router";
import Home from "../screen/Home";
import PageNotFound from "../screen/PageNotFound";
import Product from "../screen/Product/Product";
import ProductItem from "../screen/Product/ProductItem";
import ProductAvailability from "../screen/Product/ProductAvailability";
import ProductBooking from "../screen/Product/ProductBooking";
import ProductPayment from "../screen/Product/ProductPayment";
import Receipt from "../screen/Product/Receipt";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import { getStripeKey } from "../api";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(getStripeKey());
const elFonts = [
  {
    src: "url(/fonts/AvenirNextCondensed-DemiBold5DegreeSkew.woff) format(woff)",
    family: "Lato",
  },
];
class Routers extends React.Component<RouteComponentProps> {
  render() {
    return (
      <Elements stripe={stripePromise} options={{ fonts: elFonts }}>
        <main>
          <Switch>
            <Route exact path="/loc" component={"Home"}>
              <Home />
            </Route>
            <Route exact path="/" component={"Home"}>
              <Home />
            </Route>
            <Route path="/products" component={"Product"}>
              <Product />
            </Route>
            <Route path="/productItem" component={"Product Item"}>
              <ProductItem />
            </Route>
            <Route path="/productShare/:productID" component={"Product Item Link"}>
              <ProductItem />
            </Route>
            <Route path="/productAvailability" component={"Product Availability"}>
              <ProductAvailability />
            </Route>
            <Route path="/productBooking" component={"Product Booking"}>
              <ProductBooking />
            </Route>
            <Route path="/productPayment" component={"Product Payment"}>
              <ElementsConsumer>
                {({ stripe, elements }) => <ProductPayment stripe={stripe} elements={elements} />}
              </ElementsConsumer>
            </Route>
            <Route path="/receipt" component={"Receipt"}>
              <Receipt />
            </Route>
            <Route component={PageNotFound} />
          </Switch>
        </main>
      </Elements>
    );
  }
}

export default withRouter(Routers);

import styled from "styled-components";

export const SearchInput = styled.input.attrs({
  placeholder: "Search a city",
  id: "city-search"
})`
  &.slide-enter {
    transition: all 0.3s ease-out;
  }
  &.slide-enter-active {
    width: ${props => (props.width ? props.width : "50%")}
  }
  &.slide-exit {
    transition: all 0.3s ease-out;
  }
  &.slide-exit-active {
    width: 20%;
  }
  width: 20%;
  min-width: 200px;
  height: 50px;
  padding: 0 30px 0 20px;
  box-sizing: border-box;
  margin-top: 24px;
  border: none;
  outline: none;
  border-radius: 25px;
  background: #ffffff;
  font-size: 18px;
  font-family: AvenirNextMedium;
  color: #1a1f71;
  ::placeholder {
    font-family: AvenirNextMedium;
    font-size: 18px;
  }
  &.focused {
    width: ${props => (props.width ? props.width : "65%")}
  }
  @media (max-width: 600px) {
    width: 60%;
    min-width: 200px;
    &.slide-enter {
      transition: all 0.3s ease-out;
    }
    &.slide-enter-active {
      width: ${props => (props.width ? props.width : "90%")}
    }
    &.slide-exit {
      transition: all 0.3s ease-out;
    }
    &.slide-exit-active {
      width: 60%;
    }
    &.focused {
      width: ${props => (props.width ? props.width : "90%")}
    }
  }
`;


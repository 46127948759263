/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { Flex, Box, Button, Text, Image } from "rebass";
import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { theme } from "../../constants";
import { withRouter } from "react-router";
import { Input } from "@rebass/forms";
import {
  Footer,
  HeaderItem,
  BoldSkew,
  NextMedium,
  NextRegular,
  DemiBold,
  MediumItalic,
  DemiBoldSkew,
  useWindowSize,
  ModalLeviesView,
  ModalLoadingView,
  BookingAndPaymentErrorModal,
  GradientModal,
} from "../../components";
import { BsChevronLeft } from "react-icons/bs";
import { IoMdArrowDropright } from "react-icons/io";
import { FaArrowRight, FaCheck } from "react-icons/fa";
import { Hide } from "@rebass/hide";
import { getProductDetails, getDiscount, completeBooking } from "../../api";
import { calculateDuration, tConvert } from "../../utils";
import LocationIcon from "../../assets/travelExperience/LocationIcon.png";
import StopWatch from "../../assets/travelExperience/HoursIcon.png";
import ExperiencesIcon from "../../assets/travelExperience/Experiences-Icon.png";
import Loudspeaker from "../../assets/travelExperience/Loudspeaker.png";
import AdultImg from "../../assets/travelExperience/adult.png";
import ChildImg from "../../assets/travelExperience/child.png";
import InfantImg from "../../assets/travelExperience/infant.png";
import CalendarTick from "../../assets/travelExperience/CalendarTick.png";
import PoweredStripe from "../../assets/travelExperience/PoweredStripe.png";
import PickUp from "../../assets/travelExperience/PickUp.png";
import Moment from "moment";
import MASTER from "../../assets/travelExperience/MC.png";
import VISA from "../../assets/travelExperience/visa.png";
import picImage from "../../assets/travelExperience/card.png";
import useResponsiveFontSize from "../../components/useResponsiveFontSize";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "16px",
          color: "#1A1F71",
          letterSpacing: "0.025em",
          fontFamily: "Lato",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

interface IProps {
  history: any;
  product: any;
  location: any;
  cityName: any;
  stripe: any;
  elements: any;
}
interface IState {
  userSelectedProduct: any;
  productItem: any;
  modalIsOpen: boolean;
  levimodalIsOpen: boolean;
  travellerCount: any;
  fareLabel: any;
  flavours: any;
  flavourItem: any;
  isDisabled: boolean;
  selectedItem: any;
  showGradientModal: boolean;
  selectedDate: any;
  pickedProduct: any;
  payment: any;
  discountCode: any;
  discountSet: boolean;
  discountMessage: string;
  isSampleSuccessOpen: boolean;
  errorModalIsOpen: boolean;
  errorType: string;
}

class ProductPayment extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      userSelectedProduct: "",
      productItem: null,
      modalIsOpen: false,
      levimodalIsOpen: false,
      fareLabel: {
        adult: { exist: false, minAge: 0, maxAge: 0 },
        child: { exist: false, minAge: 0, maxAge: 0 },
        infant: { exist: false, minAge: 0, maxAge: 0 },
      },
      travellerCount: {
        adult: 1,
        child: 0,
        infant: 0,
      },
      flavours: null,
      flavourItem: "",
      isDisabled: true,
      selectedItem: null,
      showGradientModal: false,
      selectedDate: null,
      pickedProduct: null,
      payment: null,
      discountCode: null,
      discountSet: false,
      discountMessage: "",
      isSampleSuccessOpen: false,
      errorModalIsOpen: false,
      errorType: "",
    };
  }

  public componentWillMount = async () => {
    this.openModal();
    console.log("componentWillMount: payment" + this.props.location.state.payment);
    try {
      const response = await getProductDetails(this.props.product);
      if (response.status === 200) {
        await this.setState({
          productItem: response.data.product,
          pickedProduct: this.props.location.state.pickedProduct,
          payment: this.props.location.state.payment,
        });
      }
    } catch (e) {
      console.log(e);
    }
    this.closeModal();
  };

  onEnter = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      e.preventDefault();
      this.calculateDiscount();
    }
  };

  public componentDidMount = () => {
    window.addEventListener("keyup", this.onEnter);
  };

  public componentWillUnmount = () => {
    window.removeEventListener("keyup", this.onEnter);
  };

  private openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  private closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  private openLeviModal = () => {
    this.setState({ levimodalIsOpen: true });
  };

  private closeLeviModal = () => {
    this.setState({ levimodalIsOpen: false });
  };

  private openSuccessModal = () => {
    this.setState({ isSampleSuccessOpen: true });
  };

  private closeSuccessModal = () => {
    this.setState({ isSampleSuccessOpen: false });
  };

  private closeErrorModal = () => {
    this.setState({ errorModalIsOpen: false });
  };

  private modalBlueContent = () => {
    let [winWidth] = useWindowSize();
    return (
      <Flex justifyContent="center" flexWrap="wrap" flexDirection="column">
        <Flex justifyContent="center">
          <Image
            sx={{
              height: winWidth > 900 ? "74px" : "60px",
            }}
            src={Loudspeaker}
          />
        </Flex>
        <Box>
          <BoldSkew
            fontSize={winWidth > 499 ? "40px" : "30px"}
            spacing={"-1px"}
            color="#FFF"
            sx={{ textAlign: "center", py: "10px" }}
          >
            ON-THE-DAY-FEE
          </BoldSkew>
          <MediumItalic fontSize={winWidth > 499 ? "18px" : "15px"} sx={{ textAlign: "center" }}>
            This experience has a fee payable on the day of your experience.
          </MediumItalic>
          <Flex justifyContent="center">
            <Box sx={{ width: "50%" }}>
              <ul>
                {this.state.productItem.levies.map((item: any, index: any) => (
                  <li>
                    <MediumItalic fontSize={winWidth > 499 ? "18px" : "15px"} sx={{ textAlign: "center" }}>
                      {item.description} fee ${item.amount}p/p
                    </MediumItalic>
                  </li>
                ))}
              </ul>
            </Box>
          </Flex>
        </Box>
      </Flex>
    );
  };

  private modalWhiteContent = () => {
    let [winWidth] = useWindowSize();
    return (
      <Flex justifyContent="center">
        <Box width={1 / 2} onClick={this.closeLeviModal} sx={{ cursor: "pointer", outline: "none" }}>
          <Flex className={"lineBreak"} justifyContent="center">
            <BoldSkew
              sx={{ textAlign: "center" }}
              color={theme.colors.wildStrawberry}
              fontSize={winWidth > 499 ? "22px" : "18px"}
              spacing="-1px"
              lineHeight="30px"
            >
              ACCEPT AND {"\n"} CONTINUE BOOKING
            </BoldSkew>
            <Box>
              <IoMdArrowDropright style={{ color: theme.colors.wildStrawberry, fontSize: "50px" }} />
            </Box>
          </Flex>
        </Box>
      </Flex>
    );
  };

  ////// Replace with receipt page

  private modalSuccessBlueContent = () => {
    let [winWidth] = useWindowSize();
    return (
      <Flex justifyContent="center" flexWrap="wrap" flexDirection="column">
        <Flex justifyContent="center">
          <Image
            sx={{
              height: winWidth > 900 ? "74px" : "60px",
            }}
            src={ExperiencesIcon}
          />
        </Flex>
        <Box>
          <BoldSkew
            fontSize={winWidth > 499 ? "40px" : "30px"}
            spacing={"-1px"}
            color="#FFF"
            sx={{ textAlign: "center" }}
          >
            SUCCESS!!!!
          </BoldSkew>
        </Box>
      </Flex>
    );
  };

  private modalSuccessWhiteContent = () => {
    let [winWidth] = useWindowSize();
    return (
      <Flex justifyContent="center">
        <Box width={1 / 2} onClick={this.closeLeviModal} sx={{ cursor: "pointer", outline: "none" }}>
          <Flex>
            <BoldSkew
              sx={{ textAlign: "center" }}
              color={theme.colors.wildStrawberry}
              fontSize={winWidth > 499 ? "22px" : "18px"}
              spacing="-1.25px"
            >
              CLOSE PLEASE
            </BoldSkew>
            <Box>
              <IoMdArrowDropright style={{ color: theme.colors.wildStrawberry, fontSize: "50px" }} />
            </Box>
          </Flex>
        </Box>
      </Flex>
    );
  };

  private handleDiscountCode = (e) => {
    const discountCode = e.target.value;
    this.setState({ discountCode });
  };

  private calculateDiscount = async () => {
    const { discountCode } = this.state;
    if (discountCode !== null) {
      try {
        const request = await getDiscount(discountCode);
        if (request.data.status === "success") {
          let discountedValue = (this.state.payment.totalValue * request.data.body.percentage) / 100;
          let finalTotal = this.state.payment.totalValue - discountedValue;
          this.setState((prevState) => ({
            payment: {
              ...prevState.payment,
              totalValue: finalTotal,
            },
          }));
          this.setState({ discountSet: true, discountMessage: "Discount added!" });
        }
      } catch (e) {
        this.setState({ discountSet: false, discountMessage: "Discount code not accepted" });
        console.log(e);
      }
    }
  };

  private handleClick = (item: any, index: number) => {
    this.setState({ isDisabled: false });
    this.setState({ selectedItem: item, flavourItem: index });
  };

  private handleSubmit = async (event) => {
    event.preventDefault();
    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    this.setState({ showGradientModal: true });
    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      console.log("THROW ERROR");
      this.setState({ showGradientModal: false });
      throw new Error("Error - CardNumberElement is null");
    }
    const result = await stripe.createToken(cardElement);
    const token = result.token;
    //console.log("got token:" + JSON.stringify(result.token));
    const { discountCode } = this.state;

    const bookingData = {
      token: token,
      cartId: this.state.payment.cartId,
      email: this.state.payment.email,
      promoCode: discountCode,
    };

    try {
      const response = await completeBooking(bookingData);
      console.log("booking response:" + response);
      this.closeModal();
      if (response.status === 200) {
        // Success message
        this.setState({ showGradientModal: false });
        console.log("Booked!!!!!");
        console.log(response.data);
        this.openSuccessModal();
        // Go to receipt Page!
        const { history } = this.props;
        history.push({
          pathname: "/receipt",
          state: {
            pickedProduct: this.state.pickedProduct,
            payment: this.state.payment,
            bookedData: response.data,
          },
        });
      } else {
        //This will only happen for non 'error' return codes (so rarely)
        console.log(JSON.stringify(response));
        this.setState({ showGradientModal: false });
        this.setState({ errorType: "", errorModalIsOpen: true });
        this.closeModal();
        return;
      }
    } catch (e) {
      // Handle 400 error codes (e.g. bad card, card declined, cart expired, already checked out)
      console.log(e);
      if (e.response) {
        this.setState({ showGradientModal: false });
        this.setState({ errorType: "payment", errorModalIsOpen: true });
        console.log(e.response.data);
        return;
      }
      this.closeModal();
    }
  };

  render() {
    const {
      productItem,
      modalIsOpen,
      levimodalIsOpen,
      fareLabel,
      isDisabled,
      pickedProduct,
      payment,
      discountSet,
      discountMessage,
      showGradientModal,
      errorModalIsOpen,
      errorType,
    } = this.state;

    return !payment ? (
      <Box>
        <Flex flexWrap="wrap">
          <HeaderItem>
            <Box />
          </HeaderItem>
        </Flex>
        <ModalLoadingView modalIsOpen={modalIsOpen} closeModal={() => this.closeModal()} />
      </Box>
    ) : (
      <React.Fragment>
        {productItem ? (
          <Box>
            <BodyContent
              product={productItem}
              pickedProduct={pickedProduct}
              payment={payment}
              fareLabel={fareLabel}
              handleClick={(item: any, index: number) => this.handleClick(item, index)}
              handleDiscountCode={(e: any) => this.handleDiscountCode(e)}
              calculateDiscount={this.calculateDiscount}
              discountSet={discountSet}
              discountMessage={discountMessage}
              openLeviModal={this.openLeviModal}
            />
            <Footer>
              <Hide xsmall small>
                <WebFooter payment={payment} handleSubmit={this.handleSubmit} disabled={isDisabled} />
              </Hide>
              <Hide medium large xlarge>
                <MobileFooter payment={payment} handleSubmit={this.handleSubmit} disabled={isDisabled} />
              </Hide>
            </Footer>
            {productItem.levies ? (
              <ModalLeviesView
                modalIsOpen={levimodalIsOpen}
                closeModal={() => this.closeLeviModal()}
                blueContent={this.modalBlueContent}
                whiteContent={this.modalWhiteContent}
              />
            ) : null}
            <ModalLeviesView
              modalIsOpen={this.state.isSampleSuccessOpen}
              closeModal={() => this.closeSuccessModal()}
              blueContent={this.modalSuccessBlueContent}
              whiteContent={this.modalSuccessWhiteContent}
            />
            {showGradientModal ? (
              <GradientModal modalIsOpen={showGradientModal} text={"PROCESSING PAYMENT"} image={picImage} />
            ) : null}
          </Box>
        ) : null}
        <BookingAndPaymentErrorModal
          modalIsOpen={errorModalIsOpen}
          closeModal={() => this.closeErrorModal()}
          type={errorType}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    product: state.userSelectedProduct,
    cityName: state.userSelectedCity,
  };
};

export default connect(mapStateToProps, null)(withRouter(ProductPayment));

function BodyContent(props: any) {
  const product: any = props.product;
  const options = useOptions();

  let [winWidth] = useWindowSize();
  return product ? (
    <Box
      width={1}
      px={winWidth > theme.responsive.medium ? "144px" : "10px"}
      py={winWidth > theme.responsive.medium ? "20px" : "15px"}
      sx={{ bg: theme.colors.solitude }}
    >
      <Box width={1} sx={{ bg: "#FFF", borderRadius: "20px", pb: 20 }} className={"shadowBox"}>
        <Box width={1} sx={{ bg: "#FFF", borderRadius: "20px" }} px={"10px"} py={"10px"} className={"shadowBox"}>
          <Flex flexWrap="wrap" flexDirection={winWidth > theme.responsive.large ? "row" : "column"}>
            <Box width={winWidth > theme.responsive.large ? 0.25 : 1} height={"180px"}>
              <Box
                sx={{
                  height: "180px",
                  backgroundImage: `url(${product.images[0].urlPreviewSecure})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: 8,
                  width: "100%",
                }}
              />
            </Box>
            <Box
              width={winWidth > theme.responsive.large ? 0.7 : 1}
              sx={{
                px: winWidth > theme.responsive.large ? 10 : 2,
              }}
            >
              <DemiBold
                spacing={"-1px"}
                fontSize={winWidth > 499 ? "35px" : "28px"}
                sx={{ marginTop: winWidth > 1024 ? "0px" : "10px" }}
              >
                {props.pickedProduct.product.name}
              </DemiBold>
              {product.levies && (
                <NextRegular color={theme.colors.wildStrawberry} fontSize={"16px"} spacing={"-3px"}>
                  This experience has an{" "}
                  <a
                    className="item-hover-underline"
                    style={{ textDecoration: "underline" }}
                    onClick={props.openLeviModal}
                  >
                    on-the-day fee
                  </a>
                </NextRegular>
              )}
              <Box mt={"15px"}>
                <Flex
                  flexDirection={winWidth > 1278 ? "row" : "column"}
                  justifyContent={"space-between"}
                  sx={{ width: "80%" }}
                >
                  <Box sx={{ width: "80%" }}>
                    <Flex m={theme.margin.m5}>
                      <Box sx={{ width: winWidth > 1024 ? "5%" : "7%" }} mt={"3px"} mr={"10px"}>
                        <Image
                          sx={{
                            height: "19px",
                            width: "19px",
                          }}
                          src={LocationIcon}
                        />
                      </Box>
                      <Box>
                        <NextMedium sx={{ textDecoration: "underline" }} fontSize={"18px"}>
                          {product.locationStart}
                        </NextMedium>
                      </Box>
                    </Flex>
                  </Box>
                  <Box sx={{ width: winWidth > 1024 ? "20%" : "80%" }}>
                    <Flex m={theme.margin.m5}>
                      <Box mt={"3px"} mr={"10px"}>
                        <Image
                          sx={{
                            height: "19px",
                            width: "19px",
                          }}
                          src={StopWatch}
                        />
                      </Box>
                      <Box>
                        <NextMedium fontSize={"18px"} spacing={"-1px"}>
                          {calculateDuration(product)}
                        </NextMedium>
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
                <Flex
                  flexDirection={winWidth > 1278 ? "row" : "column"}
                  justifyContent={"space-between"}
                  sx={{ width: winWidth > 1024 ? "80%" : "100%" }}
                >
                  <Box sx={{ width: "80%" }}>
                    <Flex m={theme.margin.m5}>
                      <Box mt={"3px"} mr={"10px"}>
                        <Image
                          sx={{
                            height: "19px",
                            width: "19px",
                          }}
                          src={CalendarTick}
                        />
                      </Box>
                      <Box>
                        <NextMedium fontSize={"18px"} spacing={"-1px"}>
                          {Moment(props.pickedProduct.date.replace(/-/g, "/")).format("DD MMMM YYYY")}
                        </NextMedium>
                      </Box>
                    </Flex>
                  </Box>
                  <Box sx={{ width: winWidth > 1024 ? "20%" : "80%" }}>
                    <Flex flexDirection={winWidth > theme.responsive.mobile ? "row" : "column"} m={theme.margin.m5}>
                      <Box mr={theme.margin.m10}>
                        <Flex>
                          <Box mr={"5px"}>
                            <Image
                              sx={{
                                height: "19px",
                                width: "19px",
                              }}
                              src={AdultImg}
                            />
                          </Box>
                          <Box>
                            <NextMedium fontSize={"18px"} spacing={"-1px"}>
                              {props.pickedProduct.travellers.adult}{" "}
                              {props.pickedProduct.travellers.adult > 1 ? "Adults" : "Adult"}
                            </NextMedium>
                          </Box>
                        </Flex>
                      </Box>
                      {props.pickedProduct.travellers.child > 0 ? (
                        <Box mr={theme.margin.m10}>
                          <Flex>
                            <Box mr={"5px"}>
                              <Image
                                sx={{
                                  height: "19px",
                                  width: "19px",
                                }}
                                src={ChildImg}
                              />
                            </Box>
                            <Box>
                              <NextMedium fontSize={"18px"} spacing={"-1px"}>
                                {props.pickedProduct.travellers.child}{" "}
                                {props.pickedProduct.travellers.child > 1 ? "Childrens" : "Children"}
                              </NextMedium>
                            </Box>
                          </Flex>
                        </Box>
                      ) : null}
                      {props.pickedProduct.travellers.infant > 0 ? (
                        <Box mr={theme.margin.m10}>
                          <Flex>
                            <Box mr={"5px"}>
                              <Image
                                sx={{
                                  height: "19px",
                                  width: "19px",
                                }}
                                src={InfantImg}
                              />
                            </Box>
                            <Box>
                              <NextMedium fontSize={"18px"} spacing={"-1px"}>
                                {props.pickedProduct.travellers.infant}{" "}
                                {props.pickedProduct.travellers.infant > 1 ? "Infants" : "Infant"}
                              </NextMedium>
                            </Box>
                          </Flex>
                        </Box>
                      ) : null}
                    </Flex>
                  </Box>
                </Flex>
                {props.payment.pickupPoint && (
                  <Flex m={theme.margin.m5}>
                    <Box mt={"3px"} mr={"10px"}>
                      <Image
                        sx={{
                          height: "19px",
                          width: "19px",
                        }}
                        src={PickUp}
                      />
                    </Box>
                    <Box>
                      <NextMedium fontSize={"18px"}>
                        {props.payment.pickupPoint.pickupPoint.name +
                          " at " +
                          tConvert(props.payment.pickupPoint.pickupTime.slice(0, 5))}
                      </NextMedium>
                    </Box>
                  </Flex>
                )}
              </Box>
            </Box>
          </Flex>
        </Box>
        <Box sx={{ padding: "20px 40px" }}>
          <BoldSkew color={theme.colors.midnightBlue} fontSize={winWidth > 499 ? "40px" : "30px"} spacing={"-2.4px"}>
            REVIEW & PAY
          </BoldSkew>
          <NextMedium fontSize={winWidth > 499 ? "18px" : "14px"} color={theme.colors.midnightBlue}>
            Please confirm the details are correct and pay securely below
          </NextMedium>
        </Box>
        <Box mx={winWidth > theme.responsive.large ? "30px" : "5%"}>
          <Box width={winWidth > theme.responsive.large ? "75%" : "100%"} py={"5px"}>
            <Flex>
              <Box width={winWidth > 1024 ? "50%" : "100%"} px={"10px"}>
                <Input
                  className={"shadowBox"}
                  sx={{ ...theme.styles.promoForm }}
                  disabled={props.discountSet ? props.discountSet : false}
                  id="discountCode"
                  name="discountCode"
                  type="text"
                  placeholder="ADD DISCOUNT CODE"
                  onChange={(e: any) => {
                    props.handleDiscountCode(e);
                  }}
                />
              </Box>
              <Box>
                <Button
                  className={"shadowBox"}
                  sx={theme.styles.promoButton}
                  disabled={props.discountSet ? props.discountSet : false}
                  onClick={props.calculateDiscount}
                >
                  {props.discountSet ? (
                    <FaCheck style={{ color: theme.colors.green, fontSize: "20px" }} />
                  ) : (
                    <FaArrowRight style={{ fontSize: "20px" }} />
                  )}
                </Button>
              </Box>
            </Flex>
            <Box p={"10px"} width={winWidth > theme.responsive.large ? "75%" : "100%"}>
              {props.discountSet ? (
                <DemiBoldSkew color={theme.colors.green}>{props.discountMessage}</DemiBoldSkew>
              ) : (
                <DemiBoldSkew color={theme.colors.wildStrawberry}>{props.discountMessage}</DemiBoldSkew>
              )}
            </Box>
          </Box>
          <Box width={winWidth > theme.responsive.large ? "75%" : "100%"}>
            <Flex justifyContent="center">
              <Box width={"100%"} sx={{ bg: theme.colors.solitude, borderRadius: "30px", p: "20px" }}>
                <Box px={10} mb={10}>
                  <Flex justifyContent={"space-between"}>
                    <Box>
                      <DemiBoldSkew color={theme.colors.skyblue} fontSize={"12px"}>
                        NAME ON CARD
                      </DemiBoldSkew>
                      <Input
                        sx={theme.styles.creditForm}
                        id="cardName"
                        name="cardName"
                        type="text"
                        placeholder="JOHN SMITH"
                      />
                    </Box>
                    <Box>
                      <Image sx={{ height: winWidth > 1024 ? "35px" : "20px" }} src={MASTER} />
                      <Image sx={{ height: winWidth > 1024 ? "35px" : "20px" }} src={VISA} />
                    </Box>
                  </Flex>
                </Box>
                <Flex flexDirection={winWidth > 1024 ? "row" : "column"}>
                  <Box width={winWidth > 1024 ? 1 / 3 : 1} px={10}>
                    {props.errorMessage ? props.getErrorMessage() : null}
                    <DemiBoldSkew color={theme.colors.skyblue} fontSize={"12px"}>
                      CARD NUMBER
                    </DemiBoldSkew>
                    <CardNumberElement options={options} />
                  </Box>
                  <Box width={winWidth > 1024 ? 1 / 3 : 1} px={10}>
                    <DemiBoldSkew color={theme.colors.skyblue} fontSize={"12px"}>
                      EXPIRY
                    </DemiBoldSkew>
                    <CardExpiryElement className={"card-style"} options={options} />
                  </Box>
                  <Box width={winWidth > 1024 ? 1 / 3 : 1} px={10}>
                    <DemiBoldSkew color={theme.colors.skyblue} fontSize={"12px"}>
                      CCV
                    </DemiBoldSkew>
                    <CardCvcElement options={options} />
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>
          <Box width={winWidth > theme.responsive.large ? "75%" : "100%"}>
            <Flex p={"5px"} justifyContent="flex-end">
              <Image
                sx={{
                  height: "19px",
                }}
                src={PoweredStripe}
              />
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : null;
}

function WebFooter(props: any) {
  return !props.payment ? null : (
    <Flex px={"130px"} py={3} justifyContent="space-between">
      <Box sx={{ pt: theme.padding.p10 }}>
        <Button
          onClick={() => window.history.go(-1)}
          className={"item-hover"}
          sx={Object.assign({}, theme.styles.footerGoBack, {
            color: theme.colors.periglacialblue,
            border: 0,
            backgroundColor: "transparent",
            width: "141px",
            height: "55px",
            fontSize: 20,
          })}
        >
          <Flex>
            <BsChevronLeft />
            <Text> Go Back</Text>
          </Flex>
        </Button>
      </Box>
      <Box sx={{ pt: theme.padding.p10 }}>
        <Flex justifyContent="space-between">
          <BoldSkew color={theme.colors.midnightBlue} fontSize={"40px"} spacing={"0px"}>
            From ${props.payment.totalValue.toFixed(2)}
          </BoldSkew>
          <Box mt={"25px"} px={"10px"} />
          <Button
            //disabled={props.disabled}
            onClick={props.handleSubmit}
            sx={Object.assign({}, theme.styles.footerCheckAvailability, {
              color: theme.colors.white,
              backgroundColor: theme.colors.malachite,
              outline: "none",
              cursor: "pointer",
              width: "231px",
              height: "47px",
              fontSize: 20,
            })}
          >
            PAY NOW
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}

function MobileFooter(props: any) {
  return (
    <div>
      <Flex px={3} justifyContent="center">
        <BoldSkew color={theme.colors.midnightBlue} fontSize={"19px"} spacing={"0px"}>
          From ${props.payment.totalValue.toFixed(2)}
        </BoldSkew>
      </Flex>
      <Box>
        <Flex justifyContent="center">
          <Button
            // disabled={props.disabled}
            onClick={props.handleSubmit}
            sx={Object.assign({}, theme.styles.footerCheckAvailability, {
              color: theme.colors.white,
              backgroundColor: theme.colors.malachite,
              outline: "none",
              cursor: "pointer",
              width: "50%",
              fontSize: "13px",
            })}
          >
            <Text m={"auto"}>PAY NOW</Text>
          </Button>
        </Flex>
      </Box>
      <Box>
        <Flex mt={1} justifyContent="center">
          <Button
            onClick={() => window.history.go(-1)}
            className={"item-hover"}
            sx={Object.assign({}, theme.styles.footerCheckAvailability, {
              color: theme.colors.periglacialblue,
              backgroundColor: "transparent",
              width: "50%",
              height: "6.5vw",
              fontSize: "13px",
            })}
          >
            <Flex justifyContent="center">
              <Text mt={"-1px"}>{"< Go Back"}</Text>
            </Flex>
          </Button>
        </Flex>
      </Box>
    </div>
  );
}

import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Box, Flex, Image, Button } from "rebass";
import { withRouter } from "react-router";
import {
  useWindowSize,
  BoldSkew,
  NextMedium,
  DemiBoldSkew,
  MediumItalic,
  DemiBold,
  NextRegular,
  ModalLoadingView,
  BoldItalic,
} from "../../components";
import { theme } from "../../constants";
import { getProductDetails, sendEmailCopy } from "../../api";
import { GoCheck } from "react-icons/go";
import LocationIcon from "../../assets/travelExperience/LocationIcon.png";
import CalendarTick from "../../assets/travelExperience/CalendarTick.png";
import HoursIcon from "../../assets/travelExperience/HoursIcon.png";
import TimeIcon from "../../assets/travelExperience/TimeIcon.png";
import AdultIcon from "../../assets/travelExperience/adult.png";
import { Input } from "@rebass/forms";
import { calculateDuration, tConvert, isValidEmail, renderCancellationPolicy } from "../../utils";
import Moment from "moment";
import { FiX } from "react-icons/fi";

interface IProps {
  history: any;
  product: any;
  cityName: any;
  location: any;
}
interface IState {
  productItem: any;
  pickedProduct: any;
  email: string;
  sending: boolean;
  emailValid: boolean;
  sent: boolean;
}

class Receipt extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      productItem: null,
      pickedProduct: null,
      email: "",
      sending: false,
      emailValid: false,
      sent: false,
    };
  }

  private handleInputEmail = (e) => {
    const email = e.target.value;
    const emailValid = isValidEmail(email);
    this.setState({ email, emailValid, sent: false });
  };

  private sendEmail = async () => {
    if (!this.state.email) {
      return;
    }
    const email = this.state.email;
    if (!isValidEmail(email)) {
      return;
    }

    console.log("Sending email to:" + email);
    const bookedData = this.props.location.state.bookedData;
    bookedData.email = email;
    this.setState({ sending: true });
    try {
      const res = await sendEmailCopy(bookedData);
      console.log(res.status);
      console.log(res.data);
    } catch (e) {
      console.log(e);
    }
    this.setState({ sending: false, sent: true, email: "" });
  };

  public componentWillMount = async () => {
    if (this.state.productItem) {
      return;
    }
    try {
      const response = await getProductDetails(this.props.product);
      if (response.status === 200) {
        this.setState({
          productItem: response.data.product,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { productItem, email, emailValid, sending, sent } = this.state;
    return (
      <React.Fragment>
        {productItem ? (
          <BodyContent
            product={productItem}
            email={email}
            onInputChange={this.handleInputEmail}
            pickedProduct={this.props.location.state.pickedProduct}
            payment={this.props.location.state.payment}
            sendEmail={this.sendEmail}
            sending={sending}
            emailValid={emailValid}
            bookedData={this.props.location.state.bookedData}
            sent={sent}
            goHome={() => {
              this.props.history.push("/");
            }}
          >
            BodyContent
          </BodyContent>
        ) : null}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    product: state.userSelectedProduct,
    cityName: state.userSelectedCity,
  };
};

export default connect(mapStateToProps, null)(withRouter(Receipt));

function BodyContent(props: any) {
  const bookedData: any = props.bookedData;
  const product: any = props.product;
  const sending = props.sending;
  const emailValid = props.emailValid;
  const buttonDisabled = sending || !emailValid;
  let [winWidth] = useWindowSize();
  return !bookedData ? null : (
    <Box
      width={1}
      px={winWidth > 600 ? "144px" : "20px"}
      py={winWidth > 600 ? "20px" : "15px"}
      sx={{ bg: theme.colors.solitude }}
    >
      <Box
        width={1}
        sx={{
          position: "relative",
          bg: "#FFF",
          borderRadius: "20px",
          py: "30px",
          px: winWidth > 600 ? "80px" : "20px",
        }}
        className={"shadowBox"}
      >
        <Flex alignItems="center" flexWrap="wrap" flexDirection="column">
          <Box
            onClick={props.goHome}
            sx={{
              cursor: "pointer",
              position: "absolute",
              top: "10px",
              right: "15px",
            }}
          >
            <FiX style={{ color: theme.colors.midnightBlue, fontSize: "25px" }} />
          </Box>
          <DemiBoldSkew
            sx={{ marginBottom: winWidth > 600 ? "20px" : "10px" }}
            fontSize={winWidth > 600 ? "32px" : "24px"}
            color={theme.colors.wildStrawberry}
            spacing="-2px"
          >
            BOOKING CONFIRMED!
          </DemiBoldSkew>
          <BoldSkew sx={{}} fontSize={winWidth > 600 ? "32px" : "24px"} color={"#1A1F71"} spacing="-2px">
            ${props.payment.totalValue.toFixed(2)}{" "}
          </BoldSkew>

          <Flex
            justifyContent="center"
            alignItems="center"
            width={winWidth > 600 ? "200px" : "100px"}
            height={winWidth > 600 ? "200px" : "100px"}
            sx={{
              borderRadius: "50%",
              border: winWidth > 600 ? "5px solid #00C45B" : "2.5px solid #00C45B",
              mt: winWidth > 600 ? "30px" : "10px",
              position: "relative",
            }}
          >
            <Image
              src={product.images[0].urlPreviewSecure}
              sx={{
                height: winWidth > 600 ? "180px" : "90px",
                width: winWidth > 600 ? "180px" : "90px",
                borderRadius: "50%",
              }}
            />
            <Flex
              justifyContent="center"
              alignItems="center"
              width={winWidth > 600 ? "65px" : "32px"}
              height={winWidth > 600 ? "65px" : "32px"}
              sx={{
                borderRadius: "50%",
                backgroundColor: "#00C45B",
                position: "absolute",
                bottom: "0px",
                right: "0px",
              }}
            >
              <GoCheck
                style={{
                  color: "white",
                }}
                size={winWidth > 600 ? 40 : 20}
              />
            </Flex>
          </Flex>

          <NextMedium
            fontSize={winWidth > 600 ? "40px" : "20px"}
            sx={{ my: winWidth > 600 ? "30px" : "20px", textAlign: "center" }}
          >
            {product.name}
          </NextMedium>
          <Flex
            justifyContent={winWidth > 600 ? "flex-start" : "center"}
            flexWrap="wrap"
            flexDirection="row"
            sx={{ mb: "20px" }}
          >
            <InfoBox img={LocationIcon} text="Open in Google Maps" openMap={true} product={props.product} />
            <InfoBox
              img={CalendarTick}
              text={Moment(props.pickedProduct.date).format("DD MMMM YYYY")}
              weekday={Moment(props.pickedProduct.date).format("dddd")}
            />
            {props.payment.pickupPoint && (
              <InfoBox img={TimeIcon} text={tConvert(props.payment.pickupPoint.pickupTime.slice(0, 5))} />
            )}
            <InfoBox img={HoursIcon} text={titleCase(calculateDuration(product))} />
            <InfoBox img={AdultIcon} pickedProduct={props.pickedProduct} />
          </Flex>

          <MediumItalic
            fontSize={winWidth > 600 ? "20px" : "15px"}
            color={theme.colors.wildStrawberry}
            spacing="-0.25px"
            textAlign="center"
          >
            Check your inbox, we’ve emailed you a detailed re-cap of your booking.
          </MediumItalic>

          <Flex
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            flexDirection="column"
            sx={{
              width: winWidth > 600 ? "80%" : "100%",
              height: winWidth > 600 ? "220px" : "auto",
              borderRadius: "20px",
              backgroundColor: "#F3F5FB",
              padding: "15px",
              mt: winWidth > 600 ? "60px" : "30px",
            }}
          >
            <DemiBold fontSize={winWidth > 600 ? "26px" : "20px"} sx={{ marginBottom: "12px" }}>
              OTHERS COMING ALONG WITH YOU?
            </DemiBold>
            <BoldItalic fontSize={winWidth > 600 ? "16px" : "12px"} color={theme.colors.wildStrawberry}>
              SEND THEM A COPY OF THE BOOKING DETAILS
            </BoldItalic>
            <div style={{ marginTop: winWidth > 600 ? "25px" : "12px" }}>
              <Input
                sx={winWidth > 600 ? theme.styles.shareEmailInput : theme.styles.shareEmailInputMobile}
                id="shareEmail"
                name="shareEmail"
                type="text"
                value={props.email}
                placeholder={props.sent ? "Email Sent!" : "Email Address"}
                onChange={(e: any) => {
                  props.onInputChange(e);
                }}
              />
              <ModalLoadingView modalIsOpen={sending} />
              <Button
                disabled={buttonDisabled}
                onClick={props.sendEmail}
                sx={Object.assign({}, theme.styles.sendEmailButton, {
                  height: winWidth > 600 ? "40px" : "32px",
                  width: winWidth > 600 ? "90px" : "60px",
                  fontSize: winWidth > 600 ? "20px" : "14px",
                  color: theme.colors.white,
                  backgroundColor: props.sent
                    ? "#00C45B"
                    : buttonDisabled
                    ? theme.colors.grey
                    : theme.colors.wildStrawberry,
                })}
              >
                {props.sent ? "SENT!" : "SEND"}
              </Button>
            </div>
          </Flex>
        </Flex>
        <Flex
          alignItems="flex-start"
          flexWrap="wrap"
          flexDirection="column"
          sx={{ mt: "50px", px: winWidth > 600 ? "134px" : "20px" }}
        >
          {bookedData.cart.reservations[0].reservationItems[0].ticketInfo ? (
            <InfoDetails
              title="ADDITIONAL INFORMATION"
              desc={bookedData.cart.reservations[0].reservationItems[0].ticketInfo}
            />
          ) : (
            <InfoDetails title="CONDITIONS" desc={props.pickedProduct.product.specialNotes} />
          )}

          <InfoDetails title="INCLUDES" desc={props.pickedProduct.product.includes} />
          <InfoDetails
            title="CANCELLATION POLICY"
            desc={renderCancellationPolicy(bookedData.cart.reservations[0].reservationItems[0].cancellationPolicy)}
          />
        </Flex>
      </Box>
    </Box>
  );
}

function InfoBox(props: any) {
  let [winWidth] = useWindowSize();
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexWrap="wrap"
      flexDirection="column"
      sx={{
        width: winWidth > 600 ? "180px" : "120px",
        height: winWidth > 600 ? "160px" : "100px",
        borderRadius: winWidth > 600 ? "18px" : "12px",
        backgroundColor: "#F3F5FB",
        padding: winWidth > 600 ? "15px" : "9px",
        margin: winWidth > 600 ? "15px" : "9px",
      }}
    >
      <Image
        sx={{
          height: winWidth > 600 ? "40px" : "24px",
          mb: winWidth > 600 ? "15px" : "9px",
        }}
        src={props.img}
      />
      {props.text ? (
        props.openMap ? (
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${props.product.latitudeStart},${props.product.longitudeStart}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <NextMedium
              fontSize={winWidth > 600 ? "18px" : "12px"}
              sx={{ textAlign: "center", textDecoration: "underline" }}
              spacing="0px"
            >
              {props.text}
            </NextMedium>
          </a>
        ) : (
          <NextMedium fontSize={winWidth > 600 ? "18px" : "12px"} sx={{ textAlign: "center" }} spacing="0px">
            {props.weekday ? (
              <NextMedium fontSize={winWidth > 600 ? "18px" : "12px"} sx={{ textAlign: "center" }} spacing="0px">
                {props.weekday}
              </NextMedium>
            ) : null}
            {props.text}
          </NextMedium>
        )
      ) : (
        <Fragment>
          <NextMedium fontSize={winWidth > 600 ? "18px" : "12px"} sx={{ textAlign: "center" }} spacing="0px">
            {props.pickedProduct.travellers.adult} {props.pickedProduct.travellers.adult > 1 ? "Adults" : "Adult"}
          </NextMedium>
          {props.pickedProduct.travellers.child > 0 ? (
            <NextMedium fontSize={winWidth > 600 ? "18px" : "12px"} sx={{ textAlign: "center" }} spacing="0px">
              {props.pickedProduct.travellers.child}{" "}
              {props.pickedProduct.travellers.child > 1 ? "Childrens" : "Children"}
            </NextMedium>
          ) : null}

          {props.pickedProduct.travellers.infant > 0 ? (
            <NextMedium fontSize={winWidth > 600 ? "18px" : "12px"} sx={{ textAlign: "center" }} spacing="0px">
              {props.pickedProduct.travellers.infant} {props.pickedProduct.travellers.infant > 1 ? "Infants" : "Infant"}
            </NextMedium>
          ) : null}
        </Fragment>
      )}
    </Flex>
  );
}

function InfoDetails(props: any) {
  let [winWidth] = useWindowSize();
  return (
    <Box sx={{ mt: winWidth > 600 ? "30px" : "18px" }}>
      <BoldItalic fontSize={winWidth > 600 ? "15px" : "12px"} color="#FA2E81" sx={{ marginBottom: "7px" }}>
        {props.title}
      </BoldItalic>
      <NextRegular fontSize={winWidth > 600 ? "15px" : "12px"} color="#1A1F71" sx={{ lineHeight: 1.5 }}>
        {props.desc}
      </NextRegular>
    </Box>
  );
}

function titleCase(str) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

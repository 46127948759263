export function filterandSortCities(list: any, value: string) {
  const matched = list
    .filter((item: any) => item.name.toLowerCase().includes(value.toLowerCase()))
    .sort((item1: any, item2: any) => (item1.name.toLowerCase().charAt(0) === value.toLowerCase().charAt(0) ? -1 : 1));
  return matched;
}

export function calculateDuration(product: any) {
  const duration: number = product.duration;
  if (!duration) {
    const max = product.durationMax;
    if (max) {
      const seconds = Math.floor(max / 1000);
      if (seconds < 86400) {
        return "< " + getHours(seconds);
      } else {
        return "< " + getDays(seconds);
      }
    }
  }
  if (!duration) {
    // fallback in case of bad data
    return "Unknown";
  }
  const seconds = Math.floor(duration / 1000);
  if (seconds < 86400) {
    return getHours(seconds);
  } else {
    return getDays(seconds);
  }
}

function getHours(hoursInSeconds: number): string {
  const result = hoursInSeconds / 3600;
  if (result === 1) {
    return result.toFixed(0) + " hour";
  } else {
    return result.toFixed(0) + " hours";
  }
}

function getDays(daysInSeconds: number): string {
  const result = daysInSeconds / 86400;
  if (result === 1) {
    return result.toFixed(0) + " day";
  } else {
    return result.toFixed(0) + " days";
  }
}

export function hourToDays(hour: any) {
  const newHour = hour / 24;
  if (newHour === 1) {
    return newHour.toFixed(0) + " day";
  } else {
    return newHour.toFixed(0) + " days";
  }
}

export function isRequired(field: any, isMainTraveller: boolean): boolean {
  if (field === "NOT_REQ") {
    return false;
  }
  if (field === "ONCE" && !isMainTraveller) {
    return false;
  }
  return true;
}

export function isValidEmail(email) {
  const emailRegexp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return emailRegexp.test(email);
}

export function getDisplayHeight(winWidth: number) {
  let displayHeight = winWidth > 900 ? "42px" : "22px";
  return displayHeight;
}

export function tConvert(time: any) {
  // Check correct time format and split into components
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) {
    // If time format correct
    time = time.slice(1); // Remove full string match value
    time[5] = +time[0] < 12 ? "AM" : "PM"; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }

  return time[0] + time[1] + time[2] + " " + time[5];
}

export function renderCancellationPolicy(policy: any) {
  let arr = [] as any;
  for (let i = 0; i < policy.length; i++) {
    arr.push(
      "Cancellation " +
        hourToDays(policy[i].hoursTillDeparture) +
        " before departure will incur a " +
        policy[i].feePerc +
        "% loss of fare."
    );
  }

  return arr.join("\n");
}

import React from "react";
import styled from "styled-components";
import {
  HomeBg,
  NextMedium,
  SearchInput,
  useWindowSize,
  MediumItalic
} from "../components";
import { theme } from "../constants/theme";
import { withRouter } from "react-router";
import { CSSTransition } from "react-transition-group";
import { getCities } from "../api";
import { FiSearch } from "react-icons/fi";
import { Image, Box, Flex } from "rebass";
import locationIcon from "../assets/travelExperience/LocationIcon.png";
import { connect } from "react-redux";
import { saveCityObj } from "../store/actions";
import { filterandSortCities } from "../utils";

const ResultArea = styled.div`
  width: 65%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    width: 90%;
  }
`;
interface IProps {
  history: any;
  handleClickCity: any;
  match: any;
}

interface IState {
  focused: boolean;
  input: string;
  cityList: any;
  matchedCities: any;
  textIdx: number;
  errorMessage: string;
}

const cityArray = [
  "SYDNEY",
  "MARGARET RIVER",
  "QUEENSTOWN",
  "CAIRNS",
  "BYRON BAY",
  "WELLINGTON",
  "HOBART",
  "ADELAIDE",
  "NEWCASTLE",
  "PERTH",
];

class Home extends React.Component<IProps, IState> {
  private timeout: any;
  state = {
    focused: false,
    input: "",
    cityList: [],
    matchedCities: [{ name: "", state: "", country: "", countryName: "" }],
    textIdx: 0,
    errorMessage: ""
  };

  private handleInputFocus = () => {
    this.setState({ focused: true });
  };

  private handleInputBlur = () => {
    if (this.state.input) {
      return;
    }
    this.setState({ focused: false });
  };

  private onInputChange = async (e) => {
    const value: string = e.target.value;
    this.setState({ input: value });
    const matchedCities = filterandSortCities(this.state.cityList, value);
    this.setState({ matchedCities });
    this.setState({errorMessage: ""})
  };

  onSubmit = async (e) => {
    if (!this.state.input) {
      return;
    }
    if (this.state.matchedCities.length === 1) {
      await this.onResultClick(this.state.matchedCities[0]);
      this.props.history.push("/products");
    } else if (this.state.matchedCities.length === 0) {
      this.setState({errorMessage: "We couldn’t find any experiences. Double check the spelling is correct or try another city!"})
    } else if (this.state.matchedCities.length > 1) {
      this.setState({errorMessage: "Please select a city."})
    }
  };

  onEnter = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) {
      e.preventDefault();
      this.onSubmit(e);
    }
  };

  private geoSuccess = (position) => {
    const city = {
      name: "",
      state: "",
      country: "",
      countryName: "",
      population: 0,
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    };
    this.onResultClick(city);
  };

  private geoError = (error) => {
    console.log("geo error" + error);
  };

  public componentDidMount = async () => {
    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;
      this.setState({ textIdx: currentIdx + 1 });
    }, 5000);
    const response = await getCities();
    if (response) {
      this.setState({ cityList: response.data.List });
    } else {
      console.log("no response");
    }
    const myInput: any = document.getElementById("city-search");
    myInput.addEventListener("keyup", this.onEnter);

    if (this.props.match.path.indexOf("loc") !== -1) {
      console.log("User asked for location search");
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.geoSuccess,
          this.geoError
        );
      } else {
        console.log("Geolocation is not supported for this Browser/OS.");
      }
    }
  };

  public componentWillUnmount = () => {
    const myInput: any = document.getElementById("city-search");
    myInput.removeEventListener("keyup", this.onEnter);
  };

  public componentDidUnmount() {
    clearInterval(this.timeout);
  }

  private onResultClick = async (item) => {
    this.setState({ input: item.name });
    this.props.handleClickCity(item);
    this.props.history.push("/products");
  };

  private renderResult = () => {
    if (this.state.matchedCities.length > 0) {
      return (
        <ResultArea>
          <Box
            m={1}
            mb={"-5px"}
            className={"modalScroll"}
          >
            {this.state.matchedCities.map((item, index) => (
              <Box
                key={index}
                onClick={async () => {
                  this.onResultClick(item);
                }}
              >
                <Flex>
                  <Box
                    m={2}
                    sx={{
                      width: "30px",
                    }}
                  >
                    <Image
                      sx={{
                        width: "22px",
                        height: "22px",
                      }}
                      src={locationIcon}
                    />
                  </Box>
                  <Box>
                    <NextMedium                      
                      className={"item-hover"}
                      fontSize={"16px"}
                      color={"#1A1F71"}
                      display={"inline-block"}
                      lineHeight={"40px"}
                      marginLeft={"15px"}
                    >
                      {item.name}, {item.state}, {item.countryName}
                    </NextMedium>
                  </Box>
                </Flex>
              </Box>
            ))}
          </Box>
        </ResultArea>
      );
    }
  };

  render() {
    const { focused } = this.state;
    let textThatChanges = cityArray[this.state.textIdx % cityArray.length];
    return (
      <Box>
        <HomeBg cityArray={textThatChanges}>
          <ResponsiveSubText />
          <Box>
            <CSSTransition in={focused} timeout={300} classNames="slide">
              <SearchInput
                className={focused ? "focused" : ""}
                onFocus={this.handleInputFocus}
                onBlur={this.handleInputBlur}
                value={this.state.input}
                onChange={this.onInputChange}
              />
            </CSSTransition>
            <div
              style={{ display: "inline", cursor: "pointer" }}
              onClick={this.onSubmit}
            >
              <FiSearch
                style={{
                  color: "gray",
                  marginLeft: "-45px",
                  position: "relative",
                  top: "6px",
                }}
                size={25}
              />
            </div>
          </Box>
          {this.state.input ? this.renderResult() : null}
          {this.state.errorMessage
            ? <MediumItalic fontSize={"15px"} sx={{marginTop: "20px"}}>{this.state.errorMessage}</MediumItalic>
            : null
          }
        </HomeBg>
      </Box>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleClickCity(obj) {
      dispatch(saveCityObj(obj));
    },
  };
};

function ResponsiveSubText() {
  let [winWidth] = useWindowSize();
  return (
    <Box sx={{width: winWidth > 640? "64%": "100%"}}>
      <NextMedium
        color={theme.colors.white}
        fontSize={winWidth > theme.responsive.large ? "25px" : "17px"}
        spacing={"0.5px"}
      >
        Thousands of tours, activities and epic things to do are just a click away!
      </NextMedium>
    </Box>
  );
}

export default connect(null, mapDispatchToProps)(withRouter(Home));

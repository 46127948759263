/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import RenderProduct from "../components/RenderProduct";
import { useWindowSize, BoldSkew, NextRegular, SearchInput, NextMedium } from "../components";
import { theme } from "../constants";
import ExperiencesIcon from "../assets/travelExperience/Experiences-Icon.png";
import Loader from "react-loader-spinner";
import { Flex, Box, Image, Button } from "rebass";
import { FiSearch } from "react-icons/fi";
import { CSSTransition } from "react-transition-group";
import { getDisplayHeight } from "../utils";
import locationIcon from "../assets/travelExperience/LocationIcon.png";

export function HeaderSubContent(props: any) {
  let [winWidth] = useWindowSize();
  return (
    <Box mx={winWidth > 1023 ? 6 : 0} sx={{ ml: 3, mt: 1 }} width={"100%"}>
      {!props.modalIsOpen ? (
        props.products ? (
          <NextRegular fontSize={winWidth > 900 ? "24px" : "16px"}>
            We’ve found {props.products.length} great experiences for you in this city!
          </NextRegular>
        ) : (
          <Loader
            type="ThreeDots"
            color="#FFFFFF"
            height={"5vmin"}
            width={"5vmin"}
            timeout={3000} //3 secs
          />
        )
      ) : (
        <NextRegular fontSize={winWidth > 900 ? "24px" : "16px"}>Searching For Experiences...</NextRegular>
      )}
    </Box>
  );
}

export function HeaderSearchBox(props: any) {
  let [winWidth] = useWindowSize();
  return (
    <Box mx={winWidth > 1023 ? 6 : 0}>
      <Box width={"100%"}>
        <CSSTransition in={props.focused} timeout={300} classNames="slide">
          <RenderResponsiveSearchBox
            focused={props.focused}
            handleInputFocus={props.handleInputFocus}
            handleInputBlur={props.handleInputBlur}
            input={props.input}
            onInputChange={props.onInputChange}
          />
        </CSSTransition>
        <div style={{ display: "inline", cursor: "pointer" }} onClick={props.onSubmit}>
          <FiSearch
            style={{
              color: "gray",
              marginLeft: "-35px",
              position: "relative",
              top: "6px",
            }}
            size={25}
          />
        </div>
      </Box>
    </Box>
  );
}

export function HeaderContent(props: any) {
  let [winWidth] = useWindowSize();
  let displayName = "NEARBY";
  let displayHeight = getDisplayHeight(winWidth);
  if (props.city.name.length > 0 && props.city.countryName.length > 0) {
    displayName = props.city.name.toUpperCase() + ", " + props.city.countryName.toUpperCase();
  }

  return (
    <Box mx={winWidth > 1023 ? 6 : 0}>
      <Flex sx={{ mt: winWidth > theme.responsive.large ? 4 : 1 }}>
        <a
          className={"button"}
          onClick={() => {
            props.history.push("/");
          }}
        >
          <Image
            sx={{
              height: winWidth > theme.responsive.large ? "34px" : "30px",
              mt: winWidth > theme.responsive.large ? "4px" : "6px",
              mr: "6px",
            }}
            src={ExperiencesIcon}
          />
        </a>
        <BoldSkew spacing={"-2px"} fontSize={displayHeight}>
          {displayName}
        </BoldSkew>
      </Flex>
    </Box>
  );
}

export function RenderResponsiveSearchBox(props: any) {
  let [winWidth] = useWindowSize();
  return (
    <SearchInput
      className={props.focused ? "focused" : ""}
      onFocus={props.handleInputFocus}
      onBlur={props.handleInputBlur}
      value={props.input}
      onChange={props.onInputChange}
      width={winWidth < 600 ? "90%" : winWidth > 1024 ? "36%" : "75%"}
    />
  );
}

export function RenderResponsiveSearchResult(props: any) {
  let [winWidth] = useWindowSize();
  return (
    <Box
      sx={{
        width: winWidth < 600 ? "85%" : winWidth > 1024 ? winWidth - 320 : "92%",
        position: "absolute",
        top: "100px",
      }}
    >
      <Box
        sx={{
          width: winWidth < 600 ? "90%" : winWidth > 1024 ? "36%" : "75%",
          p: "20px",
          backgroundColor: theme.colors.white,
          borderRadius: "25px",
          zIndex: 999,
        }}
      >
        {props.content}
      </Box>
    </Box>
  );
}

export function LoadMore(props: any) {
  let [winWidth] = useWindowSize();
  let padding;
  if (winWidth > 1600) {
    padding = 40;
  } else {
    padding = 10;
  }

  return (
    <Box>
      <RenderProduct
        productsToRender={props.products}
        navigate={props.navigate}
        padding={padding}
        winWidth={winWidth}
      />
      <Flex p={"43px"} justifyContent="center">
        <Box>
          {!props.hideload && (
            <Button
              className={"item-hover"}
              sx={Object.assign({}, theme.styles.prodLoadMore, {
                color: theme.colors.midnightBlue,
                backgroundColor: theme.colors.white,
                width: winWidth >= 400 ? "363px" : winWidth - 150,
              })}
              onClick={props.loadmore}
            >
              LOAD MORE
            </Button>
          )}
        </Box>
      </Flex>
    </Box>
  );
}

export function RenderResult(props: any) {
  let [winWidth] = useWindowSize();
  return (
    <Box mx={winWidth > 1023 && !props.fromItem ? 6 : 0}>
      <Box width={"100%"}>
        <RenderResponsiveSearchResult
          content={
            <Box m={1} className={"modalScroll"} mb={"-5px"}>
              {props.matchedCities.map((item, index) => (
                <Box
                  key={index}
                  onClick={async () => {
                    props.onResultClick(item);
                  }}
                >
                  <Flex>
                    <Box
                      m={2}
                      sx={{
                        width: "30px",
                      }}
                    >
                      <Image
                        sx={{
                          width: 22,
                          height: 22,
                        }}
                        src={locationIcon}
                      />
                    </Box>
                    <Box>
                      <NextMedium
                        className={"item-hover"}
                        fontSize={"16px"}
                        color={"#1A1F71"}
                        display={"inline-block"}
                        lineHeight={"40px"}
                        marginLeft={"15px"}
                      >
                        {item.name}, {item.state}, {item.countryName}
                      </NextMedium>
                    </Box>
                  </Flex>
                </Box>
              ))}
            </Box>
          }
        />
      </Box>
    </Box>
  );
}

export const theme = {
  colors: {
    white: "#FFFFFF",
    emerald: "#39CA74",
    wildStrawberry: "#FA2E81",
    limeGreen: "#CCFF02",
    midnightBlue: "#1A1F71",
    solitude: "#F3F5FB",
    skyblue: "#8ED8F8",
    green: "#2ECC71",
    grey: "#CFCFCF",
    periglacialblue: "#AAACAF",
    grey59: "#969696",
    vividblue: "#1499D3",
    malachite: "#00C45B",
    transparentWhite: "rgba(255, 255, 255, 0.05)",
    transparentGrey: "rgba(207, 207, 207, 0.5)",
  },
  padding: {
    p5: "5px",
    p10: "10px",
    p20: "20px",
  },
  margin: {
    m5: "5px",
    m10: "10px",
    m15: "15px",
  },
  responsive: {
    mobile: 499,
    medium: 899,
    large: 1023,
  },
  link: {
    apple: "https://apps.apple.com/au/app/pelikin/id1444828880",
    playStore: "https://play.google.com/store/apps/details?id=co.pelikin.pelikin",
    help: "mailto:help@pelikinmoney.com",
  },
  styles: {
    limeLightning: {
      height: "30px",
      width: "16px",
    },
    prodHeader: {
      pt: 3,
      backgroundSize: "cover",
      color: "white",
      bg: "gray",
      height: "300px",
    },
    prodContainer: {
      bg: "white",
      borderRadius: "30px",
      position: "relative",
      height: "375px",
    },
    prodDurationContainer: {
      position: "absolute",
      top: 26,
      left: 28,
      borderRadius: 5,
      width: 120,
      height: 34,
      zIndex: 1,
    },
    prodShare: {
      position: "absolute",
      top: 20,
      right: 10,
      bg: "transparent",
      cursor: "pointer",
      zIndex: 1,
    },
    prodImgContainer: {
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      position: "relative",
      height: 210,
      overflow: "hidden",
      width: "100%",
    },
    prodImgOverlay: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.2)",
    },
    prodImg: {
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      height: 210,
      width: "100%",
      overflow: "hidden",
    },
    prodDescContainer: {
      position: "relative",
      width: "100%",
      p: "20px",
    },
    prodBookNowContainer: {
      p: "10px",
      height: "50px",
      position: "absolute",
      bottom: 0,
    },
    prodFilterContainer: {
      width: "280px",
      height: "41px",
      px: 4,
      py: 2,
    },
    prodLoadMore: {
      fontFamily: "AvenirNextCondensedBoldSkew",
      fontSize: 23,
      cursor: "pointer",
      width: "100%",
      overflow: "hidden",
      borderRadius: 30,
      letterSpacing: "-1.25px",
      height: "55px",
    },
    prodBookNowBtn: {
      fontSize: 23,
      outline: "none",
      bg: "transparent",
      overflow: "hidden",
      width: "100%",
      height: "100%",
    },
    prodBookNow: {
      fontFamily: "AvenirNextCondensedBoldSkew",
      position: "absolute",
      justifyContent: "center",
      borderBottomLeftRadius: "30px",
      borderBottomRightRadius: "30px",
      width: "100%",
      bottom: 0,
      fontSize: 24,
      cursor: "pointer",
      letterSpacing: "-1.2px",
      height: "55px",
    },
    footer: {
      backgroundColor: "#FFFFFF",
      borderTop: "1px solid #E7E7E7",
      textAlign: "center",
      position: "fixed",
      left: "0",
      bottom: "0",
      height: "100px",
      width: "100%",
    },
    phantom: {
      display: "block",
      height: "100px",
      width: "100%",
    },
    footerGoBack: {
      fontFamily: "AvenirNextMedium",
      borderRadius: 30,
      cursor: "pointer",
    },
    footerCheckAvailability: {
      fontFamily: "AvenirNextCondensedBoldSkew",
      borderRadius: 30,
      letterSpacing: "-1px",
      cursor: "pointer",
    },
    sendEmailButton: {
      fontFamily: "AvenirNextCondensedBoldSkew",
      height: "40px",
      width: "90px",
      fontSize: "20px",
      letterSpacing: "-1px",
      cursor: "pointer",
      color: "#FFF",
      position: "relative",
      top:"3px"
    },
    centerText: {
      textAlign: "center",
    },
    slickMargin: {
      bg: "#1499D3",
      height: "220px",
    },
    locationIcon: {
      fontFamily: "Icomoon",
      mt: "6px",
      mr: "10px",
    },
    shareIcon: {
      fontFamily: "Icomoon",
      fontSize: "15px",
      mt: "5px",
      mr: "10px",
    },
    plusMinus: {
      backgroundColor: "transparent",
      width: "39px",
      height: "39px",
      borderRadius: "9px",
      border: "1px solid #1a1f71",
      outline: "none",
      position: "relative",
    },
    plusMinusPosition: {
      position: "absolute",
      top: "10px",
      right: "10px",
    },
    inputForm: {
      border: "none",
      paddingLeft: "10px",
      bg: "#F3F5FB",
      color: "#1A1F71",
      fontFamily: "AvenirNextDemiBold",
      outline: "none",
      "&::placeholder": {
        fontFamily: "AvenirNextRegular",
        color: "#1A1F71",
      },
    },
    shareEmailInput: {
      border: "none",
      bg: "#FFF",
      color: "#FA2E81",
      width: "400px",
      height: "40px",
      fontFamily: "AvenirNextRegular",
      outline: "none",
      display: "inline",
      paddingLeft: "16px",
      "&::placeholder": {
        fontFamily: "AvenirNextRegular",
        color: "#1A1F71",
      },
    },
    shareEmailInputMobile: {
      border: "none",
      bg: "#FFF",
      color: "#FA2E81",
      width: "70%",
      height: "32px",
      fontFamily: "AvenirNextRegular",
      outline: "none",
      display: "inline",
      paddingLeft: "8px",
      "&::placeholder": {
        fontFamily: "AvenirNextRegular",
        color: "#1A1F71",
        fontSize: "14px",
      },
    },
    promoForm: {
      border: "none",
      borderRadius: "30px",
      padding: "15px",
      bg: "#F3F5FB",
      color: "#FA2E81",
      fontFamily: "AvenirNextCondensedDemiBoldSkew",
      outline: "none",
      textTransform: "uppercase",
      "&::placeholder": {
        fontFamily: "AvenirNextCondensedDemiBoldSkew",
        color: "rgba(26, 31, 113, 0.6)",
      },
    },
    promoButton: {
      bg: "#F3F5FB",
      height: "100%",
      width: "100%",
      borderRadius: "50%",
      outline: "none",
      color: "#FA2E81",
      cursor: "pointer",
      paddingTop: "10px",
    },
    creditForm: {
      border: "none",
      padding: "5px 5px 0px 0px",
      bg: "#F3F5FB",
      color: "#1A1F71",
      fontSize: "16px",
      fontFamily: "AvenirNextCondensedDemiBoldSkew",
      outline: "none",
      textTransform: "uppercase",
      "&::placeholder": {
        fontFamily: "AvenirNextCondensedDemiBoldSkew",
        color: "rgba(26, 31, 113, 0.6)",
      },
    },
  },
};

import React from "react";
import Routers from "./navigation/Routers";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import store from "./store";
// import { Header } from "./components";

export default class App extends React.Component {
  render() {
    const customHistory = createBrowserHistory();
    return (
      <Provider store={store}>
        <Router history={customHistory}>
          {/* <Header /> */}
          <Routers />
        </Router>
      </Provider>
    );
  }
}

import React from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Box } from "rebass";
import { NextRegular, NextMedium } from "../components";
import { theme } from "../constants";

const headerBig = "35px";
const headerSmall = "30px";
const descBig = "19px";
const descSmall = "16px";
export function readMoreContainer(winWidth: number, title: string, content: any, item?: any) {
  return (
    <Box>
      {item ? (
        <Box py={"30px"}>
          <NextMedium fontSize={winWidth > 499 ? headerBig : headerSmall}>{title}</NextMedium>
          <Box mt={"11px"}>
            <NextRegular
              fontSize={winWidth > 499 ? descBig : descSmall}
              spacing={"2px"}
              color={theme.colors.midnightBlue}
              className={"lineBreak"}
            >
              <ReactReadMoreReadLess
                charLimit={150}
                readMoreText={
                  <NextMedium className={"item-hover"} color={theme.colors.vividblue} fontSize={winWidth > 499 ? descBig : descSmall } marginTop= "6px">
                    Read more
                  </NextMedium>
                }
                readLessText={
                  <NextMedium className={"item-hover"} color={theme.colors.vividblue} fontSize={winWidth > 499 ? descBig : descSmall} marginTop= "6px">
                    Read less
                  </NextMedium>
                }
              >
                {content}
              </ReactReadMoreReadLess>
            </NextRegular>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}

import styled from "styled-components";
import { theme } from "../constants";
import { Button } from "rebass/styled-components";
import React from "react";

interface IProps {
  children?: any;
  type?: any;
  variant?: any;
  width?: any;
  color?: string;
  backgroundColor?: string;
  navigate: any;
}

const StyledButton = styled(Button)`
  cursor: pointer;
  border-radius: 40px;
  font-size: 25px;
  letter-spacing: -1.25px;
  font-family: AvenirNextCondensedBoldSkew;
`;

export const BaseButton = ({
  children,
  type,
  variant,
  width,
  color,
  backgroundColor,
  navigate
}: IProps) => (
  <StyledButton
    onClick={navigate}
    variant={variant}
    type={type}
    width={width ? width : "169px"}
    color={color ? color : theme.colors.white}
    backgroundColor={backgroundColor ? backgroundColor : theme.colors.wildStrawberry}
  >
    {children}
  </StyledButton>
);

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../assets/experiences.png";
import { Image, Box } from "rebass";

interface IProps {}

interface IState {}

class NotFound extends React.Component<IProps, IState> {
  render() {
    return (
      <div style={{ textAlign: "center" }}>
        <Box>
          <Image src={logo} height={300} />
        </Box>
        <h2>OOPS! THE PAGE YOU ARE LOOKING FOR IS NOT FOUND</h2>
        <div>&nbsp;</div>
        <a
          style={{
            color: "#fff",
            borderColor: "#91b2c3",
            backgroundColor: "#91b2c3",
            borderRadius: "100px",
            padding: "10px 20px",
            marginTop: "100px",
            fontWeight: "bold",
          }}
        >
          Back to homepage
        </a>
      </div>
    );
  }
}

export default NotFound;

import styled from "styled-components";
import React from "react";
import { theme } from "../constants/theme";
import homeBG1 from "../assets/homeBG/experience1.jpg";
import homeBG2 from "../assets/homeBG/experience2.jpg";
import homeBG3 from "../assets/homeBG/experience3.jpg";
import homeBG4 from "../assets/homeBG/experience4.jpg";
import homeBG5 from "../assets/homeBG/experience5.jpg";
import homeBG6 from "../assets/homeBG/experience6.jpg";
import homeBG7 from "../assets/homeBG/experience7.jpg";
import homeBG8 from "../assets/homeBG/experience8.jpg";
import homeBG9 from "../assets/homeBG/experience9.jpg";
import homeBG10 from "../assets/homeBG/experience10.jpg";
import homeBG11 from "../assets/homeBG/experience11.jpg";
import homeBG12 from "../assets/homeBG/experience12.jpg";
import mHomeBG1 from "../assets/homeBG/mBG1.jpg";
import mHomeBG2 from "../assets/homeBG/mBG2.jpg";
import mHomeBG3 from "../assets/homeBG/mBG3.jpg";
import mHomeBG4 from "../assets/homeBG/mBG4.jpg";
import mHomeBG5 from "../assets/homeBG/mBG5.jpg";
import mHomeBG6 from "../assets/homeBG/mBG6.jpg";
import mHomeBG7 from "../assets/homeBG/mBG7.jpg";
import mHomeBG8 from "../assets/homeBG/mBG8.jpg";
import mHomeBG9 from "../assets/homeBG/mBG9.jpg";
import mHomeBG10 from "../assets/homeBG/mBG10.jpg";
import mHomeBG11 from "../assets/homeBG/mBG11.jpg";
import logo from "../assets/experiences.png";
import { BoldSkew } from "./Text";
import { useWindowSize } from "../components";
import { Image, Box, Flex } from "rebass";

const bgArray=[homeBG1, homeBG2, homeBG3, homeBG4, homeBG5, homeBG6, homeBG7, homeBG8, homeBG9, homeBG10, homeBG11, homeBG12];
const selectedBG= bgArray[Math.floor(Math.random() * bgArray.length)];

const mobileBgArray=[mHomeBG1, mHomeBG2, mHomeBG3, mHomeBG4, mHomeBG5, mHomeBG6, mHomeBG7, mHomeBG8, mHomeBG9, mHomeBG10, mHomeBG11];
const mobileSelectedBG= mobileBgArray[Math.floor(Math.random() * mobileBgArray.length)];

const HomeBgContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  min-width: 105%;
  min-height: 100%;
  background: #000;
  background-image: url(${selectedBG});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 600px) {
    background-image: url(${mobileSelectedBG});
    background-position: center;
  }
`;

export const HomeBg = (props) => (
  <HomeBgContainer>
    <Container cityArray={props.cityArray} children={<React.Fragment>{props.children}</React.Fragment>} />
  </HomeBgContainer>
);

function Container(props: any) {
  let [winWidth] = useWindowSize();

  return (
    <Flex justifyContent={"flex-start"}>
      <Box mx={"10%"} mt={winWidth > theme.responsive.large ? "6%" : "5%"}>
        <Box>
          <Image sx={{ width: "20vmin", minWidth: "100px", marginBottom: "-30px" }} src={logo} />
          <Box py={winWidth > theme.responsive.large ? "30px" : "10px"}>
            <BoldSkew
              lineHeight={"40px"}
              fontSize={winWidth > theme.responsive.large ? "80px" : "40px"}
              spacing={winWidth > theme.responsive.large ? "-2px" : "-1px"}
            >
              DISCOVER <label>{props.cityArray}</label>
            </BoldSkew>
          </Box>
          {props.children}
        </Box>
      </Box>
    </Flex>
  );
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect } from "react-redux";
import { Flex, Box, Button, Text, Image } from "rebass";
import { theme } from "../../constants";
import { withRouter } from "react-router";
import {
  Footer,
  HeaderItem,
  BoldSkew,
  NextMedium,
  NextRegular,
  DemiBold,
  MediumItalic,
  useWindowSize,
  CalendarDatePicker,
  ModalLeviesView,
  ModalLoadingView,
  Count,
  GradientModal,
  NotAvailableModal,
  BookingAndPaymentErrorModal,
  PriceChangeModal,
} from "../../components";
import { BsChevronLeft } from "react-icons/bs";
import { IoMdArrowDropright } from "react-icons/io";
import { Hide } from "@rebass/hide";
import { getProductDetails, checkAvailabilityAndPrice } from "../../api";
import { calculateDuration } from "../../utils";
import LocationIcon from "../../assets/travelExperience/LocationIcon.png";
import StopWatch from "../../assets/travelExperience/HoursIcon.png";
import ExperiencesIcon from "../../assets/travelExperience/Loudspeaker.png";

import picImage from "../../assets/travelExperience/picImage.png";

import { saveProductObj } from "../../store/actions";

interface IProps {
  history: any;
  product: any;
  cityName: any;
  location: any;
  handleShareProduct: any;
}
interface IState {
  userSelectedProduct: any;
  productItem: any;
  modalIsOpen: boolean;
  levimodalIsOpen: boolean;
  travellerCount: any;
  fareLabel: any;
  flavours: any;
  flavourItem: any;
  isDisabled: boolean;
  selectedItem: any;
  showGradientModal: boolean;
  selectedDate: any;
  pickedProduct: any;
  notAvailableModalShow: boolean;
  priceChangeModalShow: boolean;
  errorModalIsOpen: boolean;
  errorType: string;
  priceChangeData: any;
  rateChange: string;
}

class ProductAvailability extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      userSelectedProduct: "",
      productItem: null,
      modalIsOpen: false,
      levimodalIsOpen: false,
      fareLabel: {
        adult: { exist: false, minAge: 0, maxAge: 0 },
        child: { exist: false, minAge: 0, maxAge: 0 },
        infant: { exist: false, minAge: 0, maxAge: 0 },
      },
      travellerCount: {
        adult: 1,
        child: 0,
        infant: 0,
      },
      flavours: null,
      flavourItem: "",
      isDisabled: true,
      selectedItem: null,
      showGradientModal: false,
      selectedDate: null,
      pickedProduct: {
        product: null,
        travellers: null,
        date: "",
      },
      notAvailableModalShow: false,
      priceChangeModalShow: false,
      errorModalIsOpen: false,
      errorType: "",
      priceChangeData: "",
      rateChange: "",
    };
  }

  public componentWillMount = async () => {
    this.openModal();
    this.openLeviModal();
    let productID: any;
    if (this.props.location && this.props.location.state && this.props.location.state.productID) {
      productID = this.props.location.state.productID;
      this.props.handleShareProduct(productID);
    } else {
      productID = this.props.product
    }
    try {
      const response = await getProductDetails(productID);
      if (response.status === 200) {
        this.setState({ productItem: response.data.product });
        this.validateFareLabel(this.state.productItem);
        if (this.state.productItem.flavours) {
          this.setState({ flavours: this.state.productItem.flavours });
        }
        // this.closeModal();
      }
    } catch (e) {
      console.log(e);
    }
    this.closeModal();
  };

  public componentDidUpdate = (prevProps, prevState) => {
    // only update chart if the data has changed
    if (prevState.travellerCount !== this.state.travellerCount) {
      this.filterFlavoursBasedonPax(this.state.travellerCount, this.state.flavours);
    }
  };

  private validateFareLabel = (product: any) => {
    if (product.flavours) {
      let isAdult = product.flavours.some((adult) => {
        if (adult.fareLabelAdult) {
          return true;
        }
        return false;
      });
      let isChild = product.flavours.some((child) => {
        if (child.fareLabelChild) {
          return true;
        }
        return false;
      });
      let isInfant = product.flavours.some((infant) => {
        if (infant.fareLabelInfant) {
          return true;
        }
        return false;
      });

      if (isAdult) {
        const result = product.flavours.find((adult) => adult.minAgeAdult);
        this.setState((prevState: any) => ({
          fareLabel: {
            ...prevState.fareLabel,
            adult: {
              ...prevState.fareLabel.adult,
              exist: true,
              minAge: result.minAgeAdult,
            },
          },
        }));
      }

      if (isChild) {
        const minAge = product.flavours.find((child) => child.minAgeChild);
        const maxAge = product.flavours.find((child) => child.maxAgeChild);
        this.setState((prevState: any) => ({
          fareLabel: {
            ...prevState.fareLabel,
            child: {
              ...prevState.fareLabel.child,
              exist: true,
              minAge: minAge.minAgeChild,
              maxAge: maxAge.maxAgeChild,
            },
          },
        }));
      }

      if (isInfant) {
        const maxAge = product.flavours.find((infant) => infant.maxAgeInfant);
        this.setState((prevState: any) => ({
          fareLabel: {
            ...prevState.fareLabel,
            infant: {
              ...prevState.fareLabel.infant,
              exist: true,
              maxAge: maxAge.maxAgeInfant,
            },
          },
        }));
      }
    } else {
      if (product.fareLabelAdult) {
        this.setState((prevState: any) => ({
          fareLabel: {
            ...prevState.fareLabel,
            adult: {
              ...prevState.fareLabel.adult,
              exist: true,
              minAge: product.minAgeAdult,
            },
          },
        }));
      }
      if (product.fareLabelChild) {
        this.setState((prevState: any) => ({
          fareLabel: {
            ...prevState.fareLabel.child,
            exist: true,
            minAge: product.minAgeChild,
            maxAge: product.maxAgeChild,
          },
        }));
      }
      if (product.fareLabelInfant) {
        this.setState((prevState: any) => ({
          fareLabel: {
            ...prevState.fareLabel.infant,
            exist: true,
            minAge: product.minAgeInfant,
            maxAge: product.maxAgeInfant,
          },
        }));
      }
    }
  };

  private filterFlavoursBasedonPax = (travellerCount: any, flavours: any) => {
    console.log(travellerCount);
    if (travellerCount.child > 0) {
      let matched = flavours.filter((item: any) => item.fareLabelChild);
      this.setState({ flavours: matched });
    }
    if (travellerCount.infant > 0) {
      let matched = flavours.filter((item: any) => item.fareLabelInfant);
      this.setState({ flavours: matched });
    }
    if (travellerCount.infant === 0 && travellerCount.child === 0) {
      this.setState({ flavours: this.state.productItem.flavours });
    }
  };

  private openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  private closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  private openLeviModal = () => {
    this.setState({ levimodalIsOpen: true });
  };

  private closeLeviModal = () => {
    this.setState({ levimodalIsOpen: false });
  };

  private closeAvailabilityModal = () => {
    this.setState({ notAvailableModalShow: false, showGradientModal: false });
  };

  private closeErrorModal = () => {
    this.setState({ errorModalIsOpen: false });
  };

  private backToProduct = () => {
    const { history } = this.props;
    history.push("/products");
  };

  private modalBlueContent = () => {
    let [winWidth] = useWindowSize();
    return (
      <Flex justifyContent="center" flexWrap="wrap" flexDirection="column">
        <Flex justifyContent="center">
          <Image
            sx={{
              height: winWidth > 900 ? "74px" : "60px",
            }}
            src={ExperiencesIcon}
          />
        </Flex>
        <Box>
          <BoldSkew
            fontSize={winWidth > 499 ? "40px" : "30px"}
            spacing={"-1px"}
            color="#FFF"
            sx={{ textAlign: "center", py: "10px" }}
          >
            ON-THE-DAY-FEE
          </BoldSkew>
          <MediumItalic fontSize={winWidth > 499 ? "18px" : "15px"} sx={{ textAlign: "center" }}>
            This experience has a fee payable on the day of your experience.
          </MediumItalic>
          <Flex justifyContent="center">
            <Box sx={{ width: "50%" }}>
              <ul>
                {this.state.productItem.levies.map((item: any, index: any) => (
                  <li>
                    <MediumItalic fontSize={winWidth > 499 ? "18px" : "15px"} sx={{ textAlign: "center" }}>
                      {item.description} fee ${item.amount}p/p
                    </MediumItalic>
                  </li>
                ))}
              </ul>
            </Box>
          </Flex>
        </Box>
      </Flex>
    );
  };

  private handleClick = (item: any, index: number) => {
    this.setState({ isDisabled: false });
    this.setState({ selectedItem: item, flavourItem: index });
  };

  private handleBooking = async () => {
    await this.setState({ showGradientModal: true });

    const { selectedItem, travellerCount, selectedDate } = this.state;
    let newSelectedDate = "";
    if (selectedItem === null) {
      alert("Please select something!!");
      this.setState({ isDisabled: true });
      return;
    }

    const total = this.state.travellerCount.adult + this.state.travellerCount.child + this.state.travellerCount.infant;

    newSelectedDate = `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`;

    if (total >= selectedItem.requiredMultiple) {
      try {
        const response = await checkAvailabilityAndPrice(selectedItem, newSelectedDate, travellerCount);
        console.log("CHECK", response.data);
        const check = response.data.availabilityCheck.availabilities[0].available;
        if (check === false) {
          // Insufficient Availability!
          await this.setState({ showGradientModal: false });
          this.setState({ notAvailableModalShow: true });
          return;
        } else {
          if (response.data.ratesChanged) {
            await this.setState({ showGradientModal: false });
            if (response.data.total > selectedItem.minRateAdult) {
              let priceChangeData = {
                type: "change",
                total: response.data.total,
              };
              this.setState({ priceChangeData, rateChange: "max" });
              this.setState({ priceChangeModalShow: true });
              return;
            }
            if (response.data.total < selectedItem.minRateAdult) {
              let priceChangeData = {
                type: "",
                total: response.data.total,
              };
              this.setState({ priceChangeData, rateChange: "min" });
              this.setState({ priceChangeModalShow: true });
              return;
            }
          }

          this.navigateBooking(selectedItem, travellerCount, newSelectedDate);
        }
      } catch (e) {
        // Error Modal Here!
        await this.setState({ showGradientModal: false });
        this.setState({ errorType: "", errorModalIsOpen: true });
        console.log("BOOKING ERROR:");
        console.log(e);
        if (e.response) {
          console.log(e.response.data);
        }
        return;
      }
    } else {
      // Error Modal stating Reqiure more than 1 person
      await this.setState({ showGradientModal: false });
      this.setState({ errorType: "multiple", errorModalIsOpen: true });
      console.log("This product requires at least 2 people");
      return;
    }
  };

  private closePriceChangeModal = async () => {
    this.setState({ priceChangeModalShow: false });
    const { selectedItem, travellerCount, selectedDate } = this.state;
    let newSelectedDate = "";
    newSelectedDate = `${selectedDate.year}-${selectedDate.month}-${selectedDate.day}`;
    const { history } = this.props;
    await this.setState({
      pickedProduct: {
        ...this.state.pickedProduct,
        product: selectedItem,
        travellers: travellerCount,
        date: newSelectedDate,
      },
    });
    history.push({
      pathname: "/productBooking",
      state: {
        pickedProduct: this.state.pickedProduct,
        rateChange: this.state.rateChange,
      },
    });
  };

  private navigateBooking = (selectedItem: any, travellerCount: any, newSelectedDate: any) => {
    const { history } = this.props;
    this.setState({
      pickedProduct: {
        ...this.state.pickedProduct,
        product: selectedItem,
        travellers: travellerCount,
        date: newSelectedDate,
      },
    });
    history.push({
      pathname: "/productBooking",
      state: {
        pickedProduct: this.state.pickedProduct,
      },
    });
  };

  private modalWhiteContent = () => {
    let [winWidth] = useWindowSize();
    return (
      <Flex justifyContent="center">
        <Box width={1 / 2} onClick={this.closeLeviModal} sx={{ cursor: "pointer", outline: "none" }}>
          <Flex className={"lineBreak"} justifyContent="center">
            <BoldSkew
              sx={{ textAlign: "center" }}
              color={theme.colors.wildStrawberry}
              fontSize={winWidth > 499 ? "22px" : "18px"}
              spacing="-1px"
              lineHeight="30px"
            >
              ACCEPT AND {"\n"} CONTINUE BOOKING
            </BoldSkew>
            <Box>
              <IoMdArrowDropright style={{ color: theme.colors.wildStrawberry, fontSize: "50px" }} />
            </Box>
          </Flex>
        </Box>
      </Flex>
    );
  };

  render() {
    const {
      productItem,
      modalIsOpen,
      levimodalIsOpen,
      fareLabel,
      flavourItem,
      isDisabled,
      showGradientModal,
      selectedDate,
      notAvailableModalShow,
      priceChangeModalShow,
      priceChangeData,
      errorModalIsOpen,
      errorType,
    } = this.state;
    let flavours = this.state.flavours;
    if (!flavours) {
      flavours = [productItem];
    }
    return (
      <React.Fragment>
        {productItem ? (
          <Box>
            <BodyContent
              product={productItem}
              flavours={flavours}
              fareLabel={fareLabel}
              selectedDate={selectedDate}
              setSelectedDate={(date: any) => {
                console.log("CLICK", date);
                this.setState({ selectedDate: date });
              }}
              handleClick={(item: any, index: number) => this.handleClick(item, index)}
              flavourItem={flavourItem}
              setAdultCount={(number: number) => {
                this.setState((prevState: any) => ({
                  travellerCount: {
                    ...prevState.travellerCount,
                    adult: number,
                  },
                }));
              }}
              setChildCount={(number: number) => {
                this.setState((prevState: any) => ({
                  travellerCount: {
                    ...prevState.travellerCount,
                    child: number,
                  },
                }));
              }}
              setInfantCount={(number: number) => {
                this.setState((prevState: any) => ({
                  travellerCount: {
                    ...prevState.travellerCount,
                    infant: number,
                  },
                }));
              }}
              openLeviModal={this.openLeviModal}
            />
            <Footer>
              <Hide xsmall small>
                <WebFooter product={productItem} handleBooking={this.handleBooking} disabled={isDisabled} />
              </Hide>
              <Hide medium large xlarge>
                <MobileFooter product={productItem} handleBooking={this.handleBooking} disabled={isDisabled} />
              </Hide>
            </Footer>
            {productItem.levies ? (
              <ModalLeviesView
                modalIsOpen={levimodalIsOpen}
                closeModal={() => this.closeLeviModal()}
                blueContent={this.modalBlueContent}
                whiteContent={this.modalWhiteContent}
              />
            ) : null}
            {showGradientModal ? (
              <GradientModal modalIsOpen={showGradientModal} text={"LOCKING IN YOUR EXPERIENCE"} image={picImage} />
            ) : null}
            {notAvailableModalShow ? (
              <NotAvailableModal
                modalIsOpen={notAvailableModalShow}
                closeModal={() => this.closeAvailabilityModal()}
                backToProduct={() => this.backToProduct()}
              />
            ) : null}
            <PriceChangeModal
              modalIsOpen={priceChangeModalShow}
              closeModal={() => this.closePriceChangeModal()}
              type={priceChangeData.type}
              total={priceChangeData.total}
            />
            <BookingAndPaymentErrorModal
              modalIsOpen={errorModalIsOpen}
              closeModal={() => this.closeErrorModal()}
              type={errorType}
            />
          </Box>
        ) : (
          <Box>
            <Flex flexWrap="wrap">
              <HeaderItem>
                <Box />
              </HeaderItem>
            </Flex>
            <ModalLoadingView modalIsOpen={modalIsOpen} closeModal={() => this.closeModal()} />
          </Box>
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    product: state.userSelectedProduct,
    cityName: state.userSelectedCity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleShareProduct(productID) {
      dispatch(saveProductObj(productID));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductAvailability));

function BodyContent(props: any) {
  const product: any = props.product;
  let [winWidth] = useWindowSize();
  return product ? (
    <Box
      width={1}
      px={winWidth > 900 ? "144px" : "20px"}
      py={winWidth > 900 ? "20px" : "15px"}
      sx={{ bg: theme.colors.solitude }}
    >
      <Box width={1} sx={{ bg: "#FFF", borderRadius: "20px", pb: 20 }} className={"shadowBox"}>
        <Box width={1} sx={{ bg: "#FFF", borderRadius: "20px" }} px={"10px"} py={"10px"} className={"shadowBox"}>
          <Flex flexWrap="wrap" flexDirection={winWidth > 1024 ? "row" : "column"} justifyContent="center">
            <Box width={winWidth > 1024 ? 0.25 : 1} height={"180px"}>
              <Box
                sx={{
                  height: "180px",
                  backgroundImage: `url(${product.images[0].urlPreviewSecure})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: 8,
                  width: "100%",
                }}
              />
            </Box>
            <Box width={winWidth > 1024 ? 0.75 : 1} sx={{ px: 10, pl: winWidth > 1024 ? "30px" : 0 }}>
              <DemiBold
                spacing={"-1px"}
                fontSize={winWidth > 499 ? "35px" : "28px"}
                sx={{ marginTop: winWidth > 1024 ? "0px" : "10px" }}
              >
                {product.name}
              </DemiBold>
              {product.levies && (
                <NextRegular color={theme.colors.wildStrawberry} fontSize={"16px"} spacing={"0px"}>
                  This experience has an{" "}
                  <a
                    className="item-hover-underline"
                    style={{ textDecoration: "underline" }}
                    onClick={props.openLeviModal}
                  >
                    on-the-day fee
                  </a>
                </NextRegular>
              )}
              <Box mt={"15px"}>
                <Flex>
                  <Box mt={winWidth > 499 ? "3px" : "0px"} mr={"10px"} mb={"10px"}>
                    <Image
                      sx={{
                        height: "19px",
                        width: "19px",
                      }}
                      src={LocationIcon}
                    />
                  </Box>
                  <Box>
                    <NextMedium sx={{ textDecoration: "underline" }} fontSize={winWidth > 499 ? "18px" : "14px"}>
                      {product.locationStart}
                    </NextMedium>
                  </Box>
                </Flex>
                <Flex>
                  <Box mt={winWidth > 499 ? "3px" : "0px"} mr={"10px"}>
                    <Image
                      sx={{
                        height: "19px",
                        width: "19px",
                      }}
                      src={StopWatch}
                    />
                  </Box>
                  <Box>
                    <NextMedium fontSize={winWidth > 499 ? "18px" : "14px"} spacing={"-1px"}>
                      {calculateDuration(product)}
                    </NextMedium>
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Flex>
        </Box>
        <Box sx={{ padding: winWidth > 499 ? "20px 40px" : "20px" }}>
          <BoldSkew color={theme.colors.midnightBlue} fontSize={winWidth > 499 ? "40px" : "30px"} spacing={"-2.4px"}>
            LOCK IN YOUR EXPERIENCE
          </BoldSkew>
          <NextMedium fontSize={winWidth > 499 ? "18px" : "14px"} color={theme.colors.midnightBlue}>
            When are you doing it and who else is joining you?
          </NextMedium>
        </Box>
        <Box mx={winWidth > 1024 ? "60px" : "5%"}>
          <Flex flexWrap="wrap" flexDirection={winWidth > 1024 ? "row" : "column"} justifyContent="center">
            <Box width={winWidth > 1024 ? 1 / 2 : 1}>
              <DemiBold color={theme.colors.wildStrawberry} fontSize={"40px"} display={"inline"}>
                1.{" "}
                <DemiBold color={theme.colors.wildStrawberry} fontSize={"22px"} display={"inline"}>
                  WHEN?
                </DemiBold>
              </DemiBold>
              <Flex justifyContent={winWidth <= 1024 ? "center" : "flex-start"}>
                <CalendarDatePicker
                  setSelectedDate={(date: any) => props.setSelectedDate(date)}
                  operatingDaysStr={product.operatingDaysStr}
                />
              </Flex>
            </Box>
            <Box width={winWidth > 1024 ? 1 / 2 : 1} mt={winWidth > 499 ? "0px" : "30px"}>
              <DemiBold color={theme.colors.wildStrawberry} fontSize={"40px"} display={"inline"}>
                2.{" "}
                <DemiBold color={theme.colors.wildStrawberry} fontSize={"22px"} display={"inline"}>
                  WHO’S COMING?
                </DemiBold>
              </DemiBold>
              <Box sx={{ bg: "#FFF" }} mx={winWidth > 499 ? "0px" : "20px"}>
                <Flex
                  flexWrap={"wrap"}
                  sx={{
                    bg: "#F3F5FB",
                    ml: winWidth <= 1024 ? "0px" : "28px",
                  }}
                >
                  <Count
                    title={"Adult"}
                    show={props.fareLabel.adult}
                    setCount={(number: number) => props.setAdultCount(number)}
                    width={winWidth}
                  />
                  <Count
                    title={"Children"}
                    show={props.fareLabel.child}
                    setCount={(number: number) => props.setChildCount(number)}
                    width={winWidth}
                  />
                  <Count
                    title={"Infant"}
                    show={props.fareLabel.infant}
                    setCount={(number: number) => props.setInfantCount(number)}
                    width={winWidth}
                  />
                </Flex>
              </Box>
            </Box>
          </Flex>
        </Box>
        {props.flavours && (
          <Box
            px={winWidth > 1024 ? "60px" : "10px"}
            mx={winWidth > 1024 ? "0px" : "10px"}
            mt={winWidth > 499 ? "20px" : "30px"}
          >
            <Flex flexWrap="wrap">
              <Box width={1}>
                <DemiBold color={theme.colors.wildStrawberry} fontSize={"40px"} display={"inline"}>
                  3.{" "}
                  <DemiBold color={theme.colors.wildStrawberry} fontSize={"22px"} display={"inline"}>
                    CHOOSE A SESSION
                  </DemiBold>
                </DemiBold>
                <Box width={1}>
                  <Flex
                    flexWrap="wrap"
                    flexDirection={winWidth > 1024 ? "row" : "column"}
                    justifyContent={winWidth > 1024 ? "space-between" : "center"}
                  >
                    {props.flavours
                      .sort((item1: any, item2: any) => item1.minRateAdult - item2.minRateAdult)
                      .map((item: any, index: any) => (
                        <Box
                          sx={{
                            bg: "transparent",
                            textAlign: "left",
                            outline: "none",
                            py: 3,
                            width: winWidth > 1024 ? "43%" : "95%",
                          }}
                        >
                          <Box
                            key={index}
                            className={"shadowBox"}
                            sx={{
                              bg: "#F3F5FB",
                              cursor: "pointer",
                              ml: "16px",
                            }}
                            onClick={() => props.handleClick(item, index)}
                          >
                            <Flex height={"100%"}>
                              <Box width={"6%"} sx={{ bg: theme.colors.solitude }}>
                                <Flex
                                  sx={{
                                    height: "100%",
                                    bg: props.flavourItem === index ? theme.colors.wildStrawberry : "#1A1F71",
                                  }}
                                ></Flex>
                              </Box>
                              <Box width={"94%"} sx={{ m: "24px" }}>
                                <Box mb="5px">
                                  <NextMedium fontSize={winWidth > 499 ? "18px" : "15px"} spacing={"-1px"}>
                                    {item.name}
                                  </NextMedium>
                                </Box>
                                <NextMedium
                                  fontSize={winWidth > 499 ? "18px" : "15px"}
                                  spacing={"-1px"}
                                  color={theme.colors.wildStrawberry}
                                >
                                  {item.fareLabelAdult ? item.fareLabelAdult : "Regular"} ${item.minRateAdult}
                                </NextMedium>
                                {item.fareLabelChild && (
                                  <NextMedium
                                    fontSize={winWidth > 499 ? "18px" : "15px"}
                                    spacing={"-1px"}
                                    color={theme.colors.wildStrawberry}
                                  >
                                    {item.fareLabelChild ? item.fareLabelChild : "Child"} $
                                    {item.minRateChild ? item.minRateChild : 0}
                                  </NextMedium>
                                )}
                                {item.fareLabelInfant && (
                                  <NextMedium
                                    fontSize={winWidth > 499 ? "18px" : "15px"}
                                    spacing={"-1px"}
                                    color={theme.colors.wildStrawberry}
                                  >
                                    {item.fareLabelInfant ? item.fareLabelInfant : "Infant"} $
                                    {item.minRateInfant ? item.minRateInfant : 0}
                                  </NextMedium>
                                )}
                              </Box>
                            </Flex>
                          </Box>
                        </Box>
                      ))}
                  </Flex>
                </Box>
              </Box>
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  ) : null;
}

function WebFooter(props: any) {
  return (
    <Flex px={"130px"} py={3} justifyContent="space-between">
      <Box sx={{ pt: theme.padding.p10 }}>
        <Button
          onClick={() => window.history.go(-1)}
          className={"item-hover"}
          sx={Object.assign({}, theme.styles.footerGoBack, {
            color: theme.colors.periglacialblue,
            border: 0,
            backgroundColor: "transparent",
            width: "141px",
            height: "55px",
            fontSize: 20,
          })}
        >
          <Flex>
            <BsChevronLeft />
            <Text> Go Back</Text>
          </Flex>
        </Button>
      </Box>
      <Box sx={{ pt: theme.padding.p10 }}>
        <Flex justifyContent="space-between">
          <BoldSkew color={theme.colors.midnightBlue} fontSize={"40px"} spacing={"0px"}>
            From ${props.product.minRateAdult.toFixed(2)}
          </BoldSkew>
          <Box mt={"25px"} px={"10px"} />
          <Button
            disabled={props.disabled}
            onClick={props.handleBooking}
            sx={Object.assign({}, theme.styles.footerCheckAvailability, {
              color: theme.colors.white,
              backgroundColor: props.disabled ? "rgba(250, 46, 129, 0.6)" : theme.colors.malachite,
              outline: "none",
              cursor: "pointer",
              width: "231px",
              height: "47px",
              fontSize: 20,
            })}
          >
            CONFIRM BOOKING
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}

function MobileFooter(props: any) {
  return (
    <div>
      <Flex px={3} justifyContent="center">
        <BoldSkew color={theme.colors.midnightBlue} fontSize={"19px"} spacing={"0px"}>
          From ${props.product.minRateAdult.toFixed(2)}
        </BoldSkew>
      </Flex>
      <Box>
        <Flex justifyContent="center">
          <Button
            disabled={props.disabled}
            onClick={props.handleBooking}
            sx={Object.assign({}, theme.styles.footerCheckAvailability, {
              color: theme.colors.white,
              backgroundColor: props.disabled ? "rgba(250, 46, 129, 0.6)" : theme.colors.malachite,
              outline: "none",
              cursor: "pointer",
              width: "50%",
              fontSize: "13px",
            })}
          >
            <Text m={"auto"}>CONFIRM BOOKING</Text>
          </Button>
        </Flex>
      </Box>
      <Box>
        <Flex mt={1} justifyContent="center">
          <Button
            onClick={() => window.history.go(-1)}
            className={"item-hover"}
            sx={Object.assign({}, theme.styles.footerCheckAvailability, {
              color: theme.colors.periglacialblue,
              backgroundColor: "transparent",
              width: "50%",
              height: "6.5vw",
              fontSize: "13px",
            })}
          >
            <Flex justifyContent="center">
              <Text mt={"-1px"}>{"< Go Back"}</Text>
            </Flex>
          </Button>
        </Flex>
      </Box>
    </div>
  );
}

import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { saveProductObj } from "../store/actions";
import { Flex, Box, Image } from "rebass";
import { BoldSkew, DemiBold, NextMedium } from "./index";
// import LimeLightning from "../assets/travelExperience/Lightning1.png";
import StopWatch from "../assets/travelExperience/HoursIcon.png";
import { theme } from "../constants";
import { calculateDuration } from "../utils";

interface IProps {
  productsToRender: any;
  navigate: any;
  handleClickProduct: any;
  padding: number;
  winWidth: number;
}

interface IState {
  product: any;
}

class RenderProduct extends React.Component<IProps, IState> {
  private onResultClick = async (item) => {
    this.setState({ product: item });
    this.props.handleClickProduct(item.id);
    this.props.navigate();
  };

  render() {
    if (this.props.productsToRender && this.props.productsToRender[0]) {
      console.log("first product is $" + JSON.stringify(this.props.productsToRender[0]["minRateAdult"]));
    }
    return (
      <Flex
        flexWrap="wrap"
        justifyContent={this.props.productsToRender.length <= 2 ? "flex-start" : "center"}
        px={this.props.productsToRender.length <= 2 ? "150px" : 0}
        py={3}
      >
        {this.props.productsToRender.map((product, index) => (
          <Box>
            <Box sx={{ ...theme.styles.prodBookNowBtn, p: this.props.winWidth > 499 ? 0 : 10 }}>
              <Box
                className={"shadowBox box-hover"}
                sx={Object.assign({}, theme.styles.prodContainer, {
                  width: this.props.winWidth > 499 ? "360px" : "100%",
                  m: this.props.winWidth > 499 ? this.props.padding : 0,
                })}
                key={index}
                onClick={async () => {
                  await this.onResultClick(product);
                }}
              >
                <Box
                  sx={Object.assign({}, theme.styles.prodDurationContainer, {
                    bg: theme.colors.white,
                  })}
                >
                  <Box mx={"11px"} my={"8px"}>
                    <Flex justifyContent={"center"}>
                      <Image
                        sx={{
                          height: "19px",
                          width: "19px",
                        }}
                        src={StopWatch}
                      />
                      <DemiBold ml={"10px"} pt={"2px"} fontSize={"14px"} color={theme.colors.midnightBlue}>
                        {calculateDuration(product)}
                      </DemiBold>
                    </Flex>
                  </Box>
                </Box>
                <Box sx={theme.styles.prodImgContainer} className={"transitionFix"}>
                  <Image
                    className={"item-image"}
                    style={theme.styles.prodImg}
                    src={product.images[0].urlPreviewSecure}
                  />
                </Box>
                <Box sx={theme.styles.prodDescContainer}>
                  <Box sx={{ height: "60px" }}>
                    <DemiBold sx={{ textAlign: "left" }} color={theme.colors.midnightBlue} fontSize={"20px"}>
                      {product.name}
                    </DemiBold>
                  </Box>

                  <Box>
                    <Flex pt={"15px"} justifyContent="flex-end">
                      <Box mt={"38px"} mr={2}>
                        <NextMedium spacing={"0px"} fontSize={"14px"} color={theme.colors.grey59}>
                          Price From
                        </NextMedium>
                      </Box>
                      <Box mt={"20px"}>
                        <Flex>
                          <BoldSkew color={theme.colors.wildStrawberry} fontSize={"30px"} spacing={"-1px"}>
                            AUD ${product.minRateAdult}
                          </BoldSkew>
                        </Flex>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Flex>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleClickProduct(obj) {
      dispatch(saveProductObj(obj));
    },
  };
};

export default connect(null, mapDispatchToProps)(withRouter(RenderProduct));

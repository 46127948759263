import React from "react";
import { Flex, Box, Button } from "rebass";
import { FaMinus, FaPlus } from "react-icons/fa";
import { BoldSkew, DemiBold, NextMedium } from "../components";
import { theme } from "../constants";

export interface IProps {
  show: any;
  title: string;
  setCount: any;
  width: any;
}

export interface IState {
  clicksCount: number;
}

export class Count extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      clicksCount: this.props.title === "Adult" ? 1 : 0,
    };
  }

  private increaseItem = () => {
    this.setState({ clicksCount: Math.min(this.state.clicksCount + 1, 9) });
    this.props.setCount(Math.min(this.state.clicksCount + 1, 9));
  };

  private decreaseItem = () => {
    const minNumber = this.props.title === "Adult" ? 1 : 0;
    this.setState({
      clicksCount: Math.max(this.state.clicksCount - 1, minNumber),
    });
    this.props.setCount(Math.max(this.state.clicksCount - 1, minNumber));
  };

  private ageCheck = (title: string, minAge: number, maxAge: number) => {
    if (title === "Adult") {
      if (minAge !== 0) {
        return minAge + " year +";
      } else {
        return "13 year +";
      }
    }
    if (title === "Children") {
      if (minAge !== 0) {
        return minAge + " - " + maxAge + " years";
      } else {
        return "4 - 12 years ";
      }
    }
    if (title === "Infant") {
      if (maxAge !== 0) {
        return minAge + " - " + maxAge + " years";
      } else {
        return "0 - 3 years ";
      }
    }
  };

  render() {
    const { clicksCount } = this.state;
    const { title, show, width } = this.props;
    if (show.exist) {
      return (
        <Flex
          p={"24px"}
          sx={{ width: "100%" }}
          flexDirection={width > 499 ? "row" : "column"}
          justifyContent={"space-between"}
        >
          <Box my={"auto"}>
            <DemiBold fontSize={"25px"}>{title.toUpperCase()}</DemiBold>
            <NextMedium fontSize={"14px"} color={theme.colors.grey59}>
              {this.ageCheck(title, show.minAge, show.maxAge)}
            </NextMedium>
          </Box>
          <Box>
            <Flex>
              <Box sx={{ paddingRight: "15px", my: "auto" }}>
                <Button sx={theme.styles.plusMinus} onClick={this.decreaseItem}>
                  <Box sx={theme.styles.plusMinusPosition}>
                    <FaMinus className={"plus-minus"} />
                  </Box>
                </Button>
              </Box>
              <Box
                sx={{
                  width: "67px",
                  height: "67px",
                  borderRadius: "16px",
                  bg: clicksCount > 0 ? theme.colors.wildStrawberry : theme.colors.midnightBlue,
                }}
              >
                <BoldSkew sx={{ mr: "5px", mt: "10px", textAlign: "center" }} fontSize={"44px"}>
                  {clicksCount}
                </BoldSkew>
              </Box>
              <Box sx={{ paddingLeft: "15px", my: "auto" }}>
                <Button sx={theme.styles.plusMinus} onClick={this.increaseItem}>
                  <Box sx={theme.styles.plusMinusPosition}>
                    <FaPlus className={"plus-minus"} />
                  </Box>
                </Button>
              </Box>
            </Flex>
          </Box>
        </Flex>
      );
    } else {
      return null;
    }
  }
}

export default Count;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { BoldSkew, NextMedium, NextRegular, DemiBold, MediumItalic, useWindowSize, StyledSelect } from "../components";
import { Input } from "@rebass/forms";
import { BsChevronLeft } from "react-icons/bs";
import { Flex, Box, Button, Text, Image } from "rebass";
import LocationIcon from "../assets/travelExperience/LocationIcon.png";
import StopWatch from "../assets/travelExperience/HoursIcon.png";
import ExperiencesIcon from "../assets/travelExperience/Experiences-Icon.png";
import AdultImg from "../assets/travelExperience/adult.png";
import ChildImg from "../assets/travelExperience/child.png";
import InfantImg from "../assets/travelExperience/infant.png";
import CalendarTick from "../assets/travelExperience/CalendarTick.png";
import { theme } from "../constants";
import Loader from "react-loader-spinner";
import { calculateDuration, isRequired, tConvert } from "../utils";
import Moment from "moment";

export function BodyContent(props: any) {
  const product: any = props.product;
  let [winWidth] = useWindowSize();

  const adultTravellers = buildTravellerType(
    props,
    props.adultTravellers,
    props.adultValidation,
    "Adult",
    AdultImg,
    winWidth
  );
  const childTravellers = buildTravellerType(
    props,
    props.childTravellers,
    props.childValidation,
    "Child",
    ChildImg,
    winWidth
  );
  const infantTravellers = buildTravellerType(
    props,
    props.infantTravellers,
    props.infantValidation,
    "Infant",
    InfantImg,
    winWidth
  );

  return product ? (
    <Box
      width={1}
      px={winWidth > theme.responsive.medium ? "144px" : "20px"}
      py={winWidth > theme.responsive.medium ? "20px" : "15px"}
      sx={{ bg: theme.colors.solitude }}
    >
      <Box width={1} sx={{ bg: "#FFF", borderRadius: "20px", pb: 20 }} className={"shadowBox"}>
        <Box width={1} sx={{ bg: "#FFF", borderRadius: "20px" }} px={"10px"} py={"10px"} className={"shadowBox"}>
          <Flex
            flexWrap="wrap"
            flexDirection={winWidth > theme.responsive.large ? "row" : "column"}
            justifyContent="center"
          >
            <Box width={winWidth > theme.responsive.large ? 0.25 : 1} height={"180px"}>
              <Box
                sx={{
                  height: "180px",
                  backgroundImage: `url(${product.images[0].urlPreviewSecure})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  borderRadius: 8,
                  width: "100%",
                }}
              />
            </Box>
            <Box
              width={winWidth > theme.responsive.large ? 0.75 : 1}
              sx={{
                px: winWidth > theme.responsive.large ? 10 : 2,
              }}
            >
              <DemiBold
                spacing={"-1px"}
                fontSize={winWidth > 499 ? "35px" : "28px"}
                sx={{ marginTop: winWidth > 1024 ? "0px" : "10px" }}
              >
                {props.pickedProduct.product.name}
              </DemiBold>
              {product.levies && (
                <NextRegular color={theme.colors.wildStrawberry} fontSize={"16px"} spacing={"-3px"}>
                  This experience has an{" "}
                  <a
                    className="item-hover-underline"
                    style={{ textDecoration: "underline" }}
                    onClick={props.openLeviModal}
                  >
                    on-the-day fee
                  </a>
                </NextRegular>
              )}
              <Box mt={"15px"}>
                <Flex
                  flexDirection={winWidth > 1278 ? "row" : "column"}
                  justifyContent={"space-between"}
                  sx={{ width: winWidth > 1024 ? "80%" : "100%" }}
                >
                  <Box sx={{ width: "80%" }}>
                    <Flex m={theme.margin.m5}>
                      <Box sx={{ width: winWidth > 1024 ? "5%" : "7%" }} mt={"3px"} mr={"10px"}>
                        <Image
                          sx={{
                            height: "19px",
                            width: "19px",
                          }}
                          src={LocationIcon}
                        />
                      </Box>
                      <Box>
                        <NextMedium sx={{ textDecoration: "underline" }} fontSize={"18px"}>
                          {product.locationStart}
                        </NextMedium>
                      </Box>
                    </Flex>
                  </Box>
                  <Box sx={{ width: winWidth > 1024 ? "20%" : "80%" }}>
                    <Flex m={theme.margin.m5}>
                      <Box mt={"3px"} mr={"10px"}>
                        <Image
                          sx={{
                            height: "19px",
                            width: "19px",
                          }}
                          src={StopWatch}
                        />
                      </Box>
                      <Box>
                        <NextMedium fontSize={"18px"} spacing={"-1px"}>
                          {calculateDuration(product)}
                        </NextMedium>
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
                <Flex
                  flexDirection={winWidth > 1278 ? "row" : "column"}
                  justifyContent={"space-between"}
                  sx={{ width: "80%" }}
                >
                  <Box sx={{ width: "80%" }}>
                    <Flex m={theme.margin.m5}>
                      <Box mt={"3px"} mr={"10px"}>
                        <Image
                          sx={{
                            height: "19px",
                            width: "19px",
                          }}
                          src={CalendarTick}
                        />
                      </Box>
                      <Box>
                        <NextMedium fontSize={"18px"} spacing={"-1px"}>
                          {Moment(props.pickedProduct.date.replace(/-/g, "/")).format("DD MMMM YYYY")}
                        </NextMedium>
                      </Box>
                    </Flex>
                  </Box>
                  <Box sx={{ width: winWidth > 1024 ? "20%" : "80%" }}>
                    <Flex flexDirection={"column"} m={theme.margin.m5}>
                      <Box py={"5px"}>
                        <Flex>
                          <Box mr={"5px"}>
                            <Image
                              sx={{
                                height: "19px",
                                width: "19px",
                              }}
                              src={AdultImg}
                            />
                          </Box>
                          <Box>
                            <NextMedium fontSize={"18px"} spacing={"-1px"}>
                              {props.pickedProduct.travellers.adult}{" "}
                              {props.pickedProduct.travellers.adult > 1 ? "Adults" : "Adult"}
                            </NextMedium>
                          </Box>
                        </Flex>
                      </Box>
                      {props.pickedProduct.travellers.child > 0 ? (
                        <Box py={"5px"}>
                          <Flex>
                            <Box mr={"5px"}>
                              <Image
                                sx={{
                                  height: "19px",
                                  width: "19px",
                                }}
                                src={ChildImg}
                              />
                            </Box>
                            <Box>
                              <NextMedium fontSize={"18px"} spacing={"-1px"}>
                                {props.pickedProduct.travellers.child}{" "}
                                {props.pickedProduct.travellers.child > 1 ? "Childrens" : "Children"}
                              </NextMedium>
                            </Box>
                          </Flex>
                        </Box>
                      ) : null}
                      {props.pickedProduct.travellers.infant > 0 ? (
                        <Box py={"5px"}>
                          <Flex>
                            <Box mr={"5px"}>
                              <Image
                                sx={{
                                  height: "19px",
                                  width: "19px",
                                }}
                                src={InfantImg}
                              />
                            </Box>
                            <Box>
                              <NextMedium fontSize={"18px"} spacing={"-1px"}>
                                {props.pickedProduct.travellers.infant}{" "}
                                {props.pickedProduct.travellers.infant > 1 ? "Infants" : "Infant"}
                              </NextMedium>
                            </Box>
                          </Flex>
                        </Box>
                      ) : null}
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            </Box>
          </Flex>
        </Box>
        <Box
          sx={{
            px: winWidth > theme.responsive.large ? "20px" : 3,
            py: 3,
          }}
        >
          <BoldSkew
            color={theme.colors.midnightBlue}
            fontSize={winWidth > theme.responsive.large ? "40px" : "30px"}
            spacing={"-2px"}
          >
            COMPLETE YOUR BOOKING
          </BoldSkew>
          <NextMedium fontSize={winWidth > theme.responsive.large ? "18px" : "15px"} color={theme.colors.midnightBlue}>
            Please provide us with the following information so we can complete your booking
          </NextMedium>
        </Box>
        <Box mx={winWidth > theme.responsive.large ? "30px" : "5%"}>
          <BoldSkew
            color={theme.colors.wildStrawberry}
            fontSize={winWidth > theme.responsive.large ? "25px" : "20px"}
            spacing={"0px"}
          >
            CONTACT DETAILS
          </BoldSkew>
          <Box>
            {adultTravellers}
            {childTravellers}
            {infantTravellers}
          </Box>
        </Box>
        {props.pickedProduct.product.pickups ? (
          <Box py={"10px"} mx={winWidth > theme.responsive.large ? "30px" : "5%"}>
            <BoldSkew
              color={theme.colors.wildStrawberry}
              fontSize={winWidth > theme.responsive.large ? "25px" : "20px"}
              spacing={"0px"}
            >
              SELECT YOUR PICK UP LOCATION
            </BoldSkew>
            <Box sx={{ bg: theme.colors.white }}>
              <Box
                sx={{
                  width: winWidth > theme.responsive.large ? "50%" : "100%",
                  overflow: "auto",
                  maxHeight: "357px"
                }}
              >
                <Box
                  sx={{
                    pb: "10px"
                  }}
                >
                  {props.pickedProduct.product.pickups.map((item: any, index: any) => (
                    <React.Fragment>
                      {item.operatingDaysStr.includes(Moment(props.pickedProduct.date.replace(/-/g, "/")).format("ddd")) && (
                        <Box
                          className={"shadowBox"}
                          onClick={() => props.handleClick(item, index)}
                          sx={{
                            borderRadius: "30px",
                            cursor: "pointer",
                            bg: props.pickupIndex === index ? theme.colors.wildStrawberry : theme.colors.white,
                            mb: "15px",
                            mt: "5px"
                          }}
                        >
                          <Box p="10px">
                            <Flex>
                              <Box
                                sx={{
                                  my: "auto",
                                  px: "5px",
                                }}
                              >
                                <Image
                                  sx={{
                                    height: "25px",
                                  }}
                                  src={ExperiencesIcon}
                                />
                              </Box>
                              <Box sx={{ width: "90%", p: "5px" }}>
                                <NextMedium
                                  color={props.pickupIndex === index ? theme.colors.white : theme.colors.midnightBlue}
                                  fontSize={"14px"}
                                >
                                  {item.pickupPoint.name}
                                </NextMedium>
                                <NextMedium
                                  color={props.pickupIndex === index ? theme.colors.white : theme.colors.midnightBlue}
                                  fontSize={"14px"}
                                >
                                  at {tConvert(item.pickupTime.slice(0, 5))}
                                </NextMedium>
                              </Box>
                            </Flex>
                          </Box>
                        </Box>
                      )}
                    </React.Fragment>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        ) : null}
      </Box>
    </Box>
  ) : null;
}

function buildTravellerType(props: any, travellers: any, validation: any, label: string, Img: any, winWidth: number) {
  const result = [] as any;
  if (travellers.length !== 0) {
    let counter = props.onlyMainContactDetails ? 1 : travellers.length;
    for (let i = 0; i < counter; i++) {
      const traveller = travellers[i];
      //let country = traveller.country;
      let address = traveller.address;
      //let nationality = traveller.nationality;
      let passportNumber = traveller.passportNumber;
      let passportIssued = traveller.passportIssued;
      let passportExpiry = traveller.passportExpiry;
      let mobile = traveller.mobile;
      let phone = traveller.phone;
      let firstName = traveller.firstName;
      let lastName = traveller.lastName;
      let dob = traveller.dob;
      let email = traveller.email;
      const isFirst = "Adult" === label && i === 0;
      const pickedProduct = props.pickedProduct.product;
      const dirty = props.dirty;
      const setInputDirty = props.setInputDirty;
      result.push(
        <Box key={i}>
          {travellersFormHeader(Img, i, validation, label, dirty)}
          {isRequired(pickedProduct.paxDetails.name, isFirst)
            ? travellersFormName(i, firstName, lastName, props.editTravellerDetails, label, winWidth, setInputDirty)
            : null}
          {isRequired(pickedProduct.paxDetails.dob, isFirst)
            ? travellersFormDOB(i, dob, props.editTravellerDetails, label, winWidth, setInputDirty)
            : null}
          {isRequired(pickedProduct.paxDetails.email, isFirst)
            ? travellersFormEmail(i, email, props.editTravellerDetails, label, winWidth, setInputDirty)
            : null}
          {isRequired(pickedProduct.paxDetails.address, isFirst)
            ? travellerFormAddress(i, address, props.editTravellerDetails, label, winWidth, setInputDirty)
            : null}
          {isRequired(pickedProduct.paxDetails.phone, isFirst)
            ? travellerFormPhone(i, phone, "phone", props.editTravellerDetails, label, winWidth, setInputDirty)
            : null}
          {isRequired(pickedProduct.paxDetails.mobile, isFirst)
            ? travellerFormPhone(i, mobile, "mobile", props.editTravellerDetails, label, winWidth, setInputDirty)
            : null}
          {isRequired(pickedProduct.paxDetails.country, isFirst)
            ? travellerFormCountry(i, props.countryList, props.editTravellerDetails, label, winWidth, setInputDirty)
            : null}
          {isRequired(pickedProduct.paxDetails.nationality, isFirst)
            ? travellerFormNationality(i, props.countryList, props.editTravellerDetails, label, winWidth, setInputDirty)
            : null}
          {isRequired(pickedProduct.paxDetails.passport, isFirst)
            ? travellerFormPassport(
                i,
                passportNumber,
                passportIssued,
                passportExpiry,
                props.countryList,
                props.editTravellerDetails,
                label,
                winWidth,
                setInputDirty
              )
            : null}
        </Box>
      );
    }
  }
  return result;
}

export function WebFooter(props: any) {
  return (
    <Flex px={"130px"} py={3} justifyContent="space-between">
      <Box sx={{ pt: theme.padding.p10 }}>
        <Button
          onClick={() => window.history.go(-1)}
          className={"item-hover"}
          sx={Object.assign({}, theme.styles.footerGoBack, {
            color: theme.colors.periglacialblue,
            border: 0,
            backgroundColor: "transparent",
            width: "141px",
            height: "55px",
            fontSize: 20,
          })}
        >
          <Flex>
            <BsChevronLeft />
            <Text> Go Back</Text>
          </Flex>
        </Button>
      </Box>
      <Box sx={{ pt: theme.padding.p10 }}>
        <Flex justifyContent="space-between">
          <BoldSkew color={theme.colors.midnightBlue} fontSize={"40px"} spacing={"0px"}>
            TOTAL ${props.total.toFixed(2)}
          </BoldSkew>
          <Box mt={"25px"} px={"10px"} />
          <Button
            disabled={props.disabled}
            onClick={props.handleReviewAndPay}
            sx={Object.assign({}, theme.styles.footerCheckAvailability, {
              color: theme.colors.white,
              backgroundColor: props.disabled ? "rgba(250, 46, 129, 0.6)" : theme.colors.malachite,
              outline: "none",
              cursor: "pointer",
              width: "231px",
              height: "47px",
              fontSize: 20,
            })}
          >
            {props.isLoading ? (
              <Loader type="ThreeDots" color="#FFFFFF" height={"5vmin"} width={"5vmin"} />
            ) : (
              "REVIEW & PAY"
            )}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}

export function MobileFooter(props: any) {
  return (
    <div>
      <Flex px={3} justifyContent="center">
        <BoldSkew color={theme.colors.midnightBlue} fontSize={"19px"} spacing={"0px"}>
          TOTAL ${props.total.toFixed(2)}
        </BoldSkew>
      </Flex>
      <Box>
        <Flex justifyContent="center">
          <Button
            disabled={props.disabled}
            onClick={props.handleReviewAndPay}
            sx={Object.assign({}, theme.styles.footerCheckAvailability, {
              color: theme.colors.white,
              backgroundColor: props.disabled ? "rgba(250, 46, 129, 0.6)" : theme.colors.malachite,
              outline: "none",
              cursor: "pointer",
              width: "50%",
              fontSize: "13px",
            })}
          >
            {props.isLoading ? (
              <Loader type="ThreeDots" color="#FFFFFF" height={"5vmin"} width={"5vmin"} />
            ) : (
              <Text m={"auto"}>REVIEW & PAY</Text>
            )}
          </Button>
        </Flex>
      </Box>
      <Box>
        <Flex mt={1} justifyContent="center">
          <Button
            onClick={() => window.history.go(-1)}
            className={"item-hover"}
            sx={Object.assign({}, theme.styles.footerCheckAvailability, {
              color: theme.colors.periglacialblue,
              backgroundColor: "transparent",
              width: "50%",
              height: "6.5vw",
              fontSize: "13px",
            })}
          >
            <Flex justifyContent="center">
              <Text mt={"-1px"}>{"< Go Back"}</Text>
            </Flex>
          </Button>
        </Flex>
      </Box>
    </div>
  );
}

function travellersFormHeader(img: any, i: number, travellerValidation: any, type: string, dirty: boolean) {
  return (
    <React.Fragment>
      {travellerValidation !== null ? (
        <Flex>
          <Box width={1 / 2} p={theme.padding.p10}>
            <Flex>
              <Image
                sx={{
                  width: "30px",
                  height: "25px",
                }}
                src={img}
              />
              <DemiBold pt={"3px"} fontSize={"20px"}>
                {type} {i + 1}
              </DemiBold>
            </Flex>
          </Box>

          <Box width={1 / 2} p={theme.padding.p10}>
            {!travellerValidation[i].validated ? (
              <MediumItalic color={theme.colors.wildStrawberry} fontSize={"14px"} sx={{ textAlign: "right" }}>
                {dirty
                  ? travellerValidation[i].error
                    ? travellerValidation[i].error
                    : "Please enter all required details"
                  : null}
              </MediumItalic>
            ) : null}
          </Box>
        </Flex>
      ) : null}
    </React.Fragment>
  );
}

function travellersFormName(
  i: number,
  firstName: string,
  lastName: string,
  editTravellerDetails: any,
  type: string,
  winWidth: number,
  setInputDirty: any
) {
  return (
    <React.Fragment>
      <Flex flexDirection={winWidth > theme.responsive.large ? "row" : "column"}>
        <Box width={winWidth > theme.responsive.large ? 1 / 2 : 1} p={theme.padding.p10}>
          <Input
            className={"shadowBox"}
            sx={theme.styles.inputForm}
            defaultValue={firstName}
            id="firstName"
            name="firstName"
            type="text"
            placeholder="First Name"
            onChange={(e: any) => {
              editTravellerDetails(i, "firstName", e.target.value, type);
              setInputDirty();
            }}
          />
        </Box>
        <Box width={winWidth > theme.responsive.large ? 1 / 2 : 1} p={theme.padding.p10}>
          <Input
            className={"shadowBox"}
            sx={theme.styles.inputForm}
            defaultValue={lastName}
            id="lastName"
            name="lastName"
            type="text"
            placeholder="Last Name"
            onChange={(e: any) => {
              editTravellerDetails(i, "lastName", e.target.value, type);
            }}
          />
        </Box>
      </Flex>
    </React.Fragment>
  );
}

function travellersFormDOB(
  i: number,
  dob: any,
  editTravellerDetails: any,
  type: string,
  winWidth: number,
  setInputDirty: any
) {
  return (
    <Box width={winWidth > theme.responsive.large ? 1 / 2 : 1} p={theme.padding.p10}>
      <Input
        className={"shadowBox"}
        sx={theme.styles.inputForm}
        defaultValue={dob}
        id="dob"
        name="dob"
        type="text"
        onFocus={(e: any) => {
          e.currentTarget.type = "date";
        }}
        onBlur={(e: any) => {
          e.currentTarget.type = "text";
          e.currentTarget.placeholder = "Date of Birth";
        }}
        placeholder="Date of Birth"
        onChange={(e: any) => {
          editTravellerDetails(i, "dob", e.target.value, type);
          setInputDirty();
        }}
      />
    </Box>
  );
}

function travellersFormEmail(
  i: number,
  email: string,
  editTravellerDetails: any,
  type: string,
  winWidth: number,
  setInputDirty: any
) {
  return (
    <Box width={winWidth > theme.responsive.large ? 1 / 2 : 1} p={theme.padding.p10}>
      <Input
        className={"shadowBox"}
        sx={theme.styles.inputForm}
        defaultValue={email}
        id="email"
        name="email"
        type="email"
        placeholder="Email Address"
        onChange={(e: any) => {
          editTravellerDetails(i, "email", e.target.value, type);
          setInputDirty();
        }}
      />
    </Box>
  );
}

function travellerFormAddress(
  i: number,
  address: string,
  editTravellerDetails: any,
  type: string,
  winWidth: number,
  setInputDirty
) {
  return (
    <Box width={winWidth > theme.responsive.large ? "80%" : 1} p={theme.padding.p10}>
      <Input
        className={"shadowBox"}
        sx={theme.styles.inputForm}
        defaultValue={address}
        id="address"
        name="address"
        type="text"
        placeholder="Address"
        onChange={(e: any) => {
          editTravellerDetails(i, "address", e.target.value, type);
          setInputDirty();
        }}
      />
    </Box>
  );
}

function travellerFormPhone(
  i,
  options: any,
  set: string,
  editTravellerDetails: any,
  type: string,
  winWidth: number,
  setInputDirty: any
) {
  return (
    <Box width={winWidth > theme.responsive.large ? 1 / 2 : 1} p={theme.padding.p10}>
      <Input
        className={"shadowBox"}
        sx={theme.styles.inputForm}
        defaultValue={options}
        id={set}
        name={set}
        type="number"
        placeholder={set.charAt(0).toUpperCase() + set.slice(1) + " Number"}
        onChange={(e: any) => {
          editTravellerDetails(i, set, e.target.value, type);
          setInputDirty();
        }}
      />
    </Box>
  );
}

function travellerFormCountry(
  i: number,
  countryList: any,
  editTravellerDetails: any,
  type: string,
  winWidth: number,
  setInputDirty: any
) {
  return (
    <Box width={winWidth > theme.responsive.large ? 1 / 2 : 1} p={theme.padding.p10}>
      <StyledSelect
        className={"shadowBox"}
        options={countryList}
        values={[]}
        onChange={(value: any) => {
          editTravellerDetails(i, "country", value, type);
          setInputDirty();
        }}
        placeholder={"Select a Country"}
      />
    </Box>
  );
}

function travellerFormNationality(
  i: number,
  countryList: any,
  editTravellerDetails: any,
  type: string,
  winWidth: number,
  setInputDirty: any
) {
  return (
    <Box width={winWidth > theme.responsive.large ? 1 / 2 : 1} p={theme.padding.p10}>
      <StyledSelect
        className={"shadowBox"}
        options={countryList}
        values={[]}
        onChange={(value: any) => {
          editTravellerDetails(i, "nationality", value, type);
          setInputDirty();
        }}
        placeholder={"Select a Nationality"}
      />
    </Box>
  );
}

function travellerFormPassport(
  i: number,
  passportNumber: string,
  passportIssued: any,
  passportExpiry: any,
  countryList: any,
  editTravellerDetails: any,
  type: string,
  winWidth: number,
  setInputDirty: any
) {
  return (
    <React.Fragment>
      <Flex flexDirection={winWidth > theme.responsive.large ? "row" : "column"}>
        <Box width={winWidth > theme.responsive.large ? 1 / 2 : 1} p={theme.padding.p10}>
          <Input
            className={"shadowBox"}
            sx={theme.styles.inputForm}
            defaultValue={passportNumber}
            id="passportNumber"
            name="passportNumber"
            type="text"
            placeholder="Passport Number"
            onChange={(e: any) => {
              editTravellerDetails(i, "passportNumber", e.target.value, type);
              setInputDirty();
            }}
          />
        </Box>
        <Box width={winWidth > theme.responsive.large ? 1 / 2 : 1} p={theme.padding.p10}>
          <StyledSelect
            className={"shadowBox"}
            options={countryList}
            values={[]}
            onChange={(value: any) => {
              editTravellerDetails(i, "nationality", value, type);
              setInputDirty();
            }}
            placeholder={"Select a Nationality"}
          />
        </Box>
      </Flex>
      <Flex>
        <Box width={winWidth > theme.responsive.large ? 1 / 2 : 1} p={theme.padding.p10}>
          <Input
            className={"shadowBox"}
            sx={theme.styles.inputForm}
            defaultValue={passportIssued}
            id="passportIssued"
            name="passportIssued"
            type="text"
            onFocus={(e: any) => {
              e.currentTarget.type = "date";
            }}
            onBlur={(e: any) => {
              e.currentTarget.type = "text";
              e.currentTarget.placeholder = "Passport Issue Date";
            }}
            placeholder="Passport Issue Date"
            onChange={(e: any) => {
              editTravellerDetails(i, "passportIssued", e.target.value, type);
              setInputDirty();
            }}
          />
        </Box>
        <Box width={winWidth > theme.responsive.large ? 1 / 2 : 1} p={theme.padding.p10}>
          <Input
            className={"shadowBox"}
            sx={theme.styles.inputForm}
            defaultValue={passportExpiry}
            id="passportExpiry"
            name="passportExpiry"
            type="text"
            onFocus={(e: any) => {
              e.currentTarget.type = "date";
            }}
            onBlur={(e: any) => {
              e.currentTarget.type = "text";
              e.currentTarget.placeholder = "Passport Expiry Date";
            }}
            placeholder="Passport Expiry Date"
            onChange={(e: any) => {
              editTravellerDetails(i, "passportExpiry", e.target.value, type);
              setInputDirty();
            }}
          />
        </Box>
      </Flex>
    </React.Fragment>
  );
}

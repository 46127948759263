import React from "react";
import { useWindowSize } from "../components";
import { Box } from "rebass";
import sampleImage from "../assets/sampleimage.jpg";
import { theme } from "../constants";

export function HeaderItem(props: any) {
  let [winWidth] = useWindowSize();
  const imageUrls = props.imageUrls;
  const imageUrl = imageUrls && imageUrls.regular ? imageUrls.regular : sampleImage;
  console.log(`imageUrl: ${imageUrl}`);
  return (
    <Box
      sx={Object.assign({}, theme.styles.prodHeader, {
        px: 4,
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: "center",
        width: winWidth,
        boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.3)",
      })}
    >
      {props.children}
    </Box>
  );
}

import styled from "styled-components";
import React from "react";
import logo from "../assets/logo.png";

const HeaderWrapper = styled.div`
  position: relative;
  height: 90px;
  border-bottom: 1px solid #f0f0f0;
  z-index: 1;
`;

const Logo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 12%;
  height: 90px;
  background-image: url(${logo});
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
`;

const Nav = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 88%;
  height: 100%;
  margin: 0 auto;
  padding-right: 70px;
  box-sizing: border-box;
`;

const NavItem = styled.div`
  line-height: 90px;
  margin: 0 15px;
  font-size: 14px;
  color: #1a1f71;
  cursor: pointer;
  font-family: AvenirNextMedium;
  &.left {
    float: left;
  }
  &.active {
    color: #fa2e81;
    box-shadow: inset 0 -3px 0 #fa2e81;
  }
  &.right {
    width: 10%;
    height: 40px;
    border-radius: 20px;
    background-color: #1a1f71;
    color: #ffffff;
    font-size: 22px;
    font-family: AvenirNextCondensedBoldSkew;
    float: right;
    margin-top: 25px;
    text-align: center;
  }
`;

export const Header = props => (
  <HeaderWrapper>
    <Logo />
    <Nav>
      <NavItem className="left">Travel Insurance</NavItem>
      <NavItem className="left active">Experience</NavItem>
      <NavItem className="left">Travel SIM Cards</NavItem>
      <NavItem className="left">FAQs</NavItem>
      <NavItem className="left">Blog</NavItem>
      <NavItem className="right">GET THE APP</NavItem>
    </Nav>
  </HeaderWrapper>
);

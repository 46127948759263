import styled from "styled-components";
import { theme } from "../constants";
import { Text } from "rebass/styled-components";

interface IProps {
  color?: string;
  fontSize?: any;
  spacing?: any;
  lineHeight?: any;
}

export const BoldSkew = styled(Text)`
  color: ${(props: IProps) => (props.color ? props.color : theme.colors.white)};
  letter-spacing: ${(props: IProps) => (props.spacing ? props.spacing : "-3.5px")};
  font-size: ${(props: IProps) => (props.fontSize ? props.fontSize : "70px")};
  font-family: AvenirNextCondensedBoldSkew;
  line-height: ${(props: IProps) => (props.lineHeight ? props.lineHeight : "50px")};
`;

export const NextRegular = styled(Text)`
  color: ${(props: IProps) => (props.color ? props.color : theme.colors.white)};
  ${(props: IProps) => (props.spacing ? props.spacing : "-1.5px")};
  font-size: ${(props: IProps) => (props.fontSize ? props.fontSize : "30px")};
  font-family: AvenirNextRegular;
`;

export const DemiBold = styled(Text)`
  color: ${(props: IProps) => (props.color ? props.color : theme.colors.midnightBlue)};
  letter-spacing: ${(props: IProps) => (props.spacing ? props.spacing : "0px")};
  font-size: ${(props: IProps) => (props.fontSize ? props.fontSize : "50px")};
  font-family: AvenirNextDemiBold;
`;

export const DemiBoldSkew = styled(Text)`
  color: ${(props: IProps) => (props.color ? props.color : theme.colors.white)};
  letter-spacing: ${(props: IProps) => (props.spacing ? props.spacing : "0px")};
  font-size: ${(props: IProps) => (props.fontSize ? props.fontSize : "18px")};
  font-family: AvenirNextCondensedDemiBoldSkew;
`;

export const NextMedium = styled(Text)`
  color: ${(props: IProps) => (props.color ? props.color : theme.colors.midnightBlue)};
  letter-spacing: ${(props: IProps) => (props.spacing ? props.spacing : "0px")};
  font-size: ${(props: IProps) => (props.fontSize ? props.fontSize : "50px")};
  font-family: AvenirNextMedium;
`;

export const MediumItalic = styled(Text)`
  color: ${(props: IProps) => (props.color ? props.color : theme.colors.white)};
  letter-spacing: ${(props: IProps) => (props.spacing ? props.spacing : "0px")};
  font-size: ${(props: IProps) => (props.fontSize ? props.fontSize : "18px")};
  font-family: AvenirNextCondensedMediumItalic;
`;

export const BoldItalic = styled(Text)`
  color: ${(props: IProps) => (props.color ? props.color : theme.colors.white)};
  letter-spacing: ${(props: IProps) => (props.spacing ? props.spacing : "0px")};
  font-size: ${(props: IProps) => (props.fontSize ? props.fontSize : "18px")};
  font-family: AvenirNextCondensedBoldItalic;
`;
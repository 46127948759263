import Moment from "moment";
import axios from "axios";

const DOMAIN_TEST = "https://test.labirdo.pelikin.co";

export function getStripeKey(): string {
  const key = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "pk_test_9mcQItFhoWuamkWojatatOLe00kxR4I3c9";
  return key;
}

const DOMAIN = process.env.REACT_APP_DOMAIN || DOMAIN_TEST;
const DOMAIN_URL = DOMAIN + "/api/v1/";

const DAYS_TO_SEARCH = process.env.REACT_APP_DAYS_TO_SEARCH ? parseInt(process.env.REACT_APP_DAYS_TO_SEARCH) : 31;
//const DAYS_TO_SEARCH = 365;
console.log("Domain is:" + DOMAIN);

const api = axios.create({
  baseURL: DOMAIN_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

/**
 *
 * @param code the discount code
 * @returns the percentage abount (example, 10)
 */
export async function getDiscountPercentage(code: string) {
  const result = await getDiscount(code);
  return result.data.percentage;
}

/**
 *   Returns the discount object, or 404 if not found.
 *   Example:
 *   "body": {
        "id": 1,
        "name": "pel10",
        "percentage": 10,
        "current": 1
    },
*/
export async function getDiscount(code: string) {
  const result = await api.get(`livn/discount/${code}`);
  return result;
}

export async function requestCountries() {
  const result = await api.get("livn/countries");
  return result;
}

export async function verifyBooking(bookingData) {
  const response = await api.post("livn/booking/verify", bookingData);
  return response;
}
/**
 * Include the full details, including the discount code, if any.
 * Example:
 *  const postData = {
      token: token,
      cartId: cartId,
      email: 'user@example.com',
      promoCode: 'pel10'
    };
 */
export async function completeBooking(bookingData) {
  const response = await api.post("livn/booking/complete", bookingData);
  return response;
}

export async function sendEmailCopy(bookedDataAndEmail) {
  const response = await api.post("livn/booking/email", bookedDataAndEmail);
  return response;
}

// List all valid bookings (both past and future) for the current user

export async function listBookings() {
  const response = await api.get("livn/booking/list");
  return response;
}

export async function getStartCities() {
  const start = new Date().getTime();
  const response = await api.get("livn/cities/start");
  const end = new Date().getTime();
  const diff = end - start;
  console.log(`getStartCities took ${diff} ms`);
  return response;
}

export async function getCities() {
  const start = new Date().getTime();
  const response = await api.get("livn/cities/list/");
  const end = new Date().getTime();
  const diff = end - start;
  console.log(`getCities took ${diff} ms`);
  return response;
}

export async function getProductDetails(productId) {
  const start = new Date().getTime();
  const response = await api.get(`/livn/products/details/${productId}`);
  const end = new Date().getTime();
  const diff = end - start;
  console.log(`getProductDetails took ${diff} ms`);
  return response;
}

/**
 * 
 * @param productId  id of the product
 * @param startDate  start date to check for (string)
 * @param units      set if checking more than 1 (default)
 * 
 * @returns json object containing availability 
 * {
    "availabilityCheck": {
        "availabilities": [
            {
                "available": true,
                "date": "2020-08-28",
                "availableUnits": 1,
            }, ...
 * 
 * example: checkAvailability(14890,'2020-08-28')
 */
export async function checkAvailability(productId, startDate, units = 1) {
  const start = new Date().getTime();
  const response = await api.get(`/livn/booking/available/${productId}/${startDate}/${units}`);
  const end = new Date().getTime();
  const diff = end - start;
  console.log(`checkAvailability took ${diff} ms`);
  return response;
}

export async function checkAvailabilityAndPrice(product, startDate, travellerCount) {
  const start = new Date().getTime();
  const data = {
    product,
    startDate,
    travellerCount,
  };

  const response = await api.post("/livn/booking/available", data);
  console.log("checkAvailabilityAndPrice for " + startDate + " response:" + response.data);
  const end = new Date().getTime();
  const diff = end - start;
  console.log(`checkAvailabilityAndPrice took ${diff} ms`);
  return response;
}

// Lists Products by tree view
export async function getProductByLocation(lng, lat, dist, countries = undefined) {
  return getProductByLocationAndDate(lng, lat, dist, null, countries);
}

export async function getProductByLocationAndDate(lng, lat, dist, date, countries = undefined) {
  if (!lng || !lat) {
    throw new Error("Error - No Location is available");
  }
  const start = new Date().getTime();
  let url: string = "/livn/products/search/tree/location/";
  if (date) {
    // GET one month for now, as so little data in test
    const { startDate, endDate } = getDates(date, DAYS_TO_SEARCH);
    url += `${lng}/${lat}/${dist}/${startDate}/${endDate}`;
  } else {
    url += `${lng}/${lat}/${dist}`;
  }
  if (countries) {
    url += `/${countries}`;
  }
  const response = await api.get(url);
  console.log("getProductByLocationAndDate Took:" + (new Date().getTime() - start));
  return response;
}

// Not currently recommenmded
/*async function getProductByCityName(name, date) {
  const start = new Date().getTime();
  const { startDate, endDate } = getDates(date);
  const url = `/livn/products/search/cities/${name}/${startDate}/${endDate}`;
  console.log(url);
  const response = await api.get(url);
  console.log("getProductByCityName Took:" + (new Date().getTime() - start));
  return response;
}*/

const getDates = (date, add = 6) => {
  console.log(`adding ${add} days`);
  const startDate = date !== null ? Moment(date).format("YYYY-MM-DD") : Moment().format("YYYY-MM-DD");
  const endDate =
    date !== null ? Moment(date).add(add, "days").format("YYYY-MM-DD") : Moment().add(add, "days").format("YYYY-MM-DD");
  return { startDate, endDate };
};

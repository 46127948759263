import { SAVE_CITY_OBJ, SAVE_PRODUCT_OBJ } from "./actions";
import { bake_cookie, read_cookie } from "sfcookies";

const initialState = {
  userSelectedCity: read_cookie("userSelectedCity") || {},
  userSelectedProduct: read_cookie("userSelectedProduct") || null,
};

export default (state = initialState, action) => {
  let newState = null;

  switch (action.type) {
    case SAVE_CITY_OBJ:
      newState = {
        ...state,
        userSelectedCity: action.city
      };
      bake_cookie("userSelectedCity", action.city);
      return newState;
    case SAVE_PRODUCT_OBJ:
      newState = {
        ...state,
        userSelectedProduct: action.productID
      };
      bake_cookie("userSelectedProduct", action.productID);
      return newState;
    default:
      return state;
  }
};

import React from "react";
import { connect } from "react-redux";
import { Flex, Box, Image } from "rebass";
import { IoMdArrowDropright } from "react-icons/io";
import { theme } from "../../constants";
import { withRouter } from "react-router";
import {
  Footer,
  HeaderItem,
  BoldSkew,
  MediumItalic,
  ModalLeviesView,
  ModalLoadingView,
  useWindowSize,
  PriceChangeModal,
  BookingAndPaymentErrorModal,
} from "../../components";
import { BodyContent, MobileFooter, WebFooter } from "../../container/ProductBookingContainer";
import ExperiencesIcon from "../../assets/travelExperience/Loudspeaker.png";
import { Hide } from "@rebass/hide";
import { getProductDetails, verifyBooking, requestCountries } from "../../api";
import { isRequired, isValidEmail } from "../../utils";

type fieldType =
  | "address"
  | "country"
  | "dob"
  | "firstName"
  | "gender"
  | "language"
  | "lastName"
  | "mobile"
  | "email"
  | "nationality"
  | "passportNumber"
  | "passportIssued"
  | "passportExpiry"
  | "phone";

export interface Traveller {
  firstName: string;
  lastName: string;
  salutation: string;
  gender: string;
  address: string;
  country: string;
  language: string;
  nationality?: string;
  passportNumber?: string | undefined;
  passportIssued?: Date | string | null;
  passportExpiry?: Date | string | null;
  mobile?: string | number | undefined;
  phone?: number | string | undefined;
  email?: string;
  dob?: Date | string | null;
  age?: number | null;
}

interface IProps {
  history: any;
  location: any;
  product: any;
  cityName: any;
  pickedProduct: any;
  handlePickedProduct: any;
}
interface IState {
  userSelectedProduct: any;
  productItem: any;
  modalIsOpen: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  pickedProduct: any;
  total: number;
  onlyMainContactDetails: boolean;
  adultTravellers: Traveller[];
  childTravellers: Traveller[];
  infantTravellers: Traveller[];
  countryList: any;
  adultValidation: any;
  childValidation: any;
  infantValidation: any;
  levimodalIsOpen: boolean;
  pickupItem: any;
  pickupIndex: any;
  allPaxValidated: boolean;
  rateChange: string;
  hasError: boolean;
  inputDirty: boolean;
  priceChangeModalShow: boolean;
  priceChangeData: any;
  errorModalIsOpen: boolean;
  errorType: string;
}

class ProductBooking extends React.Component<IProps, IState> {
  constructor(props) {
    super(props);
    const travellerCount = this.props.location.state.pickedProduct;
    let adultTravellers: Traveller[] = [];
    let childTravellers: Traveller[] = [];
    let infantTravellers: Traveller[] = [];
    let adultValidation = [] as any;
    let childValidation = [] as any;
    let infantValidation = [] as any;
    for (let i = 0; i < travellerCount.travellers.adult; i++) {
      adultTravellers.push(this.getDefaultTraveller());
      adultValidation.push({ number: i, validated: false, error: "" });
    }

    for (let i = 0; i < travellerCount.travellers.child; i++) {
      childTravellers.push(this.getDefaultTraveller());
      if (travellerCount.product.paxDetails.name !== "ONCE") {
        childValidation.push({ number: i, validated: false, error: "" });
      }
      childValidation = null;
    }

    for (let i = 0; i < travellerCount.travellers.infant; i++) {
      infantTravellers.push(this.getDefaultTraveller());
      if (travellerCount.product.paxDetails.name !== "ONCE") {
        infantValidation.push({ number: i, validated: false, error: "" });
      }
      infantValidation = null;
    }
    this.state = {
      adultTravellers,
      childTravellers,
      infantTravellers,
      adultValidation,
      childValidation,
      infantValidation,
      userSelectedProduct: "",
      productItem: "",
      modalIsOpen: false,
      isDisabled: true,
      isLoading: false,
      pickedProduct: "",
      total: 0,
      onlyMainContactDetails: false,
      countryList: null,
      levimodalIsOpen: false,
      pickupItem: "",
      pickupIndex: "",
      allPaxValidated: false,
      rateChange: "",
      hasError: true,
      inputDirty: false,
      priceChangeModalShow: false,
      priceChangeData: "",
      errorModalIsOpen: false,
      errorType: ""
    };
  }

  private closeErrorModal = () => {
    this.setState({ errorModalIsOpen: false });
  };

  public componentWillMount = async () => {
    this.openModal();
    try {
      const item = await requestCountries();
      this.getCountryList(item.data.body);
    } catch (e) {
      console.log(e);
    }
    try {
      const response = await getProductDetails(this.props.product);
      if (response.status === 200) {
        this.setState({
          productItem: response.data.product,
          pickedProduct: this.props.location.state.pickedProduct,
          rateChange: this.props.location.state.rateChange,
        });
        this.handleTotalPrice();
        this.checkPaxDetails();
      }
    } catch (e) {
      console.log(e);
    }
    this.closeModal();
  };

  private getCountryList = (data: any) => {
    var countryList = [] as any;
    data.map((item: any) =>
      countryList.push({
        label: item.name,
        value: item.two_char_code,
      })
    );

    this.setState({ countryList });
    console.log(this.state.countryList[0]);
  };

  private checkPaxDetails = () => {
    const { pickedProduct } = this.state;
    console.log("paxDetails" + JSON.stringify(pickedProduct.product.paxDetails));
    if (Object.values(pickedProduct.product.paxDetails).indexOf("ALL") > -1) {
      this.setState({ onlyMainContactDetails: false });
    } else {
      this.setState({ onlyMainContactDetails: true });
    }
  };

  private getDefaultTraveller = () => {
    return {
      salutation: "MX",
      gender: "X",
      address: "",
      country: "",
      language: "en",
      nationality: "",
      firstName: "",
      lastName: "",
    };
  };

  private calculateAge = (dob1: any) => {
    var today = new Date();
    var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
    var ageNow = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      ageNow--;
    }
    return ageNow;
  };

  private editTravellerDetails = (index: number, field: fieldType, value: string, type: string) => {
    if (type === "Adult") {
      let adultTravellers = this.state.adultTravellers;
      this.editTraveller(adultTravellers, field, index, value);
      this.setState({ adultTravellers });
    }
    if (type === "Child") {
      let childTravellers = this.state.childTravellers;
      this.editTraveller(childTravellers, field, index, value);
      this.setState({ childTravellers });
    }
    if (type === "Infant") {
      let infantTravellers = this.state.infantTravellers;
      this.editTraveller(infantTravellers, field, index, value);
      this.setState({ infantTravellers });
    }
    if (!this.validateTravellersData()) {
      return false;
    }
    return true;
  };

  private setError = (index: number, type: string, error: string) => {
    if (type === "Adult") {
      const some_array = [...this.state.adultValidation];
      some_array[index].error = error;
      some_array[index].validated = false;
      this.setState({ allPaxValidated: false, adultValidation: some_array });
    }
    if (type === "Child") {
      if (this.state.childValidation !== null) {
        const some_array = [...this.state.childValidation];
        some_array[index].error = error;
        some_array[index].validated = false;
        this.setState({ allPaxValidated: false, childValidation: some_array });
      }
    }
    if (type === "Infant") {
      if (this.state.infantValidation !== null) {
        const some_array = [...this.state.infantValidation];
        some_array[index].error = error;
        some_array[index].validated = false;
        this.setState({ allPaxValidated: false, infantValidation: some_array });
      }
    }
    return true;
  };

  private validateTravellers = (
    travellers: Traveller[],
    label: string,
    minAge: number,
    maxAge: number,
    adults: boolean = false
  ) => {
    const { pickedProduct } = this.state;
    const hasError = travellers.some((traveller, index) => {
      let isMainTraveller = adults && index === 0;
      let age = traveller.age !== null ? traveller.age : "";

      if (isRequired(pickedProduct.product.paxDetails.name, isMainTraveller)) {
        if (traveller.firstName.trim().length === 0) {
          return this.setError(index, label, `${label} ${index + 1} first name cannot be empty`);
        }
        if (traveller.lastName.trim().length === 0) {
          return this.setError(index, label, `${label} ${index + 1} last name cannot be empty.`);
        }
      } else {
        // Names are not required, but we want to include a name for the ticket, so use the main traveller
        if (traveller.firstName.trim().length === 0) {
          traveller.firstName = this.state.adultTravellers[0].firstName;
        }
        if (traveller.lastName.trim().length === 0) {
          traveller.lastName = this.state.adultTravellers[0].lastName;
        }
      }

      if (isRequired(pickedProduct.product.paxDetails.dob, isMainTraveller)) {
        let dobRegex = RegExp("^(19[5-9][0-9]|20[0-4][0-9]|2050)[-/](0?[1-9]|1[0-2])[-/](0?[1-9]|[12][0-9]|3[01])$");
        if (!traveller.dob || traveller.dob === "") {
          return this.setError(index, label, `${label} ${index + 1} dob cannot be empty.`);
        }
        if (traveller.dob && !dobRegex.test(traveller.dob.toString())) {
          return this.setError(index, label, `${label} ${index + 1} Date of Birth field is invalid`);
        }
        if (!age) {
          return this.setError(index, label, `${label} ${index + 1} has an invalid age.`);
        }
        if (age < minAge) {
          return this.setError(index, label, `${label} ${index + 1} must be more than ${minAge} years of age.`);
        }
        if (age > maxAge) {
          return this.setError(index, label, `${label} ${index + 1} invalid age.`);
        }
      }

      if (isRequired(pickedProduct.product.paxDetails.email, isMainTraveller)) {
        if (traveller.email === null) {
          return this.setError(index, label, `${label} ${index + 1} email is required.`);
        }
        if (!isValidEmail(traveller.email)) {
          return this.setError(index, label, `${label} ${index + 1} email is invalid.`);
        }
      }

      if (isRequired(pickedProduct.product.paxDetails.mobile, isMainTraveller)) {
        if (!traveller.mobile) {
          return this.setError(index, label, `${label} ${index + 1} mobile number field is empty`);
        }
      }

      if (isRequired(pickedProduct.product.paxDetails.phone, isMainTraveller)) {
        if (!traveller.phone) {
          return this.setError(index, label, `${label} ${index + 1} phone number field is empty`);
        }
      }

      if (isRequired(pickedProduct.product.paxDetails.address, isMainTraveller)) {
        if (!traveller.address) {
          return this.setError(index, label, `${label} ${index + 1} address field is empty`);
        }
      }

      if (isRequired(pickedProduct.product.paxDetails.country, isMainTraveller)) {
        if (traveller.country === "") {
          return this.setError(index, label, `${label} ${index + 1} must select a Country`);
        }
      }

      if (isRequired(pickedProduct.product.paxDetails.nationality, isMainTraveller)) {
        if (traveller.nationality === "") {
          return this.setError(index, label, `${label} ${index + 1} must select a Nationality`);
        }
      }

      if (isRequired(pickedProduct.product.paxDetails.passport, isMainTraveller)) {
        let passportRegex = RegExp("^[A-Z][0-9]{8}$");
        let passportExpRegex = RegExp(
          "^(19[5-9][0-9]|20[0-4][0-9]|2050)[-/](0?[1-9]|1[0-2])[-/](0?[1-9]|[12][0-9]|3[01])$"
        );
        if (traveller.passportNumber === undefined) {
          return this.setError(index, label, `${label} ${index + 1} passport number field is empty`);
        }
        if (traveller.passportNumber && !passportRegex.test(traveller.passportNumber)) {
          return this.setError(index, label, `${label} ${index + 1} passport number field is invalid`);
        }
        if (!traveller.passportIssued || traveller.passportIssued === "") {
          return this.setError(index, label, `${label} ${index + 1} passport issue date cannot be empty.`);
        }
        if (!traveller.passportExpiry || traveller.passportExpiry === "") {
          return this.setError(index, label, `${label} ${index + 1} passport expiry date cannot be empty.`);
        }
        if (traveller.passportExpiry && !passportExpRegex.test(traveller.passportExpiry.toString())) {
          return this.setError(index, label, `${label} ${index + 1} passport expiry date field is invalid`);
        }
        if (traveller.passportIssued && !passportExpRegex.test(traveller.passportIssued.toString())) {
          return this.setError(index, label, `${label} ${index + 1} passport issue date field is invalid`);
        }
        if (traveller.passportExpiry) {
          const expDate = new Date(traveller.passportExpiry).valueOf();
          const now = new Date().valueOf();
          if (expDate < now) {
            return this.setError(index, label, `${label} ${index + 1} passport has expired`);
          }
          if (traveller.passportIssued > traveller.passportExpiry) {
            return this.setError(
              index,
              label,
              `${label} ${index + 1} passport issue date cannot be more than passport expiry date`
            );
          }
        }
      }
      if (label === "Adult") {
        const some_array = [...this.state.adultValidation];
        some_array[index].validated = true;
        some_array[index].error = "";
        this.setState({ adultValidation: some_array });
      }
      if (label === "Child") {
        if (this.state.childValidation !== null) {
          const some_array = [...this.state.childValidation];
          some_array[index].validated = true;
          some_array[index].error = "";
          this.setState({ childValidation: some_array });
        }
      }
      if (label === "Infant") {
        if (this.state.childValidation !== null) {
          const some_array = [...this.state.infantValidation];
          some_array[index].validated = true;
          some_array[index].error = "";
          this.setState({ infantValidation: some_array });
        }
      }
      return false;
    });
    this.setState({ hasError: false });
    if (!this.state.pickedProduct.product.pickups && !hasError) {
      this.setState({ allPaxValidated: true });
      this.setState({
        isDisabled: false,
      });
    }
    if (this.state.pickupIndex !== "" && !hasError) {
      this.setState({ allPaxValidated: true, isDisabled: false });
      this.setState({});
    }
    if (hasError) {
      this.setState({ hasError: true, allPaxValidated: false, isDisabled: true });
    }

    return !hasError;
  };

  private validateTravellersData = (): boolean => {
    const { pickedProduct, adultTravellers } = this.state;
    let minAdultAge = pickedProduct.product.minAgeAdult ? pickedProduct.product.minAgeAdult : 12;
    let minChildAge = pickedProduct.product.minAgeChild ? pickedProduct.product.minAgeChild : 6;
    let maxChildAge = pickedProduct.product.maxAgeChild ? pickedProduct.product.maxAgeChild : 11;

    if (!this.validateTravellers(adultTravellers, "Adult", minAdultAge, 140, true)) {
      return false;
    }
    if (!this.validateTravellers(this.state.childTravellers, "Child", minChildAge, maxChildAge, false)) {
      return false;
    }
    if (!this.validateTravellers(this.state.infantTravellers, "Infant", 0, minChildAge, false)) {
      return false;
    }

    return true;
  };

  private editTraveller = (travellers: Traveller[], field: fieldType, index: number, value: any) => {
    if (field === "firstName") {
      travellers[index][field] = value as string;
    } else if (field === "lastName") {
      travellers[index][field] = value as string;
    } else if (field === "dob") {
      travellers[index][field] = value as string;
      travellers[index]["age"] = this.calculateAge(value) as number;
    } else if (field === "country") {
      console.log("country:" + JSON.stringify(value));
      const code = value[0].value;
      console.log("set country:" + code);
      travellers[index][field] = code as string;
    } else if (field === "nationality") {
      console.log("nationality:" + JSON.stringify(value));
      const code = value[0].value;
      console.log("set nationality:" + code);
      travellers[index][field] = code as string;
    } else if (field === "passportNumber") {
      travellers[index][field] = value as string;
    } else if (field === "passportIssued") {
      travellers[index][field] = value as string;
    } else if (field === "passportExpiry") {
      travellers[index][field] = value as string;
    } else if (field === "mobile") {
      travellers[index][field] = value;
    } else if (field === "phone") {
      travellers[index][field] = value;
    } else if (field === "email") {
      travellers[index][field] = value as string;
    } else if (field === "address") {
      travellers[index][field] = value as string;
    }
  };

  private openModal = () => {
    this.setState({ modalIsOpen: true });
  };

  private closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  private openLeviModal = () => {
    this.setState({ levimodalIsOpen: true });
  };

  private closeLeviModal = () => {
    this.setState({ levimodalIsOpen: false });
  };

  private checkHasError = (hasError: boolean) => {
    console.log("check if all error good", hasError);
    if (!hasError) {
      this.setState({ allPaxValidated: true, isDisabled: false });
    } else {
      this.setState({
        isDisabled: true,
      });
    }
  };

  private handleClick = (item: any, index: number) => {
    const { hasError, allPaxValidated } = this.state;
    this.setState({
      pickupItem: item,
      pickupIndex: index,
    });
    this.checkHasError(hasError);
    if (allPaxValidated === true && !hasError) {
      this.setState({
        isDisabled: false,
      });
    }
  };

  private handleReviewAndPay = async () => {
    const { history } = this.props;
    this.setState({ isLoading: true });
    const mainEmail = this.state.adultTravellers[0].email;
    this.openModal();
    try {
      const validate = await this.validateBooking();
      if (validate) {
        this.closeModal();
        const payment = {
          totalValue: this.state.total,
          pickupPoint: this.state.pickupItem,
          cartId: validate.cartId,
          email: mainEmail,
        };
        history.push({
          pathname: "/productPayment",
          state: {
            pickedProduct: this.state.pickedProduct,
            payment,
          },
        });
      } else {
        this.setState({ errorType: "", errorModalIsOpen: true });
        this.setState({ isLoading: false });
        this.closeModal();
        console.log("Not validated");
        return;
      }
      this.setState({ isLoading: false });
    } catch (e) {
      console.log(e);
    }
  };

  private validateBooking = async () => {
    let paxes = this.state.adultTravellers;
    paxes = paxes.concat(this.state.childTravellers);
    paxes = paxes.concat(this.state.infantTravellers);
    const cartItems: any = [];

    //console.log(JSON.stringify(this.state.pickedProduct));
    for (let i = 0; i < paxes.length; i++) {
      let item = {
        paxIndex: i,
        productId: this.state.pickedProduct.product.id,
        productDate: this.state.pickedProduct.date,
        pickupId: this.state.pickupItem.id,
      };
      //console.log(item);
      cartItems.push(item);
    }

    const verifyData = {
      cart: {
        paxes: paxes,
        cartItems: cartItems,
        total: this.state.total,
        //Set random value to test changed price message
        // e.g.   total: 123
      },
    };

    try {
      const result = await verifyBooking(verifyData);
      console.log("result of verify:" + JSON.stringify(result));
      if (result.status === 200) {
        return result.data;
      }
    } catch (e) {
      console.log(e);
      const res = e.response;
      if (res) {
        console.log(res.data);
        if (res.status === 409) {
          const price = res.data.total;
          console.log(`The new price is $${price}`);
          let priceChangeData = {
            type: "change",
            total: price,
          };
          this.setState({ priceChangeData, rateChange: "max", priceChangeModalShow: true, total: price });
        }
      } else {
        console.log("e without response!" + e);
      }
      return null;
    }
  };

  private handleTotalPrice = () => {
    const { pickedProduct, rateChange } = this.state;
    let total = 0;
    let priceAdult = 0;
    let priceChild = 0;
    let priceInfant = 0;
    let adultRate = rateChange === "max" ? pickedProduct.product.maxRateAdult : pickedProduct.product.minRateAdult;
    let childRate = rateChange === "max" ? pickedProduct.product.maxRateChild : pickedProduct.product.minRateChild;
    let infantRate = rateChange === "max" ? pickedProduct.product.maxRateInfant : pickedProduct.product.minRateInfant;

    if (pickedProduct.travellers.adult !== 0) {
      priceAdult = pickedProduct.travellers.adult * adultRate;
    }
    if (pickedProduct.travellers.child !== 0) {
      priceChild = pickedProduct.travellers.child * childRate;
    }
    if (pickedProduct.travellers.infant !== 0) {
      priceInfant = pickedProduct.travellers.infant * infantRate;
    }
    total = priceAdult + priceChild + priceInfant;
    this.setState({ total });
  };

  private modalBlueContent = () => {
    let [winWidth] = useWindowSize();
    return (
      <Flex justifyContent="center" flexWrap="wrap" flexDirection="column">
        <Flex justifyContent="center">
          <Image
            sx={{
              height: winWidth > 900 ? "74px" : "60px",
            }}
            src={ExperiencesIcon}
          />
        </Flex>
        <Box>
          <BoldSkew
            fontSize={winWidth > 499 ? "40px" : "30px"}
            spacing={"-1px"}
            color="#FFF"
            sx={{ textAlign: "center", py: "10px" }}
          >
            ON-THE-DAY-FEE
          </BoldSkew>
          <MediumItalic fontSize={winWidth > 499 ? "18px" : "15px"} sx={{ textAlign: "center" }}>
            This experience has a fee payable on the day of your experience.
          </MediumItalic>
          <Flex justifyContent="center">
            <Box sx={{ width: "50%" }}>
              <ul>
                {this.state.productItem.levies.map((item: any, index: any) => (
                  <li>
                    <MediumItalic fontSize={winWidth > 499 ? "18px" : "15px"} sx={{ textAlign: "center" }}>
                      {item.description} fee ${item.amount}p/p
                    </MediumItalic>
                  </li>
                ))}
              </ul>
            </Box>
          </Flex>
        </Box>
      </Flex>
    );
  };

  private modalWhiteContent = () => {
    let [winWidth] = useWindowSize();
    return (
      <Flex justifyContent="center">
        <Box width={1 / 2} onClick={this.closeLeviModal} sx={{ cursor: "pointer", outline: "none" }}>
          <Flex className={"lineBreak"} justifyContent="center">
            <BoldSkew
              sx={{ textAlign: "center" }}
              color={theme.colors.wildStrawberry}
              fontSize={winWidth > 499 ? "22px" : "18px"}
              spacing="-1px"
              lineHeight="30px"
            >
              ACCEPT AND {"\n"} CONTINUE BOOKING
            </BoldSkew>
            <Box>
              <IoMdArrowDropright style={{ color: theme.colors.wildStrawberry, fontSize: "50px" }} />
            </Box>
          </Flex>
        </Box>
      </Flex>
    );
  };

  private setInputDirty = () => {
    this.setState({
      inputDirty: true,
    });
  };

  private closePriceChangeModal = () => {
    this.setState({ priceChangeModalShow: false });
  };

  render() {
    const {
      productItem,
      isDisabled,
      pickedProduct,
      total,
      adultTravellers,
      childTravellers,
      infantTravellers,
      onlyMainContactDetails,
      countryList,
      adultValidation,
      childValidation,
      infantValidation,
      pickupIndex,
      levimodalIsOpen,
      modalIsOpen,
      isLoading,
      priceChangeModalShow,
      priceChangeData,
      errorType,
      errorModalIsOpen
    } = this.state;
    return (
      <React.Fragment>
        {productItem ? (
          <Box>
            <BodyContent
              product={productItem}
              pickedProduct={pickedProduct}
              adultValidation={adultValidation}
              childValidation={childValidation}
              infantValidation={infantValidation}
              handleClick={(item: any, index: number) => this.handleClick(item, index)}
              pickupIndex={pickupIndex}
              countryList={countryList}
              editTravellerDetails={(index: number, fieldType: any, value: any, type: any) =>
                this.editTravellerDetails(index, fieldType, value, type)
              }
              adultTravellers={adultTravellers}
              childTravellers={childTravellers}
              infantTravellers={infantTravellers}
              onlyMainContactDetails={onlyMainContactDetails}
              openModal={this.openModal}
              openLeviModal={this.openLeviModal}
              dirty={this.state.inputDirty}
              setInputDirty={this.setInputDirty}
            />
            <Footer>
              <Hide xsmall small>
                <WebFooter
                  total={total}
                  isLoading={isLoading}
                  handleReviewAndPay={this.handleReviewAndPay}
                  disabled={isDisabled}
                />
              </Hide>
              <Hide medium large xlarge>
                <MobileFooter
                  total={total}
                  isLoading={isLoading}
                  handleReviewAndPay={this.handleReviewAndPay}
                  disabled={isDisabled}
                />
              </Hide>
            </Footer>
          </Box>
        ) : null}
        <PriceChangeModal
          modalIsOpen={priceChangeModalShow}
          closeModal={() => this.closePriceChangeModal()}
          type={priceChangeData.type}
          total={priceChangeData.total}
        />
        {productItem.levies ? (
          <ModalLeviesView
            modalIsOpen={levimodalIsOpen}
            closeModal={() => this.closeLeviModal()}
            blueContent={this.modalBlueContent}
            whiteContent={this.modalWhiteContent}
          />
        ) : (
          <Box>
            <Flex flexWrap="wrap">
              <HeaderItem>
                <Box />
              </HeaderItem>
            </Flex>
            <ModalLoadingView modalIsOpen={modalIsOpen} closeModal={() => this.closeModal()} />
          </Box>
        )}
        <BookingAndPaymentErrorModal
          modalIsOpen={errorModalIsOpen}
          closeModal={() => this.closeErrorModal()}
          type={errorType}
        />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    product: state.userSelectedProduct,
    cityName: state.userSelectedCity,
  };
};

export default connect(mapStateToProps, null)(withRouter(ProductBooking));

export const SAVE_CITY_OBJ = "SAVE_CITY_OBJ";
export const SAVE_PRODUCT_OBJ = "SAVE_PRODUCT_OBJ";

export const saveCityObj = (obj) => {
  return {
    type: SAVE_CITY_OBJ,
    city: obj,
  };
};

export const saveProductObj = (id) => {
  return {
    type: SAVE_PRODUCT_OBJ,
    productID: id,
  };
};
